import React, { useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  FontIcon,
  Link,
  MessageBar,
  MessageBarType,
  Shimmer,
  ShimmerElementType,
  Text,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { NotMandantsMessage } from "./NotMandantsMessage";
import HttpService from "../../services/HttpService";
import { ActivationCodePanel } from "../Panel/ActivationCodePanel";
import { useBoolean } from "@fluentui/react-hooks";
import { KantonContext } from "../../contexts";
import { createFaq } from "../../helpers/helpers";
import AuthService from "../../services/AuthService";

function MainContainer() {
  const kantonInfo = useContext(KantonContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [panelData, setPanelData] = useState({});
  const [loading, setLoading] = useState(true);
  const [displayConfirmationMsg, setDisplayConfirmationMsg] = useState(false);

  const getMandateList = async () => {
    const response = await HttpService.getAxiosClient().get("/api/mandants");
    if (response.status === 200) {
      setData(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const confirmNotificationSettings = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/user/notification/settings/mark/confirmed",
      {}
    );
    if (response.status === 200) {
      // setDisplayConfirmationMsg(false);
    }
  };

  useEffect(() => {
    const getUserNotificationSettings = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/user/notification/settings"
      );
      if (response.status === 200) {
        if (response.data.confirmed === false) {
          setDisplayConfirmationMsg(true);
          confirmNotificationSettings();
        }
      }
    };
    getUserNotificationSettings();
  }, []);

  useEffect(() => {
    setLoading(true);
    getMandateList();
  }, []);

  useEffect(() => {
    const redirectToMandant = sessionStorage.getItem("pid");
    const found =
      data && data.some((e) => e.SubjektId.toString() === redirectToMandant);
    if (data && found) {
      sessionStorage.clear();
      navigate(`mandanten/${redirectToMandant}`);
    }
  }, [data]);

  const handlePanelOpen = (proc) => {
    setPanelData(proc);
    openPanel();
  };

  const handleClick = async (subjektid, name) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/account/unregister",
        {
          SubjektId: subjektid,
        }
      );
      if (response.status === 200) {
        getMandateList();
        setDeletionSuccess({ subjektid, name });
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const STPStartHeader = (
    <ObjectListHeader size="l-fb-100" hasActionButton="true">
      <Text as="span" className="flexItem t-listHeading l-fb-40">
        Name
      </Text>
      <Text as="span" className="flexItem t-listHeading t-right l-fb-30">
        PID
      </Text>
      <Text as="span" className="flexItem t-listHeading t-right l-fb-30">
        Geteilt mit
      </Text>
    </ObjectListHeader>
  );

  const UpdateSuccessMessage = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <ObjectItem>
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
          className="title2"
          style={{ minWidth: "100%" }}
        >
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
            <Text as="h3" className="title2">
              Ihre Kommunikationseinstellungen wurden wie folgt gesetzt
            </Text>
            <Text as="p" className="t-standard">
              Neu werden Sie benachrichtigt, wenn neue Dokumente oder
              Mitteilungen im Steuerportal vorhanden sind.
            </Text>
            <ContentContainer className="vertical l-flex-rowgap-4 l-1of1">
              <ContentContainer className="flexItem l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem title4 l-fb-30">
                  Zustellung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  {kantonInfo.kanton === "nw"
                    ? "per Briefpost"
                    : "digital ins Steuerportal (keine Briefpost)"}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem t-standard title4 l-fb-30">
                  Benachrichtigung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  per E-Mail: {AuthService.getUsername()}
                </Text>
              </ContentContainer>
            </ContentContainer>
            <ContentContainer className="horizontal l-flex-justify-end l-1of1 l-m-t-8">
              <DefaultButton
                text="Einstellungen anpassen"
                iconProps={ICON_PROPS.SettingsIcon}
                onClick={() => navigate(`/settings`)}
              />
            </ContentContainer>
          </ContentContainer>
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const deletionSuccessMessage = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <ObjectItem>
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          {deletionSuccess.name} (PID: {deletionSuccess.subjektid}) erfolgreich
          deaktiviert.
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const displayMessage = () => {
    if (deletionSuccess) {
      return deletionSuccessMessage;
    } else if (displayConfirmationMsg) {
      return UpdateSuccessMessage;
    } else {
      return null;
    }
  };

  const displayMandates = (mandates) => {
    if (mandates.length === 0) {
      return <NotMandantsMessage />;
    } else {
      const alphabetize = () =>
        mandates.sort((a, b) => a.name.localeCompare(b.name));
      return alphabetize().map((mandate) => (
        <ObjectItem
          onClick={() =>
            navigate(`mandanten/${mandate.SubjektId}`, { state: mandate })
          }
          ariaLabel={mandate.name}
          actionButton={
            <IconButton
              iconProps={null}
              ariaLabel={"Bearbeiten Menu"}
              menuProps={
                mandate.activated
                  ? mandate.owned === true
                    ? {
                        items: [
                          {
                            key: "open",
                            text: "Anzeigen",
                            iconProps: ICON_PROPS.ViewIcon,
                            onClick: () =>
                              navigate(`mandanten/${mandate.SubjektId}`, {
                                state: mandate,
                              }),
                          },
                          {
                            key: "deactivate",
                            text: "Steuerportal deaktivieren",
                            iconProps: ICON_PROPS.RemoveIcon,
                            onClick: () =>
                              handleClick(mandate.SubjektId, mandate.name),
                          },
                        ],
                      }
                    : {
                        items: [
                          {
                            key: "open",
                            text: "Anzeigen",
                            iconProps: ICON_PROPS.ViewIcon,
                            onClick: () =>
                              navigate(`mandanten/${mandate.SubjektId}`, {
                                state: mandate,
                              }),
                          },
                        ],
                      }
                  : mandate.agbAccepted
                  ? {
                      items: [
                        {
                          key: "open",
                          text: "Anzeigen",
                          iconProps: ICON_PROPS.ViewIcon,
                          onClick: () =>
                            navigate(`mandanten/${mandate.SubjektId}`, {
                              state: mandate,
                            }),
                        },
                        {
                          key: "activate",
                          text: "Aktivierungscode eingeben",
                          iconProps: ICON_PROPS.CheckIcon,
                          onClick: () => handlePanelOpen(mandate),
                        },
                      ],
                    }
                  : mandate.owned === true
                  ? {
                      items: [
                        {
                          key: "open",
                          text: "Anzeigen",
                          iconProps: ICON_PROPS.ViewIcon,
                          onClick: () =>
                            navigate(`mandanten/${mandate.SubjektId}`, {
                              state: mandate,
                            }),
                        },
                        {
                          key: "aktivieren",
                          text: "Steuerportal aktivieren",
                          iconProps: ICON_PROPS.CheckIcon,
                          onClick: () => navigate(`/aktivieren/`),
                        },
                      ],
                    }
                  : {
                      items: [
                        {
                          key: "open",
                          text: "Anzeigen",
                          iconProps: ICON_PROPS.ViewIcon,
                          onClick: () =>
                            navigate(`mandanten/${mandate.SubjektId}`, {
                              state: mandate,
                            }),
                        },
                      ],
                    }
              }
              menuIconProps={ICON_PROPS.MenuDotsIcon}
              className={"moreIcon"}
            />
          }
        >
          <ContentContainer className="l-flex-rowgap-4">
            <ContentContainer className="horizontal l-fb-40">
              {(() => {
                if (mandate.type === "NP") {
                  if (mandate.owned === false) {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.NPDelegatedIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  }
                  if (
                    mandate.agbAccepted === true &&
                    mandate.activated === false
                  ) {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.NPActivateIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  } else {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.AccountIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  }
                }
                if (mandate.type === "JP") {
                  if (mandate.owned === false) {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.JPDelegatedIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  }
                  if (
                    mandate.agbAccepted === true &&
                    mandate.activated === false
                  ) {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.JPActivateIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  } else {
                    return (
                      <FontIcon
                        iconName={ICON_PROPS.JPIcon.iconName}
                        className="objectItemIcon-large"
                      />
                    );
                  }
                }
              })()}
              <Text as="span" className="flexItem title3 l-fb-40">
                {mandate.name}
              </Text>
            </ContentContainer>
            <ContentContainer size="l-fb-30" className="horizontal">
              <Text as="span" className="flexItem t-listText hiddenLabel">
                PID:
              </Text>
              <Text as="span" className="flexItem t-listText t-right">
                {mandate.SubjektId}
              </Text>
            </ContentContainer>
            <ContentContainer size="l-fb-30" className="horizontal">
              <Text as="span" className="flexItem t-listText hiddenLabel">
                Geteilt mit:
              </Text>
              <Text as="span" className="flexItem t-listText t-right">
                {mandate.delegations ? mandate.delegations : ""}
              </Text>
            </ContentContainer>
          </ContentContainer>
        </ObjectItem>
      ));
    }
  };

  return (
    <MainContentContainer messages={displayMessage()}>
      <ObjectList
        title="Mandanten"
        headingTag="h2"
        headingTagClass="title2"
        listHeader={STPStartHeader}
      >
        {loading ? (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        ) : data.length !== 0 ? (
          displayMandates(data)
        ) : (
          <NotMandantsMessage />
        )}
      </ObjectList>
      {createFaq(kantonInfo, navigate)}
      <ContentContainer
        className="l-flex-rowgap-4"
        style={{ marginLeft: "16px", marginTop: "32px", marginBottom: "16px" }}
      >
        <Text as="p" className="t-standard">
          <Link
            href={
              kantonInfo.kanton === "ow"
                ? "https://ow-support.etax.ch/hc/de/categories/360002998739-Bedienungsanleitung-eSteuerportal-Obwalden"
                : "https://nw-support.etax.ch/hc/de/categories/360003003600-Bedienungsanleitung-eSteuerportal-Nidwalden"
            }
            target="_blank"
            underline
          >
            Alle Fragen & Antworten anzeigen
          </Link>
        </Text>
      </ContentContainer>
      <ActivationCodePanel
        panelData={panelData}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        setData={setData}
      />
    </MainContentContainer>
  );
}

export default MainContainer;
