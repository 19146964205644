import React from "react";
import {
  ContentContainer,
  HeaderTitle,
  ICON_PROPS,
  ObjectItem,
  ObjectList,
  Panel,
} from "@riag-libs/etax-pattern-library";
import { DefaultButton, PanelType, Text } from "@fluentui/react";
import HttpService from "../../services/HttpService";

export const VonMirGeteiltPanel = (props) => {
  const { selectedItem, isOpen, onDismiss, setAcceptedInvitations } = props;

  const getAcceptedInvitationsList = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/shares/my/all",
      {
        lang: "de",
      }
    );
    if (response.status === 200) {
      setAcceptedInvitations(response.data);
    }
  };

  const handleInvitationDelete = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/my/delete",
        {
          subjectId: selectedItem.subjectId,
          subjectType: selectedItem.type,
          trusteeId: selectedItem.trustee.userId,
        }
      );
      if (response.status === 200) {
        getAcceptedInvitationsList();
        onDismiss();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <DefaultButton
        onClick={() => {
          onDismiss();
        }}
      >
        Schliessen
      </DefaultButton>
    ),
    [onDismiss]
  );

  if (Object.keys(selectedItem).length === 0) {
    return <div></div>;
  }

  return (
    <Panel
      headerText={
        <HeaderTitle
          title={`${selectedItem.name} (PID: ${selectedItem.pid})`}
          subTitle="Von mir geteilt"
          logo={ICON_PROPS.ShareIcon}
          className="l-m-b-16"
        />
      }
      className={"owTheme"}
      panelType={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <ContentContainer className="vertical">
        <ObjectList
          title="Geteilt mit"
          headingTag="h2"
          headingTagClass="title2"
        >
          <ContentContainer className="vertical l-flex-rowgap-8">
            <ObjectItem className="whiteContent">
              <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Name:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {selectedItem.invitation.fname}{" "}
                    {selectedItem.invitation.lname}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    E-Mail:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {selectedItem.invitation.email}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Mobile-Nummer:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {selectedItem.invitation.mobile}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Benutzerrechte:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {selectedItem.permissions.accountsView === true &&
                      "Sieht Steuerkonto, Sieht Dokumente"}
                    {selectedItem.permissions.declRead === true &&
                      " Deklaration lesen, bearbeiten, übermitteln"}
                  </Text>
                </ContentContainer>
              </ContentContainer>
            </ObjectItem>
            <ContentContainer>
              <DefaultButton
                text="Teilen beenden"
                iconProps={ICON_PROPS.DeleteIcon}
                onClick={() => handleInvitationDelete()}
              />
            </ContentContainer>
          </ContentContainer>
        </ObjectList>
      </ContentContainer>
    </Panel>
  );
};
