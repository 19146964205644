import React, { useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Shimmer, ShimmerElementType, Text } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { deleteDeclaration, linkToDeclaration } from "../../helpers/helpers";
import moment from "moment";
import { NotDeclarationsMessage } from "./NotDeclarationsMessage";
import { KantonContext } from "../../contexts";
import HttpService from "../../services/HttpService";
import { useBoolean } from "@fluentui/react-hooks";
import { SharingPanelDeclarationView } from "../Panel/SharingPanelDeclarationView";
import { TAX_YEAR } from "../../constants/constants";

var FileSaver = require("file-saver");

const TaxDeclarationsHeader = (
  <ObjectListHeader hasActionButton={true}>
    <Text as="span" className="flexItem t-listHeading l-fb-30">
      Typ
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Status
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Bearbeitet
    </Text>
    {/*<Text as="span" className="flexItem t-listHeading t-right l-fb-20">*/}
    {/*  Einreichefrist*/}
    {/*</Text>*/}
    <Text as="span" className="flexItem t-listHeading t-right l-fb-10">
      Geteilt mit
    </Text>
  </ObjectListHeader>
);

export const TaxDeclaration = (props) => {
  const kantonInfo = useContext(KantonContext);
  const navigate = useNavigate();
  const [declarations, setDeclarations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [
    isDeclarationSharingOpen,
    { setTrue: openSharingPanel, setFalse: dismissSharingPanel },
  ] = useBoolean(false);
  const [selectedDeclaration, setSelectedDeclaration] = useState({});

  const getMandant = async () => {
    const response = await HttpService.getAxiosClient().get(
      `/api/mandant/${props.mandant.SubjektId}`
    );
    if (response.status === 200) {
      props.setMandant(response.data);
    }
  };

  useEffect(() => {
    if (props.mandant.activated === false) {
      getMandant();
    }
  }, []);

  const postAllDeclarations = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/etax/mandant/declarations",
      {
        SubjektId: props.mandant.SubjektId.toString(),
        locale: "de",
      }
    );
    setDeclarations(response.data);
    setLoading(false);
  };

  const exportDeclaration = async (id, modelDescr) => {
    try {
      const response = await HttpService.getAxiosClient().get(
        `/api/etax/declaration/export/${id}`,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/zip",
        });
        FileSaver.saveAs(blob, modelDescr);
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  useEffect(() => {
    if (Object.keys(props.mandant).length !== 0) {
      postAllDeclarations();
    }
  }, [props.mandant]);

  if (loading) {
    return (
      <MainContentContainer>
        <ObjectList
          title={"Steuererklärungen"}
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxDeclarationsHeader}
          className="tableView bigTitle"
        >
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        </ObjectList>
      </MainContentContainer>
    );
  }

  if (declarations.length === 0) {
    return (
      <NotDeclarationsMessage kantonInfo={kantonInfo} mandant={props.mandant} />
    );
  }

  let yearsArray = [];
  const findYears = () => {
    declarations.forEach((dec) => {
      if (!yearsArray.includes(dec.taxYear)) {
        yearsArray.push(dec.taxYear);
      }
    });
  };

  findYears();

  const displayStatusField = (declaration) => {
    if (declaration.printedAt && declaration.submittedAt) {
      if (moment(declaration.printedAt).isAfter(declaration.submittedAt)) {
        return (
          <>
            <Text as="span" className="flexItem t-listText">
              Erstellt:
            </Text>
            <Text as="span" className="flexItem t-listText t-right-s">
              {moment(declaration.printedAt).format("DD.MM.YYYY HH:mm")}
            </Text>
          </>
        );
      } else {
        return (
          <>
            <Text as="span" className="flexItem t-listText">
              Übermittelt:
            </Text>
            <Text as="span" className="flexItem t-listText t-right-s">
              {moment(declaration.submittedAt).format("DD.MM.YYYY HH:mm")}
            </Text>
          </>
        );
      }
    } else if (declaration.printedAt) {
      return (
        <>
          <Text as="span" className="flexItem t-listText">
            Erstellt:
          </Text>
          <Text as="span" className="flexItem t-listText t-right-s">
            {moment(declaration.printedAt).format("DD.MM.YYYY HH:mm")}
          </Text>
        </>
      );
    } else if (declaration.submittedAt) {
      return (
        <>
          <Text as="span" className="flexItem t-listText">
            Übermittelt:
          </Text>
          <Text as="span" className="flexItem t-listText t-right-s">
            {moment(declaration.submittedAt).format("DD.MM.YYYY HH:mm")}
          </Text>
        </>
      );
    } else if (declaration.createdAt) {
      return (
        <>
          <Text as="span" className="flexItem t-listText">
            Eröffnet:
          </Text>
          <Text as="span" className="flexItem t-listText t-right-s">
            {moment(declaration.createdAt).format("DD.MM.YYYY HH:mm")}
          </Text>
        </>
      );
    }
  };

  return (
    <MainContentContainer>
      {yearsArray.map((year) => (
        <ObjectList
          title={year}
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxDeclarationsHeader}
        >
          {declarations.length !== 0 &&
            declarations
              .filter((d) => d.taxYear === year)
              .map((declaration) => {
                return (
                  <ObjectItem
                    actionButton={
                      <IconButton
                        iconProps={null}
                        ariaLabel={"Bearbeiten Menü"}
                        menuProps={
                          declaration.permissions.owner === true
                            ? declaration.submissions === 0
                              ? {
                                  items: [
                                    {
                                      key: "extend",
                                      text: `Frist verlängern ${TAX_YEAR}`,
                                      iconProps: ICON_PROPS.TimeAlarmIcon,
                                      onClick: () =>
                                        navigate(`/extension/`, {
                                          state: props.mandant,
                                        }),
                                    },
                                    {
                                      key: "export",
                                      text: "Steuererklärung exportieren",
                                      iconProps: ICON_PROPS.DownloadIcon,
                                      onClick: () =>
                                        exportDeclaration(
                                          declaration.id,
                                          declaration.modelDescr
                                        ),
                                      target: "_blank",
                                    },
                                    {
                                      key: "delete",
                                      text: "Steuererklärung löschen",
                                      iconProps: ICON_PROPS.RemoveIcon,
                                      href: deleteDeclaration(
                                        kantonInfo.etaxBaseUri,
                                        declaration.id
                                      ),
                                      target: "_blank",
                                    },
                                    {
                                      key: "share",
                                      text: "Teilen",
                                      iconProps: ICON_PROPS.ShareIcon,
                                      onClick: () => {
                                        openSharingPanel();
                                        setSelectedDeclaration(declaration);
                                      },
                                    },
                                  ],
                                }
                              : {
                                  items: [
                                    {
                                      key: "extend",
                                      text: `Frist verlängern ${TAX_YEAR}`,
                                      iconProps: ICON_PROPS.TimeAlarmIcon,
                                      onClick: () =>
                                        navigate(`/extension/`, {
                                          state: props.mandant,
                                        }),
                                    },
                                    {
                                      key: "export",
                                      text: "Steuererklärung exportieren",
                                      iconProps: ICON_PROPS.DownloadIcon,
                                      onClick: () =>
                                        exportDeclaration(
                                          declaration.id,
                                          declaration.modelDescr
                                        ),
                                      target: "_blank",
                                    },
                                    {
                                      key: "share",
                                      text: "Teilen",
                                      iconProps: ICON_PROPS.ShareIcon,
                                      onClick: () => {
                                        openSharingPanel();
                                        setSelectedDeclaration(declaration);
                                      },
                                    },
                                  ],
                                }
                            : {
                                items: [
                                  {
                                    key: "extend",
                                    text: `Frist verlängern ${TAX_YEAR}`,
                                    iconProps: ICON_PROPS.TimeAlarmIcon,
                                    onClick: () => navigate(`/extension/`),
                                  },
                                  {
                                    key: "export",
                                    text: "Steuererklärung exportieren",
                                    iconProps: ICON_PROPS.DownloadIcon,
                                    onClick: () =>
                                      exportDeclaration(
                                        declaration.id,
                                        declaration.modelDescr
                                      ),
                                    target: "_blank",
                                  },
                                  {
                                    key: "share",
                                    text: "Teilen",
                                    iconProps: ICON_PROPS.ShareIcon,
                                    onClick: () => {
                                      openSharingPanel();
                                      setSelectedDeclaration(declaration);
                                    },
                                  },
                                ],
                              }
                        }
                        menuIconProps={ICON_PROPS.MenuDotsIcon}
                        className={"moreIcon"}
                      ></IconButton>
                    }
                    onClick={() =>
                      sessionStorage.setItem("pid", props.mandant.SubjektId)
                    }
                    href={linkToDeclaration(
                      kantonInfo.etaxBaseUri,
                      declaration.id,
                      declaration.submissions
                    )}
                  >
                    <ContentContainer className="l-flex-rowgap-8">
                      <ContentContainer className="horizontal l-fb-30">
                        {declaration.model.includes("np") ? (
                          declaration.permissions.owner === true ? (
                            <FontIcon
                              iconName={ICON_PROPS.AccountIcon.iconName}
                              className="objectItemIcon-large"
                            />
                          ) : (
                            <FontIcon
                              iconName={ICON_PROPS.NPDelegatedIcon.iconName}
                              className="objectItemIcon-large"
                            />
                          )
                        ) : declaration.permissions.owner === true ? (
                          <FontIcon
                            iconName={ICON_PROPS.JPIcon.iconName}
                            className="objectItemIcon-large"
                          />
                        ) : (
                          <FontIcon
                            iconName={ICON_PROPS.JPDelegatedIcon.iconName}
                            className="objectItemIcon-large"
                          />
                        )}
                        <Text as="span" className="flexItem title3">
                          {declaration.modelDescr}
                        </Text>
                      </ContentContainer>
                      <ContentContainer
                        size="l-fb-20"
                        className="horizontal l-flex-wrap l-flex-gap-4"
                      >
                        {displayStatusField(declaration)}
                      </ContentContainer>
                      <ContentContainer
                        size="l-fb-20"
                        className="horizontal l-flex-wrap l-flex-gap-4"
                      >
                        <Text
                          as="span"
                          className="flexItem t-listText t-right-s"
                        >
                          {moment(declaration.lastModified).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </Text>
                      </ContentContainer>
                      {/*<ContentContainer size="l-fb-20" className="horizontal">*/}
                      {/*  <Text*/}
                      {/*    as="span"*/}
                      {/*    className="flexItem t-listText hiddenLabel"*/}
                      {/*  >*/}
                      {/*    Einreichefrist:*/}
                      {/*  </Text>*/}
                      {/*  <Text as="span" className="flexItem t-listText t-right">*/}
                      {/*    -*/}
                      {/*  </Text>*/}
                      {/*</ContentContainer>*/}
                      <ContentContainer size="l-fb-10" className="horizontal">
                        <Text
                          as="span"
                          className="flexItem t-listText hiddenLabel l-fb-100"
                        >
                          Geteilt mit:
                        </Text>
                        <ContentContainer className="horizontal l-flex-justify-end">
                          {declaration.delegations
                            ? declaration.delegations
                            : ""}
                        </ContentContainer>
                      </ContentContainer>
                    </ContentContainer>
                  </ObjectItem>
                );
              })}
        </ObjectList>
      ))}
      <SharingPanelDeclarationView
        isOpen={isDeclarationSharingOpen}
        onDismiss={dismissSharingPanel}
        mandant={props.mandant}
        declaration={selectedDeclaration}
        postAllDeclarations={postAllDeclarations}
        getMandant={getMandant}
      />
    </MainContentContainer>
  );
};
