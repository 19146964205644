import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  Expander,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Text } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import { NothingSharedMessage } from "./NothingSharedMessage";

function EinladungenGeteilt() {
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const navigate = useNavigate();

  const getPendingInvitationsList = async () => {
    const response = await HttpService.getAxiosClient().get(
      "/api/delegation/invitations"
    );
    if (response.status === 200) {
      setPendingInvitations(response.data);
    }
  };

  useEffect(() => {
    getPendingInvitationsList();
  }, []);

  const handleInvitationDelete = async (id) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/delegation/invitation/decline",
        {
          lang: "de",
          requestId: id,
        }
      );
      if (response.status === 200) {
        getPendingInvitationsList();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const displayPendingInvitations = (invitations) => {
    return invitations.map((invitation) => (
      <Expander
        title={`${invitation.firstName} ${invitation.lastName}`}
        headerTitleTag="h3"
        headerTitleTagClass="title3"
        headerTitleSize="l-fb-40"
        className="formContent"
        headerContent={
          <>
            <ContentContainer
              size="l-fb-40 l-flex-nogrow"
              className="horizontal"
            >
              <Text as="span" className="flexItem t-listText hiddenLabel">
                E-Mail:
              </Text>
              <Text as="span" className="flexItem t-listText t-right-s">
                {invitation.email}
              </Text>
            </ContentContainer>
            <ContentContainer
              size="l-fb-40 l-flex-nogrow"
              className="horizontal"
            >
              <Text as="span" className="flexItem t-listText hiddenLabel">
                Mobile-Nummer:
              </Text>
              <Text as="span" className="flexItem t-listText t-right-s">
                {invitation.mobile}
              </Text>
            </ContentContainer>
            <ContentContainer
              size="l-fb-20 l-flex-nogrow"
              className="horizontal"
            >
              <Text as="span" className="flexItem t-listText hiddenLabel">
                Anzahl geteilt:
              </Text>
              <Text as="span" className="flexItem t-listText t-right">
                {invitation.subjects.length}
              </Text>
            </ContentContainer>
          </>
        }
        headerContentSize="l-fb-60"
      >
        <ObjectList
          title="Geteilter Inhalt"
          headingTag="h4"
          headingTagClass="title4"
          listHeader={
            <ObjectListHeader hasActionButton={true}>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Mandant
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-10">
                PID
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Typ
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Benutzerrechte
              </Text>
            </ObjectListHeader>
          }
        >
          {invitation.subjects.map((subj) => (
            <ObjectItem
              onClick={() => navigate(`/mandanten/${subj.pid}`)}
              ariaLabel={subj.name}
              actionButton={
                <IconButton
                  iconProps={null}
                  ariaLabel={"Menü"}
                  menuIconProps={ICON_PROPS.DeleteIcon}
                  onClick={() => handleInvitationDelete(invitation.id)}
                  className={"moreIcon"}
                />
              }
            >
              <ContentContainer className="l-flex-rowgap-8">
                <ContentContainer size="l-fb-30" className="horizontal">
                  <FontIcon
                    iconName={
                      subj.subjectType === "JP"
                        ? ICON_PROPS.JPIcon.iconName
                        : ICON_PROPS.NPIcon.iconName
                    }
                    className="objectItemIcon-large"
                  />
                  <Text as="span" className="flexItem title3">
                    {subj.name}
                  </Text>
                </ContentContainer>
                <ContentContainer size="l-fb-10" className="horizontal">
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    PID:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {subj.pid}
                  </Text>
                </ContentContainer>
                <ContentContainer size="l-fb-30" className="horizontal">
                  <Text
                    as="span"
                    className="flexItem t-listText hiddenLabel l-fb-100"
                  >
                    Typ:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {subj.typeDescr} {subj.year}
                  </Text>
                </ContentContainer>
                <ContentContainer size="l-fb-30" className="horizontal">
                  <Text
                    as="span"
                    className="flexItem t-listText hiddenLabel l-fb-100"
                  >
                    Benutzerrechte:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {subj.permissions.accountsView === true &&
                      "Sieht Steuerkonto, Sieht Dokumente"}
                    {subj.permissions.declRead === true &&
                      "Deklaration lesen, bearbeiten, einreichen"}
                  </Text>
                </ContentContainer>
              </ContentContainer>
            </ObjectItem>
          ))}
        </ObjectList>
      </Expander>
    ));
  };

  if (pendingInvitations.length === 0) {
    return <NothingSharedMessage type={"einladungen"} />;
  }

  return (
    <MainContentContainer>
      <ObjectList
        title="Offene Einladungen für"
        headingTag="h2"
        headingTagClass="title2"
        listHeader={
          <ObjectListHeader>
            <ContentContainer className="flexItem l-fb-40">
              <Text as="span" className="t-listHeading l-m-l-32">
                Name
              </Text>
            </ContentContainer>
            <ContentContainer className="flextItem l-flex-align-end l-fb-60">
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                E-Mail
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                Mobile-Nummer
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-20"
              >
                Offene Einladungen
              </Text>
            </ContentContainer>
          </ObjectListHeader>
        }
      >
        {pendingInvitations && displayPendingInvitations(pendingInvitations)}
      </ObjectList>
    </MainContentContainer>
  );
}

export default EinladungenGeteilt;
