import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  FontIcon,
  Shimmer,
  ShimmerElementType,
  Text,
} from "@fluentui/react";
import { NotActiveMessage } from "./NotActiveMessage";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import { NotActiveSteuerKonto } from "./NotActiveSteuerKonto";
import { SharingPanelMandantView } from "../Panel/SharingPanelMandantView";
import { useBoolean } from "@fluentui/react-hooks";
import { NotSharedWithYou } from "./NotSharedWithYou";

const TaxAccountHeader = (
  <ObjectListHeader>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Gemeinde
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Beschreibung
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-15">
      Status
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Saldo
    </Text>
  </ObjectListHeader>
);

export const SteuerKonto = (props) => {
  const navigate = useNavigate();
  const [konto, setKonto] = useState({});
  const [loading, setLoading] = useState(false);
  const [
    isSteuerSharingOpen,
    { setTrue: openSharingPanel, setFalse: dismissSharingPanel },
  ] = useBoolean(false);
  const { mandant, setMandant } = props;

  const getMandant = async () => {
    try {
      const response = await HttpService.getAxiosClient().get(
        `/api/mandant/${mandant.SubjektId}`
      );
      if (response.status === 200) {
        setMandant(response.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        navigate("/");
      }
    }
  };

  const postAllSteuerKonto = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/account/overview",
      {
        SubjektId: mandant.SubjektId,
      }
    );
    setKonto(response.data);
    setLoading(false);
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (mandant.activated === true) {
        postAllSteuerKonto();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return <NotActiveMessage steuer nestError />;
    }
  }, [mandant]);

  useEffect(() => {
    if (mandant.activated === false) {
      getMandant();
    }
  }, []);

  if (konto?.ErrorNumber === "PERS-00013") {
    return <NotActiveMessage steuer nestError={"PERS-00013"} />;
  }

  const KontoItems = konto?.BackEndData?.KontoItems;

  const handleClick = async (acc) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/account/details",
        {
          Filter: "Alle",
          KontoNr: acc.KontoNr,
          Sachgebiet: acc.Sachgebiet,
          SubjektId: mandant.SubjektId,
          SubjektIdKonto: acc.SubjektIdKonto,
        }
      );
      if (response.status === 200) {
        navigate(
          `/mandanten/${mandant.SubjektId}/${acc.SubjektIdKonto}/${acc.KontoNr}/kontodetails`,
          {
            state: {
              data: response.data,
              mandantInfo: mandant,
              kontoItems: {
                KontoNr: acc.KontoNr,
                Sachgebiet: acc.Sachgebiet,
                SubjektId: mandant.SubjektId,
                SubjektIdKonto: acc.SubjektIdKonto,
              },
            },
          }
        );
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  if (loading) {
    return (
      <MainContentContainer>
        <ObjectList
          title="Steuerkonto"
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxAccountHeader}
          className="tableView bigTitle"
          titleAction={
            <DefaultButton
              disabled={true}
              text={"Teilen"}
              iconProps={ICON_PROPS.ShareIcon}
            ></DefaultButton>
          }
        >
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        </ObjectList>
      </MainContentContainer>
    );
  }

  if (mandant.owned === false && mandant.permissions.accountsView === false) {
    return <NotSharedWithYou steuer />;
  }

  if (Object.keys(konto).length === 0) {
    if (mandant.agbAccepted === true && mandant.activated === false) {
      return (
        <NotActiveSteuerKonto setMandant={setMandant} mandantInfo={mandant} />
      );
    }
    return <NotActiveMessage steuer mandant={mandant} />;
  }

  const TaxaccountEmptyListMessage = (
    <ObjectItem
      className="infoWarning"
      ariaLabel="Information zur leeren Liste"
    >
      <ContentContainer className="horizontal">
        <FontIcon
          iconName={ICON_PROPS.InfoIcon.iconName}
          className="objectItemIcon"
        />
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
          <Text as="span" className="flexItem title3">
            Für {mandant.name} sind keine Steuerkontoinformationen vorhanden.
          </Text>
        </ContentContainer>
      </ContentContainer>
    </ObjectItem>
  );
  return (
    <MainContentContainer>
      <ObjectList
        title="Steuerkonto"
        headingTag="h3"
        headingTagClass="title2"
        listHeader={TaxAccountHeader}
        className="tableView bigTitle"
        emptyListMessage={KontoItems.length === 0 && TaxaccountEmptyListMessage}
        titleAction={
          <DefaultButton
            onClick={() => openSharingPanel()}
            text={"Teilen"}
            iconProps={ICON_PROPS.ShareIcon}
          ></DefaultButton>
        }
      >
        {KontoItems.length !== 0 &&
          KontoItems.map((acc) => (
            <ObjectItem onClick={() => handleClick(acc)}>
              <ContentContainer className="l-flex-rowgap-8">
                <ContentContainer size="l-fb-10" className="horizontal">
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Steuerjahr:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {acc.Periode}
                  </Text>
                </ContentContainer>
                <ContentContainer size="l-fb-20" className="horizontal">
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Gemeinde:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {acc.GemeindeBezeichnung}
                  </Text>
                </ContentContainer>
                <ContentContainer
                  size="l-fb-40"
                  className="horizontal t-ellipsis"
                >
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Beschreibung:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {acc.SachgebietBezeichnung}
                    {`: ${acc.SteuerartBezeichnung}`}
                  </Text>
                </ContentContainer>
                <ContentContainer
                  size="l-fb-15"
                  className="horizontal t-ellipsis"
                >
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Status:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right-s">
                    {acc.Verfahren}
                  </Text>
                </ContentContainer>
                <ContentContainer
                  size="l-fb-15"
                  className="horizontal t-ellipsis"
                >
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Saldo:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right">
                    {acc.Saldo}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-fb-24px">
                  <FontIcon
                    iconName={ICON_PROPS.ArrowRightIcon.iconName}
                    className="objectItemIcon"
                  />
                </ContentContainer>
              </ContentContainer>
            </ObjectItem>
          ))}
      </ObjectList>
      {KontoItems.length !== 0 && (
        <ObjectItem className="totalRow">
          <ContentContainer className="horizontal l-flex-columngap-8">
            <ContentContainer className="horizontal">
              <Text as="span" className="flexItem title4">
                {konto?.BackEndData?.ZuGunstenText &&
                  konto.BackEndData.ZuGunstenText}
              </Text>
              <Text as="span" className="flexItem title4 t-right">
                {konto?.BackEndData?.SaldoTotal && konto.BackEndData.SaldoTotal}
              </Text>
            </ContentContainer>
            <ContentContainer className="l-fb-24px" />
          </ContentContainer>
        </ObjectItem>
      )}
      <SharingPanelMandantView
        isOpen={isSteuerSharingOpen}
        onDismiss={dismissSharingPanel}
        mandant={mandant}
        refreshOnClose={getMandant}
      />
    </MainContentContainer>
  );
};
