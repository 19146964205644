import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FieldSet,
  ICON_PROPS,
  IconButtonWithCallout,
  MainContentContainer,
  TextField,
  MaskedTextField,
} from "@riag-libs/etax-pattern-library";
import { PrimaryButton } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  deadlineDescription,
  pickExtensionMessage,
} from "../../helpers/helpers";
import { KantonContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import { TAX_YEAR } from "../../constants/constants";

export const CompanyExtension = () => {
  const location = useLocation();
  const kantonInfo = useContext(KantonContext);
  const [fields, setFields] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  let regexp = new RegExp("([0-9]{2}).([0-9]{2}).([0-9]{4})");
  let regexpLetter = new RegExp("[a-zA-Z]");
  const [shouldDisabled, setShouldDisabled] = useState(true);

  useEffect(() => {
    if (fields.PersonSearchRequest?.SubjektId && fields.Wunschfrist) {
      setShouldDisabled(false);
    } else {
      setShouldDisabled(true);
    }
  }, [fields]);

  useEffect(() => {
    if (location?.state?.SubjektId) {
      setFields({
        ...fields,
        PersonSearchRequest: {
          SubjektId: location.state.SubjektId,
        },
      });
    }
  }, []);

  const handleKeyUp = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("submitCompanyFrist").click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  const handleClick = async () => {
    try {
      if (
        fields.Wunschfrist &&
        regexp.test(fields.Wunschfrist.slice(0, -1).trim()) &&
        !regexpLetter.test(fields.PersonSearchRequest.SubjektId) &&
        fields.PersonSearchRequest.SubjektId !== ""
      ) {
        const response = await HttpService.getAxiosClient().post(
          "/api/frist/jp",
          {
            Periode: TAX_YEAR,
            PersonSearchRequest: {
              SubjektId: parseInt(fields.PersonSearchRequest.SubjektId),
            },
            Wunschfrist: fields.Wunschfrist.slice(0, -1).trim(),
          }
        );
        if (response.status === 200 && response.data.isError === false) {
          setError(false);
          setSuccess({
            actual: response.data.newFrist,
            requested: fields.Wunschfrist.slice(0, -1).trim(),
          });
        }
        if (response.status === 200 && response.data.isError === true) {
          setSuccess(false);
          setError(response.data.message);
        }
      } else {
        setSuccess(false);
        setError("Bitte überprüfen Sie Ihre Eingaben");
      }
    } catch (error) {
      setSuccess(false);
      setError("Bitte überprüfen Sie Ihre Eingaben");
      console.log(error, error.message);
    }
  };

  const SteuerperiodeCallout = IconButtonWithCallout({
    iconButtonAriaLabel: "Steuerperiode",
    calloutTitle: "Steuerperiode",
    calloutTitleHeading: "h3",
    calloutText: "Die Steuerperiode kann nicht geändert werden.",
  });

  const PIDCallout = IconButtonWithCallout({
    iconButtonAriaLabel: "PID-Nr.",
    calloutTitle: "PID-Nr.",
    calloutTitleHeading: "h3",
    calloutText:
      "Ihre Personenidentifikationsnummer finden Sie auf dem Aktivierungsschreiben, welches Sie vom Steueramt erhalten haben.",
  });

  const FristCallout = IconButtonWithCallout({
    iconButtonAriaLabel: "Gewünschte Frist",
    calloutTitle: "Gewünschte Frist",
    calloutTitleHeading: "h3",
    calloutText:
      "Das Datum wird im Format Tag, Monat und Jahr eingegeben. zB. 30.06.2023",
  });

  return (
    <MainContentContainer messages={pickExtensionMessage(success, error)}>
      <FieldSet
        title="Fristerstreckung für Privatpersonen"
        headingTag="h3"
        headingTagClass="visuallyHidden"
        description={deadlineDescription(kantonInfo.kanton, true)}
      >
        <TextField
          label="Steuerperiode"
          className="l-w-330r"
          readOnly={true}
          value={`${TAX_YEAR}`}
          outSideChild={SteuerperiodeCallout}
        />
        <TextField
          label="PID-Nr."
          className="l-w-330r"
          defaultValue={location?.state?.SubjektId && location.state.SubjektId}
          outSideChild={PIDCallout}
          onChange={(event) => {
            setFields({
              ...fields,
              PersonSearchRequest: {
                SubjektId: event.target.value,
              },
            });
          }}
        />
        <MaskedTextField
          label="Gewünschte Frist"
          mask="99.99.9999"
          className="l-w-330r"
          outSideChild={FristCallout}
          onChange={(event) => {
            setFields({
              ...fields,
              Wunschfrist: event.target.value,
            });
          }}
        />
      </FieldSet>
      <PrimaryButton
        text="Fristerstreckung beantragen"
        id={"submitCompanyFrist"}
        disabled={shouldDisabled}
        iconProps={ICON_PROPS.CalendarIcon}
        className="l-m-tb-16 l-w-330r"
        onClick={() => handleClick()}
      />
    </MainContentContainer>
  );
};
