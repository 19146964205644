import React from "react";
import {
  ContentContainer,
  ICON_PROPS,
  ObjectItem,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Text } from "@fluentui/react";
export const NotMandantsMessage = () => {
  return (
    <ObjectItem
      className="infoWarning"
      ariaLabel="Information zur leeren Liste"
    >
      <ContentContainer className="horizontal">
        <FontIcon
          iconName={ICON_PROPS.InfoIcon.iconName}
          className="objectItemIcon"
        />
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
          <Text as="span" className="flexItem title3">
            Sie haben noch keine Mandanten erfasst.
          </Text>
        </ContentContainer>
      </ContentContainer>
    </ObjectItem>
  );
};
