import React, { useContext, useEffect, useState } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import MandatSelected from "../components/Mandant/MandatSelected";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import HttpService from "../services/HttpService";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";

function SelectedPage() {
  const [mandant, setMandant] = useState({});
  const kantonInfo = useContext(KantonContext);
  const navigate = useNavigate();
  const mandantPath = window.location.pathname.split("/mandanten/").pop();

  const getMandant = async () => {
    try {
      const response = await HttpService.getAxiosClient().get(
        `/api/mandant/${mandantPath}`
      );
      if (response.status === 200) {
        setMandant(response.data);
      }
    } catch (error) {
      navigate("/");
      console.log(error, error.message);
    }
  };

  useEffect(() => {
    getMandant();
  }, []);

  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={`${mandant.name ? mandant.name : ""} (PID:
                ${mandant.SubjektId ? mandant.SubjektId : ""})`}
          displayButtons={true}
          breadcrumbs={[
            {
              text: mandant?.name ? mandant.name : "",
              key: "f1",
              isCurrentItem: true,
            },
          ]}
          backButtonPath={`/`}
        />
      }
      main={<MandatSelected mandant={mandant} setMandant={setMandant} />}
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default SelectedPage;
