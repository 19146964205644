import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import NewActivation from "./NewActivation";
import ToBeActivatedList from "./ToBeActivatedList";
import HttpService from "../../services/HttpService";

function MainActivation() {
  const DE1 = (
    <PivotItem headerText="Aktivierung beantragen" itemKey="Privatpersonen">
      <NewActivation />
    </PivotItem>
  );
  const DE2 = (
    <PivotItem headerText="Aktivierungscode eingeben" itemKey="List">
      <ToBeActivatedList />
    </PivotItem>
  );

  return (
    <Pivot
      ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
      activeKey="PrivateActivation"
      className="pivotContainer"
    >
      {DE1}
      {DE2}
    </Pivot>
  );
}

export default MainActivation;
