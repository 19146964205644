import React, { useEffect, useState } from "react";
import {
  ButtonList,
  ContentContainer,
  DefaultButton,
  ICON_PROPS,
  Panel,
  PrimaryButton,
  TextButton,
  TextField,
} from "@riag-libs/etax-pattern-library";
import { PanelType } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  MobileFormatError,
  SMSSent,
  SMSVerified,
  GlobalSettingsError,
  VerifyMobileCodeError,
} from "../../helpers/helpers";

export const MobilePanel = (props) => {
  const [mobile, setMobile] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [error, setError] = useState(null);
  const [newMobileRequested, setNewMobileRequested] = useState(false);
  const [mobileCodeVerificationSuccess, setMobileCodeVerificationSuccess] =
    useState(false);
  const { setLocalSettings } = props;

  const getUserNotificationSettings = async () => {
    const response = await HttpService.getAxiosClient().get(
      "/api/user/notification/settings"
    );
    if (response.status === 200) {
      setLocalSettings(response.data);
    }
  };

  const postUserNotificationSettings = async (updatedSettings) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/settings",
        {
          ...updatedSettings,
        }
      );

      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating settings");
    }
  };

  const postUserMobileCode = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/mobile/validation/complete",
        {
          code: mobileCode,
        }
      );
      if (response.status === 200) {
        setError(null);
        setNewMobileRequested(false);
        setMobileCodeVerificationSuccess(true);
      }
      if (response.status !== 200) {
        setError("SMS-Code konnte nicht verifiziert werden");
      }
    } catch (err) {
      setError("errorVerifyMobileCode");
    }
  };

  useEffect(() => {
    postUserNotificationSettings({
      ...props.localSettings,
      mobile: "",
      mobileValidated: false,
    });
  }, []);

  const isValidMobile = (mobile) => {
    const regex = /^(\+)?[0-9]{10,}$/;
    return regex.test(mobile);
  };

  const handleMobileChange = (event, newValue) => {
    setMobile(newValue);
  };

  const handleMobileCodeChange = (event, newValue) => {
    setMobileCode(newValue);
  };

  const handleKeyPressVerifyMobile = (event) => {
    if (event.key === "Enter") {
      handleVerifyMobile();
    }
  };

  const handleKeyPressMobileCodeChange = (event) => {
    if (event.key === "Enter") {
      handleVerifyMobileCode();
    }
  };

  const postUserNewMobile = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/mobile/validation/start",
        {
          lang: "de",
        }
      );
      if (response.status === 200) {
        setError(null);
      }
      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating mobile");
    }
  };

  const handleVerifyMobile = async () => {
    if (isValidMobile(mobile)) {
      setError(null);
      const updatedSettings = {
        ...props.localSettings,
        mobileValidated: false,
        mobile: mobile,
      };
      await postUserNotificationSettings(updatedSettings);
      postUserNewMobile();
      setNewMobileRequested(true);
    } else {
      setError("errorMobileFormat");
    }
  };

  const displayMessage = () => {
    if (error) {
      if (error === "errorVerifyMobileCode") {
        return VerifyMobileCodeError;
      } else if (error === "errorVerifyMobileCode") {
        return VerifyMobileCodeError;
      } else if (error === "errorMobileFormat") {
        return MobileFormatError;
      } else {
        return GlobalSettingsError;
      }
    } else if (newMobileRequested === true) {
      return SMSSent;
    } else if (mobileCodeVerificationSuccess) {
      return SMSVerified;
    }
  };

  const handleVerifyMobileCode = async () => {
    await postUserNotificationSettings({
      ...props.localSettings,
      mobile: mobile,
      mobileValidated: true,
    });
    await postUserMobileCode();
  };
  const renderContent = () => {
    if (newMobileRequested === true) {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <ContentContainer className="vertical l-w-330">
            <ContentContainer className="vertical l-flex-rowgap-8 l-w-330">
              <TextField
                label={`Verifikations-Code für ${mobile}`}
                size="l-w-330"
                onKeyPress={(e) => handleKeyPressMobileCodeChange(e)}
                onChange={handleMobileCodeChange}
                autoFocus
              />
              <ButtonList className="l-w-330 vertical narrow">
                <PrimaryButton
                  text="Verifikations-Code bestätigen"
                  iconProps={ICON_PROPS.CheckIcon}
                  className="l-w-330"
                  onClick={() => handleVerifyMobileCode()}
                />
                <TextButton
                  text="Verifikations-Code erneut verschicken"
                  iconProps={ICON_PROPS.ReloadIcon}
                  className="l-w-330"
                  onClick={() => handleVerifyMobile()}
                />
              </ButtonList>
            </ContentContainer>
          </ContentContainer>
        </ContentContainer>
      );
    } else if (mobileCodeVerificationSuccess) {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <DefaultButton
            onClick={() => {
              getUserNotificationSettings();
              props.onDismiss();
            }}
          >
            Schliessen
          </DefaultButton>
        </ContentContainer>
      );
    } else {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <TextField
            label="Mobile-Nummer (bitte verifizieren)"
            size="l-w-330"
            value={mobile}
            onChange={handleMobileChange}
            onKeyPress={(e) => handleKeyPressVerifyMobile(e)}
          />
          <PrimaryButton
            text="Mobile-Nummer verifizieren"
            iconProps={ICON_PROPS.CheckIcon}
            className="l-w-330"
            onClick={() => handleVerifyMobile()}
          />
        </ContentContainer>
      );
    }
  };

  return (
    <Panel
      headerText="Mobile-Nummer ändern"
      panelType={PanelType.smallFixedFar}
      isOpen={props.isOpen}
      dismissPanel={() => {
        getUserNotificationSettings();
        props.onDismiss();
      }}
    >
      {renderContent()}
    </Panel>
  );
};
