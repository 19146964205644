import React, { useContext, useEffect, useState } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import MainContainer from "../components/Main/MainContainer";
import AuthService from "../services/AuthService";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme.css";
import "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme.css";
import { Footer } from "../components/Footer/Footer";
import { KantonContext, MainContext } from "../contexts";
import HttpService from "../services/HttpService";

function MainPage() {
  sessionStorage.removeItem("page");
  const kantonInfo = useContext(KantonContext);
  const [data, setData] = useState({});

  useEffect(() => {
    const getMandateList = async () => {
      const response = await HttpService.getAxiosClient().get("/api/mandants");
      if (response.status === 200) {
        setData(response.data);
      }
    };
    getMandateList();
  }, []);

  return (
    <MainContext.Provider value={data}>
      <AppContainer
        header={
          <HeaderDefault
            userName={AuthService.getUsername()}
            mainText={`Willkommen, ${AuthService.getGivenName()} ${AuthService.getFamilyName()}`}
            displayButtons={true}
          />
        }
        main={<MainContainer />}
        footer={<Footer />}
        appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
      ></AppContainer>
    </MainContext.Provider>
  );
}

export default MainPage;
