import React from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Text } from "@fluentui/react";

const TaxAccountHeader = (
  <ObjectListHeader>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Gemeinde
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Beschreibung
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-15">
      Status
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Saldo
    </Text>
  </ObjectListHeader>
);

const DocumentsHeader = (
  <ObjectListHeader hasActionButton={true}>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerart
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Dokumenttyp
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Dokumentname
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-20">
      Erstellt am
    </Text>
  </ObjectListHeader>
);

export const NotSharedWithYou = (props) => {
  const { steuer } = props;
  return (
    <MainContentContainer>
      <ObjectList
        title={steuer ? "Steuerkonto" : "Dokumente"}
        headingTag="h3"
        headingTagClass="title2"
        listHeader={steuer ? TaxAccountHeader : DocumentsHeader}
        className="tableView bigTitle"
      >
        <ObjectItem
          className="infoWarning"
          ariaLabel="Information zur leeren Liste"
        >
          <ContentContainer className="horizontal">
            <FontIcon
              iconName={ICON_PROPS.InfoIcon.iconName}
              className="objectItemIcon"
            />
            {steuer ? (
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Steuerkonto ist für Sie nicht zugänglich.{" "}
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Die Berechtigungen zum Anzeigen der Inhalte wurden nicht mit
                  Ihnen geteilt.
                </Text>
              </ContentContainer>
            ) : (
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Dokumente ist für Sie nicht zugänglich.{" "}
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Die Berechtigungen zum Anzeigen der Inhalte wurden nicht mit
                  Ihnen geteilt.
                </Text>
              </ContentContainer>
            )}
          </ContentContainer>
        </ObjectItem>
      </ObjectList>
    </MainContentContainer>
  );
};
