import React, { useContext } from "react";
import {
  Header,
  HeaderTitle,
  TextButton,
  ButtonList,
  IconButton,
  ICON_PROPS,
  ContentContainer,
} from "@riag-libs/etax-pattern-library";
import {
  Breadcrumb,
  DefaultButton,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { newETaxDeclaration, newETaxImport } from "../../helpers/helpers";
import { ImpressumPanel } from "../Panel/ImpressumPanel";
import { useBoolean } from "@fluentui/react-hooks";
import { KantonContext } from "../../contexts";
import AuthService from "../../services/AuthService";
import { TAX_YEAR } from "../../constants/constants";

function HeaderDefault(props) {
  const {
    userName,
    mainText,
    displayButtons,
    breadcrumbs,
    backButtonPath,
    backButtonClear,
    backButtonState,
  } = props;
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const navigate = useNavigate();
  const kantonInfo = useContext(KantonContext);
  const kantonName = kantonInfo.kanton === "ow" ? "Obwalden" : "Nidwalden";

  const BreadcrumbItems = [
    {
      text: "Start",
      key: "f0",
      href: "/",
    },
    ...(breadcrumbs || []),
  ];

  const MenuProps = {
    items: [
      {
        key: "profile",
        text: "Benutzerprofil",
        iconProps: ICON_PROPS.AccountIcon,
        href: kantonInfo.userAccountUrl,
        target: "_blank",
      },
      {
        key: "settings",
        text: "Einstellungen",
        iconProps: ICON_PROPS.SettingsIcon,
        onClick: () => navigate(`/settings`),
      },
      {
        key: "impressum",
        text: "Impressum",
        iconProps: ICON_PROPS.ImpressumIcon,
        onClick: () => openPanel(),
      },
      {
        key: "abmelden",
        text: "Abmelden",
        iconProps: ICON_PROPS.LogOutIcon,
        onClick: () => AuthService.doLogout(),
      },
    ],
  };

  const NeuMenuProps = {
    items: [
      {
        key: "neueFrist",
        text: `Frist verlängern ${TAX_YEAR}`,
        iconProps: ICON_PROPS.TimeAlarmIcon,
        onClick: () => navigate(`/extension`),
      },
      {
        key: "importStE",
        text: "Steuererklärung importieren",
        iconProps: ICON_PROPS.UploadIcon,
        href: newETaxImport(kantonInfo.etaxBaseUri, kantonInfo.kanton),
        target: "_blank",
      },
      ...(kantonInfo.kanton === "ow"
        ? [
            {
              key: "neueJP",
              text: "Steuererklärung JP",
              href: kantonInfo.jpOWUrl,
              target: "_blank",
              iconProps: ICON_PROPS.JPIcon,
            },
          ]
        : []),
      {
        key: "aktivieren",
        text: "Steuerportal aktivieren",
        iconProps: ICON_PROPS.CheckIcon,
        onClick: () => navigate(`/aktivieren`),
      },
    ],
  };

  const TitleActionMandantenButtons = (
    <ButtonList>
      <PrimaryButton
        text={"Neue Steuererklärung eröffnen"}
        iconProps={ICON_PROPS.AddIcon}
        href={newETaxDeclaration(kantonInfo.etaxBaseUri, kantonInfo.kanton)}
      />
      <DefaultButton
        ariaLabel="Weitere Aktionen"
        title="Weitere Aktionen"
        menuIconProps={ICON_PROPS.MenuDotsIcon}
        className="moreIcon"
        menuProps={NeuMenuProps}
      />
    </ButtonList>
  );

  return (
    <div>
      <Header
        breadcrumb={
          <Breadcrumb
            ariaLabel="Brotkrümmelnavigation"
            focusZoneProps={{ handleTabKey: 1 }}
            items={BreadcrumbItems}
          />
        }
        headerTitle={
          <HeaderTitle
            title={`Steuerportal Kanton ${kantonName}`}
            logo={
              kantonInfo.kanton === "ow"
                ? ICON_PROPS.OWLogoIcon
                : ICON_PROPS.NWLogoIcon
            }
          />
        }
        metaNav={
          <TextButton
            text={userName}
            menuProps={MenuProps}
            className={"small"}
          />
        }
        metaButtons={
          <ButtonList gap="narrow">
            <IconButton
              iconProps={ICON_PROPS.ShareIcon}
              ariaLabel={"Teilen verwalten"}
              onClick={() => navigate(`/teilen/`)}
            />
            <IconButton
              iconProps={ICON_PROPS.HelpIcon}
              ariaLabel={"Hilfe"}
              href={
                kantonInfo.kanton === "ow"
                  ? "https://ow-support.etax.ch/"
                  : "https://nw-support.etax.ch/"
              }
              target={"_blank"}
            ></IconButton>
            <IconButton
              iconProps={ICON_PROPS.LogOutIcon}
              ariaLabel={"Abmelden"}
              onClick={() => AuthService.doLogout()}
            />
          </ButtonList>
        }
        offScreenNaviIcon={ICON_PROPS.HamburgerIcon}
        offScreenNaviAriaOpen="Navigation öffnen"
        offScreenNaviAriaClose="Navigation schliessen"
        offScreenPanelUser={userName}
        offScreenPanelButtonList={
          <ButtonList gap="narrow">
            <TextButton
              iconProps={ICON_PROPS.ShareIcon}
              text={"Teilen verwalten"}
              ariaLabel={"Teilen verwalten"}
              onClick={() => navigate(`/teilen/`)}
            />
            <TextButton
              iconProps={ICON_PROPS.HelpIcon}
              text={"Hilfe"}
              ariaLabel={"Hilfe"}
              href={
                kantonInfo.kanton === "ow"
                  ? "https://ow-support.etax.ch/"
                  : "https://nw-support.etax.ch/"
              }
              target={"_blank"}
            />
            <TextButton
              text={"Abmelden"}
              iconProps={ICON_PROPS.LogOutIcon}
              ariaLabel={"Abmelden"}
              onClick={() => AuthService.doLogout()}
            />
          </ButtonList>
        }
      />
      <ContentContainer className="l-flex-justify-end l-m-b-32">
        {backButtonPath && (
          <IconButton
            iconProps={ICON_PROPS.LongArrowLeftIcon}
            ariaLabel={"Zurück"}
            onClick={() =>
              backButtonClear
                ? navigate(backButtonPath, {
                    state: backButtonState ? backButtonState : {},
                  })
                : navigate(backButtonPath)
            }
          />
        )}
        {mainText && (
          <ContentContainer className="l-fb-0">
            <Text as="h2" className="title2">
              {mainText}
            </Text>
          </ContentContainer>
        )}
        <ImpressumPanel isOpen={isOpen} onDismiss={dismissPanel} />
        {displayButtons && TitleActionMandantenButtons}
      </ContentContainer>
    </div>
  );
}

export default HeaderDefault;
