import React, { useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectList,
  ObjectListHeader,
  Expander,
  RevealCheckbox,
  RadioButton,
  FieldSet,
  Checkbox,
  ObjectItem,
} from "@riag-libs/etax-pattern-library";
import { Text, FontIcon, Shimmer, ShimmerElementType } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  DeliveryWarning,
  MandantSettingsError,
  SettingHelp,
} from "../../helpers/helpers";
import { KantonContext } from "../../contexts";

export const MandantSettings = () => {
  const kantonInfo = useContext(KantonContext);
  const [mandants, setMandants] = useState([]);
  const [mandantsLoading, setMandantsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getMandateList = async () => {
    const response = await HttpService.getAxiosClient().get("/api/mandants");
    if (response.status === 200) {
      setMandantsLoading(false);
      setMandants(
        response.data.filter((mandant) => mandant.activated === true)
      );
    }
  };

  useEffect(() => {
    getMandateList();
  }, []);

  const postMandantNotificationSettings = async (
    updatedSettings,
    subjektid
  ) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        `/api/mandant/${subjektid}/notification/settings`,
        {
          ...updatedSettings.notificationSettings,
        }
      );
      if (response.status === 200) {
        setError(null);
      }
      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating settings");
    }
  };

  const handleCustomSettingChange = (
    mandantIndex,
    settingKey,
    newValue,
    subjektid
  ) => {
    let updatedMandants = [...mandants];

    if (
      kantonInfo.kanton === "ow" &&
      settingKey === "deliveryWay" &&
      newValue === "paper" &&
      updatedMandants[mandantIndex].notificationSettings[
        "newMessageNotificationType"
      ] === "sms"
    ) {
      updatedMandants[mandantIndex].notificationSettings[
        "newMessageNotificationType"
      ] = "none";
    }

    if (
      kantonInfo.kanton === "ow" &&
      settingKey === "deliveryWay" &&
      newValue === "paper" &&
      updatedMandants[mandantIndex].notificationSettings[
        "newDocNotificationType"
      ] === "sms"
    ) {
      updatedMandants[mandantIndex].notificationSettings[
        "newDocNotificationType"
      ] = "none";
    }

    if (
      kantonInfo.kanton === "ow" &&
      settingKey === "deliveryWay" &&
      newValue === "paper" &&
      updatedMandants[mandantIndex].notificationSettings[
        "dueDateNotificationType"
      ] === "sms"
    ) {
      updatedMandants[mandantIndex].notificationSettings[
        "dueDateNotificationType"
      ] = "none";
    }

    updatedMandants[mandantIndex].notificationSettings[settingKey] =
      settingKey === "custom"
        ? !updatedMandants[mandantIndex].notificationSettings["custom"]
        : newValue;

    if (
      updatedMandants[mandantIndex].notificationSettings.deliveryWay ===
        "electronic" &&
      updatedMandants[mandantIndex].notificationSettings
        .newDocNotificationType === "none"
    ) {
      updatedMandants[
        mandantIndex
      ].notificationSettings.newDocNotificationType = "email";
    }

    setMandants(updatedMandants);
    postMandantNotificationSettings(updatedMandants[mandantIndex], subjektid);
  };

  const generateOptions = (deliveryWay) => {
    if (deliveryWay === "electronic") {
      return [
        { key: "email", text: "per E-Mail" },
        {
          key: "sms",
          text: "per SMS",
        },
      ];
    } else {
      return [
        { key: "email", text: "per E-Mail" },
        {
          key: "sms",
          text: "per SMS",
          ...(kantonInfo.kanton === "ow" && { disabled: true }),
        },
        {
          key: "none",
          text: "keine Notifikation",
        },
      ];
    }
  };

  const displayMessage = () => {
    if (error) {
      return MandantSettingsError;
    } else {
      return null;
    }
  };

  return (
    <MainContentContainer messages={displayMessage()}>
      <Text as="p" className="t-standard">
        {kantonInfo.kanton === "ow"
          ? "Definieren Sie hier pro Mandant abweichende Zustellungs- und Benachrichtigungseinstellungen."
          : "Definieren Sie hier pro Mandant abweichende Benachrichtigungseinstellungen."}
      </Text>
      <ObjectList
        style={{ marginTop: "16px" }}
        // title="Kommunikations-Einstellungen pro Mandant"
        headingTag="h2"
        headingTagClass="title2"
        listHeader={
          <ObjectListHeader>
            <ContentContainer className="flexItem l-fb-50">
              <Text as="span" className="t-listHeading l-m-l-32">
                Name
              </Text>
            </ContentContainer>
            <ContentContainer className="flextItem l-flex-align-end l-fb-50">
              <Text as="span" className="flexItem t-listHeading l-fb-60">
                PID
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-40"
              >
                abweichende Einstellungen
              </Text>
            </ContentContainer>
          </ObjectListHeader>
        }
      >
        {mandantsLoading ? (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        ) : mandants.length === 0 ? (
          <ObjectItem
            className="infoWarning"
            ariaLabel="Information zur leeren Liste"
          >
            <ContentContainer className="horizontal">
              <FontIcon
                iconName={ICON_PROPS.InfoIcon.iconName}
                className="objectItemIcon"
              />
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Sie haben noch kein aktiviertes Steuerkonto.
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        ) : (
          mandants
            .filter((mandant) => mandant.activated === true)
            .map((mandant, index) => {
              return (
                <Expander
                  title={`${mandant.name}`}
                  headerTitleTag="h3"
                  headerTitleTagClass="title3"
                  headerTitleSize="l-fb-50"
                  className="formContent"
                  headerContent={
                    <>
                      <ContentContainer
                        size="l-fb-60 l-flex-nogrow"
                        className="horizontal"
                      >
                        <Text
                          as="span"
                          className="flexItem t-listText hiddenLabel"
                        >
                          PID:
                        </Text>
                        <Text
                          as="span"
                          className="flexItem t-listText t-right-s"
                        >
                          {mandant.SubjektId}
                        </Text>
                      </ContentContainer>
                      <ContentContainer
                        size="l-fb-40 l-flex-nogrow"
                        className="horizontal"
                      >
                        <Text
                          as="span"
                          className="flexItem t-listText hiddenLabel"
                        >
                          Individuelle Einstellungen:
                        </Text>
                        <Text as="span" className="flexItem t-listText t-right">
                          {mandant.notificationSettings.custom === true && (
                            <FontIcon
                              iconName={ICON_PROPS.CheckIcon.iconName}
                              className="objectItemIcon-medium"
                            />
                          )}
                        </Text>
                      </ContentContainer>
                    </>
                  }
                  headerContentSize="l-fb-50"
                >
                  <ContentContainer className="vertical">
                    <>
                      <RevealCheckbox
                        ariaPoliteText="Die Eingabefelder für die individuellen Einstellungen werden angzeigt"
                        action={(event, checked) =>
                          handleCustomSettingChange(
                            index,
                            "custom",
                            checked,
                            mandant.SubjektId
                          )
                        }
                        checkbox={
                          <Checkbox
                            label={`Für ${mandant.name} sind abweichende Einstellungen aktiviert.`}
                            defaultChecked={mandant.notificationSettings.custom}
                          />
                        }
                        initialVisible={mandant.notificationSettings.custom}
                      >
                        {mandant.owned === true && (
                          <RadioButton
                            className="l-m-t-8"
                            active
                            defaultSelectedKey={
                              mandant.notificationSettings.deliveryWay
                            }
                            label="Wie sollen amtliche Dokumente zugestellt werden?"
                            options={[
                              {
                                key: "electronic",
                                text: "digital ins Steuerportal (keine Briefpost)",
                              },
                              { key: "paper", text: "per Briefpost" },
                            ].filter(
                              (option) =>
                                !(
                                  option.key === "electronic" &&
                                  kantonInfo.kanton === "nw"
                                )
                            )}
                            onChange={(event, value) => {
                              handleCustomSettingChange(
                                index,
                                "deliveryWay",
                                value.key,
                                mandant.SubjektId
                              );
                            }}
                            outSideChild={
                              kantonInfo.kanton === "ow" && SettingHelp
                            }
                          />
                        )}
                        {mandant.owned === true &&
                          mandant.notificationSettings.deliveryWayPartner &&
                          mandant.notificationSettings.deliveryWayPartner ===
                            "electronic" &&
                          mandant.notificationSettings.deliveryWay ===
                            "paper" &&
                          kantonInfo.kanton === "ow" &&
                          DeliveryWarning}
                        <FieldSet
                          title={
                            kantonInfo.kanton === "ow"
                              ? "Wie möchten Sie bei Verfügbarkeit neuer Dokumente und Mitteilungen im Steuerportal benachrichtigt werden?"
                              : "Wie möchten Sie, nebst dem standardmässigen Postversand, bei Verfügbarkeiten neuer Dokumente und Mitteilungen im Steuerportal benachrichtigt werden?"
                          }
                          headingTagClass="title5"
                        >
                          <ContentContainer
                            style={{ marginTop: "8px" }}
                            className="l-flex-columngap-16 l-flex-align-start"
                          >
                            <RadioButton
                              key={
                                mandant.notificationSettings
                                  .newDocNotificationType
                              }
                              active
                              defaultSelectedKey={
                                mandant.notificationSettings
                                  .newDocNotificationType
                              }
                              label="Neue Dokumente"
                              size="l-w-330"
                              options={generateOptions(
                                mandant.notificationSettings.deliveryWay
                              )}
                              onChange={(event, value) =>
                                handleCustomSettingChange(
                                  index,
                                  "newDocNotificationType",
                                  value.key,
                                  mandant.SubjektId
                                )
                              }
                            />
                            <RadioButton
                              active
                              selectedKey={
                                mandant.notificationSettings
                                  .dueDateNotificationType
                              }
                              label="Fristenerinnerungen"
                              size="l-w-330"
                              options={[
                                { key: "email", text: "per E-Mail" },
                                {
                                  key: "sms",
                                  text: "per SMS",
                                  ...(kantonInfo.kanton === "ow" &&
                                    mandant.notificationSettings.deliveryWay ===
                                      "paper" && {
                                      disabled: true,
                                    }),
                                },
                                { key: "none", text: "keine Notifikation" },
                              ]}
                              onChange={(event, value) =>
                                handleCustomSettingChange(
                                  index,
                                  "dueDateNotificationType",
                                  value.key,
                                  mandant.SubjektId
                                )
                              }
                            />
                            <RadioButton
                              active
                              selectedKey={
                                mandant.notificationSettings
                                  .newMessageNotificationType
                              }
                              label="Neue Nachrichten"
                              size="l-w-330"
                              options={[
                                { key: "email", text: "per E-Mail" },
                                {
                                  key: "sms",
                                  text: "per SMS",
                                  ...(kantonInfo.kanton === "ow" &&
                                    mandant.notificationSettings.deliveryWay ===
                                      "paper" && {
                                      disabled: true,
                                    }),
                                },
                                { key: "none", text: "keine Notifikation" },
                              ]}
                              onChange={(event, value) =>
                                handleCustomSettingChange(
                                  index,
                                  "newMessageNotificationType",
                                  value.key,
                                  mandant.SubjektId
                                )
                              }
                            />
                          </ContentContainer>
                        </FieldSet>
                      </RevealCheckbox>
                    </>
                  </ContentContainer>
                </Expander>
              );
            })
        )}
      </ObjectList>
    </MainContentContainer>
  );
};
