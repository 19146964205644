import {
  ContentContainer,
  Expander,
  IconButtonWithCallout,
  ObjectItem,
  ObjectList,
} from "@riag-libs/etax-pattern-library";
import { Link, MessageBar, MessageBarType, Text } from "@fluentui/react";
import React from "react";
import moment from "moment/moment";

export const newETaxDeclaration = (baseUri, kanton) => {
  let url = baseUri + "/#createNewDoc/";
  switch (kanton.toLowerCase()) {
    case "nw":
      url = url + "ch.nw";
      break;
    case "ow":
      url = url + "ch.ow";
      break;
    default:
      alert(`Don't know how to create new declaration for kanton ${kanton}`);
      return;
  }
  return url;
};

export const newETaxImport = (baseUri, kanton) => {
  if (kanton === "ow") {
    return baseUri + "#importDoc";
  }
  return baseUri + "#chooseImportFile";
};

export const linkToDeclaration = (baseUri, extension, submitted) => {
  if (submitted !== 0) {
    return baseUri + "#loadDoc/" + extension + "/SUBMISSIONSTATE";
  }
  return baseUri + "#loadDoc/" + extension;
};

export const deleteDeclaration = (baseUri, extension) => {
  return baseUri + "#deleteDoc/" + extension;
};

export const etaxDeclarationsExport = (baseUri, id, kanton) => {
  const cutBaseUri = baseUri.slice(0, -5).trim();
  if (kanton === "ow") {
    return cutBaseUri + "services/ow-channel/export/" + id;
  }
  if (kanton === "nw") {
    return cutBaseUri + "services/nw-channel/export/" + id;
  }
};

//

export const pickMessage = (
  submitError,
  agbError,
  agbSuccess,
  fields,
  kanton
) => {
  if (submitError) {
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem href="#">
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {submitError}
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
  if (agbError) {
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem href="#">
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {agbError}
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
  if (agbSuccess) {
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem>
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            className="title2"
          >
            <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8 l-m-b-16">
              <Text as="p" className="title2">
                Aktivierungscode angefordert
              </Text>
              <Text as="p" className="t-standard">
                Sie haben den Aktivierungscode für {fields.vorname}{" "}
                {fields.name} (PID-Nr. {fields.pid}) beantragt. Dieser wird
                Ihnen per Post zugeschickt.
              </Text>
              <Text as="p" className="t-standard">
                Weitere Informationen zur Aktivierung finden Sie in den{" "}
                <Link
                  href={
                    kanton === "ow"
                      ? "https://ow-support.etax.ch/hc/de/categories/360002998739-Bedienungsanleitung-eSteuerportal-Obwalden"
                      : "https://nw-support.etax.ch/hc/de/categories/360003003600-Bedienungsanleitung-eSteuerportal-Nidwalden"
                  }
                  target="_blank"
                  underline
                >
                  Hilfeseiten im Bereiche 'Bedienungsanleitung eSteuerportal'
                </Link>
                .
              </Text>
            </ContentContainer>
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
};

export const pickSharingMessage = (submitSuccess, submitError) => {
  if (submitSuccess) {
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem href="#">
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
          >
            Einladung erfolgreich versendet
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
  if (submitError) {
    if (submitError === "fields") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Bitte achten Sie darauf, dass alle Felder korrekt ausgefüllt sind.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (submitError === "username") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Sie können nicht mit sich selbst teilen. Bitte benutzen Sie eine
              andere E-Mail Adresse.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (submitError === "shared") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Sie können nicht an jemanden delegieren, an den Sie bereits
              delegiert haben.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (submitError === "emailmobile") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Überprüfen Sie die Eingabe Ihrer E-Mail Adresse und Mobile-Nummer.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (submitError === "email") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Überprüfen Sie die Eingabe Ihrere E-Mail Adresse.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (submitError === "mobile") {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem href="#">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Überprüfen Sie die Eingabe Ihrer Mobile-Nummer.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem href="#">
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Einladung konnte nicht gesendet werden
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
};

export const pickExtensionMessage = (success, extensionError) => {
  if (success) {
    const formattedActual = moment(success.actual).format("DD.MM.YYYY");
    const earlier = formattedActual !== success.requested;
    if (earlier) {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
            >
              Die Frist wurde auf das maximal mögliche Datum angepasst.
            </MessageBar>
          </ObjectItem>
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
            >
              Die Frist wurde bis zum{" "}
              {moment(success.actual).format("DD.MM.YYYY")} verlängert.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem>
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
          >
            Die Frist wurde bis zum{" "}
            {moment(success.actual).format("DD.MM.YYYY")} verlängert.
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
  if (extensionError) {
    return (
      <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
        <ObjectItem href="#">
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {extensionError}
          </MessageBar>
        </ObjectItem>
      </ObjectList>
    );
  }
};

export const deadlineDescription = (kanton, company) => {
  if (kanton === "ow" && !company) {
    return (
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 formData">
        <Text as="h3" className="title3" style={{ marginBottom: "16px" }}>
          Hinweise
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Die Steuererklärung ist bis zum Einreichedatum gemäss
          Aktivierungsschreiben oder bis zur gewährten Frist einzureichen.
          Elektronisch eingereichte Fristerstreckungsgesuche sind kostenlos.
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Weitere Information finden Sie unter{" "}
          <Link
            href="https://www.ow.ch/aemter/186"
            target="_blank"
            className="t-underline"
          >
            www.steuern.ow.ch
          </Link>
          .
        </Text>
      </ContentContainer>
    );
  }

  if (kanton === "ow" && company) {
    return (
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 formData">
        <Text as="h3" className="title3" style={{ marginBottom: "16px" }}>
          Hinweise
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Elektronisch eingereichte Fristerstreckungsgesuche sind kostenlos.
          Unternehmungen können ohne Begründung eine Fristerstreckung bis zum
          31.03 im Folgejahr beantragen.
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Wünschen Sie eine längere Frist, wenden Sie sich bitte an die
          Steuerverwaltung. Weitere Informationen finden Sie unter{" "}
          <Link
            href="https://www.ow.ch/aemter/186"
            target="_blank"
            className="t-underline"
          >
            www.steuern.ow.ch
          </Link>
          .
        </Text>
      </ContentContainer>
    );
  }

  if (kanton === "nw" && !company) {
    return (
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 formData">
        <Text as="h3" className="title3" style={{ marginBottom: "16px" }}>
          Hinweise
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Die Steuererklärung ist bis zum Einreichedatum gemäss
          Aktivierungsschreiben einzureichen.
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Die Fristen können online verlängert werden. Details zur Dauer der
          Fristerstreckung entnehmen Sie der Richtlinie “
          <Link
            href="https://www.steuern-nw.ch/app/uploads/2021/11/RL_Steuererklaerung_Fristen_2022.pdf"
            target="_blank"
            className="t-underline"
          >
            Frist zur Einreichung der Steuererklärung”
          </Link>
          . Wünschen Sie eine längere Frist als in der Richtlinie angegeben,
          wenden Sie sich bitte an das für Sie zuständige Steueramt.
        </Text>
      </ContentContainer>
    );
  }

  if (kanton === "nw" && company) {
    return (
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 formData">
        <Text as="h3" className="title3" style={{ marginBottom: "16px" }}>
          Hinweise
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Die ordentliche Frist für die Einreichung der Steuererklärung der
          juristischen Personen ist der 30.06 des auf die Steuerperiode
          folgenden Jahres.
        </Text>
        <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
          Fristen können online längstens bis zum 31.12. verlängert werden.
          Wünschen Sie eine längere Frist, wenden Sie sich bitte an das
          Kantonale Steueramt.
        </Text>
        <br />
      </ContentContainer>
    );
  }
};

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const createFaq = (kantonInfo, navigate) => {
  return (
    <ObjectList
      title="Benötigen Sie Hilfe?"
      headingTag="h3"
      headingTagClass="title3"
      className={"faq"}
      style={{ marginTop: "64px" }}
    >
      <Expander
        title={`Wie kann ich eine neue Person bzw. einen Mandanten anlegen?`}
        headerTitleTag="h4"
        style={{ borderTop: "0px" }}
        headerTitleTagClass="t-standard"
        className="formContent"
      >
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
          <Text
            as="span"
            className="flexItem t-standard t-overflow-wrap l-fb-50"
          >
            Eine neue Person bzw. ein Mandant wird automatisch angelegt, indem
            Sie
          </Text>
          <ul
            style={{
              marginLeft: "32px",
              marginTop: "16px",
            }}
          >
            <li style={{ listStyleType: "circle" }}>
              <Text
                as="span"
                className="flexItem t-standard t-overflow-wrap l-fb-50"
              >
                eine Steuererklärung für einen{" "}
                <Link
                  href={newETaxDeclaration(
                    kantonInfo.etaxBaseUri,
                    kantonInfo.kanton
                  )}
                  target="_blank"
                  underline
                >
                  neue/n Steuerpflichtige/n
                </Link>{" "}
                eröffnen oder
              </Text>
            </li>
            <li style={{ listStyleType: "circle" }}>
              <Text
                as="span"
                className="flexItem t-standard t-overflow-wrap l-fb-50"
              >
                für eine Person das{" "}
                <Link
                  onClick={() => navigate(`/aktivieren/`)}
                  target="_blank"
                  underline
                >
                  Steuerkonto aktivieren.
                </Link>{" "}
              </Text>
            </li>
          </ul>
        </ContentContainer>
      </Expander>
      <Expander
        title={`Welche Funktionen sind im Steuerkonto verfügbar?`}
        headerTitleTag="h4"
        headerTitleTagClass="t-standard"
        className="formContent"
      >
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
          <Text
            as="span"
            className="flexItem t-standard t-overflow-wrap l-fb-50"
          >
            Im Steuerkonto können Sie folgende Aktionen vornehmen:
          </Text>
          {kantonInfo.kanton === "ow" ? (
            <ul
              style={{
                marginLeft: "32px",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Provisorische und definitive Veranlagungen anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Rechnungen zur Steuererklärung anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Getätigte Zahlungen und die offene Steuerschuld einsehen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Rechte zur Bearbeitung und Einreichung der Steuererklärungen
                  einer anderen Personen übertragen sowie die vergebenen Rechte
                  wieder entfernen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Einzahlungsscheine generieren und anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Kommunikationseinstellung zur Zustellung von amtlichen
                  Dokumenten definieren
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Kommunikationseinstellung zur Benachrichtigung von neuen
                  Dokumenten und Mitteilungen definieren
                </Text>
              </li>
            </ul>
          ) : (
            <ul
              style={{
                marginLeft: "32px",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Provisorische und definitive Veranlagungen anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Rechnungen zur Steuererklärung anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Getätigte Zahlungen und die offene Steuerschuld einsehen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Rechte zur Bearbeitung und Einreichung der Steuererklärungen
                  einer anderen Personen übertragen sowie die vergebenen Rechte
                  wieder entfernen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Einzahlungsscheine generieren und anzeigen
                </Text>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  Kommunikationseinstellung zur Benachrichtigung von neuen
                  Dokumenten und Mitteilungen definieren
                </Text>
              </li>
            </ul>
          )}
          <Text
            as="span"
            className="flexItem t-standard t-overflow-wrap l-fb-50"
          >
            Das Steuerkonto wird regelmässig um weitere Funktionen erweitert.
            Weitere Informationen finden Sie im{" "}
            <Link
              href={
                kantonInfo.kanton === "ow"
                  ? "https://ow-support.etax.ch/hc/de/categories/360002998739-Bedienungsanleitung-eSteuerportal-Obwalden"
                  : "https://nw-support.etax.ch/hc/de/categories/360003003600-Bedienungsanleitung-eSteuerportal-Nidwalden"
              }
              target="_blank"
              underline
            >
              Helpdesk.
            </Link>
          </Text>
        </ContentContainer>
      </Expander>
      <Expander
        title={`Wie kann ich das Steuerkonto für eine bestimmte Person aktivieren?`}
        headerTitleTag="h4"
        headerTitleTagClass="t-standard"
        className="formContent"
      >
        <ContentContainer className="l-flex-rowgap-4">
          <Text
            as="span"
            className="flexItem t-standard t-overflow-wrap l-fb-50"
          >
            Eine Schritt-für-Schritt-Anleitung zur Aktivierung des Steuerkontos
            finden Sie{" "}
            <Link
              href={
                kantonInfo.kanton === "ow"
                  ? "https://ow-support.etax.ch/hc/de/categories/360002998739-Bedienungsanleitung-eSteuerportal-Obwalden"
                  : "https://nw-support.etax.ch/hc/de/categories/360003003600-Bedienungsanleitung-eSteuerportal-Nidwalden"
              }
              target="_blank"
              underline
            >
              im Helpdesk.
            </Link>{" "}
          </Text>
        </ContentContainer>
      </Expander>
      <Expander
        title={`Wie kann ich eine Frist zur Einreichung der Steuererklärung verlängern?`}
        headerTitleTag="h4"
        headerTitleTagClass="t-standard"
        className="formContent"
      >
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
          <Text
            as="span"
            className="flexItem t-standard t-overflow-wrap l-fb-50"
          >
            Sie können dies über das Kontextmenü{" "}
            <Link
              onClick={() => navigate(`/extension/`)}
              target="_blank"
              underline
            >
              "Frist verlängern"
            </Link>{" "}
            durchführen.
          </Text>
        </ContentContainer>
      </Expander>
    </ObjectList>
  );
};

export const EmailSent = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
        Es wurde ein Verifikations-Code an Ihre E-Mail Adresse verschickt. Bitte
        geben Sie diesen Code unten ein.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const EmailVerified = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
        Ihre E-Mail Adresse wurde erfolgreich verifiziert.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const SettingHelp = (
  <IconButtonWithCallout
    calloutText={
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 l-m-b-16">
        <Text as="p" className="t-standard">
          Bei digitaler Zustellung werden keine Papierdokumente per Briefpost
          verschickt. Die Zustellung erfolgt ausschliesslich digital ins
          Steuerportal.
        </Text>
      </ContentContainer>
    }
    calloutTitle="Digitale Zustellung"
    calloutTitleHeading="h3"
    iconButtonAriaLabel="KontextInformation zur Zustellart"
  />
);

export const NotificationHelp = (
  <IconButtonWithCallout
    calloutText={
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 l-m-b-16">
        <Text as="p" className="t-standard">
          Individuelle Einstellungen der Notifikationsart können im Register
          "Einstellungen pro Mandant" vorgenommen werden.
        </Text>
      </ContentContainer>
    }
    calloutTitle="Notifikationsart"
    calloutTitleHeading="h3"
    iconButtonAriaLabel="KontextInformation zur Zustellart"
  />
);

export const NotificationHelpPID = (
  <IconButtonWithCallout
    calloutText={
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 l-m-b-16">
        <Text as="p" className="t-standard">
          Bei digitaler Zustellung amtlicher Dokumente kann die Notifikation für
          "neue Dokumente" nicht ausgeschaltet werden.
        </Text>
      </ContentContainer>
    }
    calloutTitle="Notifikationsart"
    calloutTitleHeading="h3"
    iconButtonAriaLabel="KontextInformation zur Zustellart"
  />
);

export const SMSSent = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
        Es wurde eine SMS mit Verifikations-Code an Ihre Mobile-Nummer
        verschickt. Bitte geben Sie diesen Code unten ein.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const SMSVerified = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
        Ihre Mobile-Nummer wurde erfolgreich verifiziert.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const MandantSettingsError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Die von Ihnen gewählte Einstellung konnte nicht gespeichert werden.
        Bitte versuchen Sie es erneut!
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const GlobalSettingsError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Die von Ihnen gewählte Einstellung konnte nicht gespeichert werden.
        Bitte versuchen Sie es erneut!
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const VerifyEmailCodeError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Der Code wurde falsch eingegeben.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const VerifyMobileCodeError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Der Code wurde falsch eingegeben.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const MobileFormatError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Bitte geben Sie eine gültige Mobile-Nummer ein.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const EmailFormatError = (
  <ObjectList
    title="Fehler"
    headerTitleTag="h3"
    titleVisuallyHidden={true}
    className="messageList"
  >
    <ObjectItem>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        Bitte geben Sie eine gültige E-Mail Adresse ein.
      </MessageBar>
    </ObjectItem>
  </ObjectList>
);

export const DeliveryWarning = (
  <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={true}>
    <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-4">
      <Text as="span" className="title3">
        Achtung: Ihre Dokumente werden digital ins Steuerportal zugestellt
      </Text>
      <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
        <Text as="p" className="t-standard">
          Ihr/Ihre Partner(in) hat die digitale Zustellung eingestellt.{" "}
        </Text>
        <Text as="p" className="t-standard">
          Für die Zustellung per Briefpost müssen beide Partner die Option "per
          Briefpost" wählen.
        </Text>
      </ContentContainer>
    </ContentContainer>
  </MessageBar>
);
