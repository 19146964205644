import { ContentContainer } from "@riag-libs/etax-pattern-library";
import { Link, Text } from "@fluentui/react";
import React from "react";

export const PrivateActivationDescription = (
  <ContentContainer
    className="l-flex-column l-flex-align-start l-flex-rowgap-0"
    style={{ marginTop: "8px" }}
  >
    <Text as="p" className="t-standard">
      Bitte geben Sie Vorname, Geburtsdatum, und PID-Nr. ein, um die Aktivierung
      des Steuerportals zu beantragen.
    </Text>
    <Text as="p" className="t-standard" style={{ marginBottom: "8px" }}>
      Der Vor- und Nachname muss exakt mit den Angaben auf dem Brief
      "Steuererklärung" übereinstimmen.
    </Text>
  </ContentContainer>
);

export const CompanyActivationDescription = (
  <ContentContainer
    className="l-flex-column l-flex-align-start l-flex-rowgap-0"
    style={{ marginTop: "8px", marginBottom: "8px" }}
  >
    <Text as="span" className="title4">
      Schritt 1 – Vollmacht ausfüllen, unterzeichnen und an das Steueramt NW
      senden
    </Text>
    <Text as="p" className="t-standard">
      Bitte füllen Sie{" "}
      <Text as="span" className="title4">
        vorgängig
      </Text>{" "}
      das Formular 'Vollmacht JP Steuerportal NW' aus und schicken Sie es
      unterzeichnet entweder per E-Mail an{" "}
      <Link href="mailto:steueramt@nw.ch" target="_blank" underline>
        steueramt@nw.ch
      </Link>{" "}
      oder per Post an die Adresse in der Kopfzeile des Formulars.
    </Text>
    <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
      <Link
        href="https://www.steuern-nw.ch/app/uploads/2023/01/Formular_Vollmacht-JP-Steuerportal-1.pdf"
        target="_blank"
        underline
      >
        Hier können Sie das Formular 'Vollmacht JP Steuerportal NW'
        herunterladen
      </Link>
    </Text>
    <Text as="span" className="title4">
      Schritt 2 – Aktivierungscode beantragen
    </Text>

    <Text as="p" className="t-standard">
      Nach Eingang und Prüfung der Vollmacht durch das Kantonale Steueramt, wird
      die bevollmächtigte Person{" "}
      <Text as="span" className="title4">
        per E-Mail
      </Text>{" "}
      informiert, dass die Akivierung des Steuerportal für das Unternehmen
      beantragt werden kann.
    </Text>
  </ContentContainer>
);

export const CompanyActivationDescriptionOW = (
  <ContentContainer
    className="l-flex-column l-flex-align-start l-flex-rowgap-0"
    style={{ marginTop: "8px", marginBottom: "8px" }}
  >
    <Text as="span" className="title4">
      Schritt 1 - Vollmacht ausfüllen, unterzeichnen und dann an die
      Steuerverwaltung OW senden
    </Text>
    <Text as="p" className="t-standard">
      Bitte füllen Sie{" "}
      <Text as="span" className="title4">
        vorgängig
      </Text>{" "}
      das Formular "Vollmacht für das Steuerportal Obwalden" aus und schicken
      Sie es unterzeichnet entweder per E-Mail an{" "}
      <Link href="mailto:steuerverwaltung@ow.ch" target="_blank" underline>
        steuerverwaltung@ow.ch
      </Link>{" "}
      oder per Post an die auf der Vorderseite angedruckte Adresse des
      Formulars.
    </Text>
    <Text as="p" className="t-standard" style={{ marginBottom: "16px" }}>
      <Link
        href="https://www.ow.ch/_docn/321284/Vollmacht_JP_Steuerportal_OW.pdf"
        target="_blank"
        underline
      >
        Hier können Sie das Formular herunterladen
      </Link>
    </Text>
    <Text as="span" className="title4">
      Schritt 2 – Aktivierungscode beantragen
    </Text>

    <Text as="p" className="t-standard">
      Nach Eingang und Prüfung der Vollmacht durch die Steuerverwaltung OW, wird
      die bevollmächtigte Person{" "}
      <Text as="span" className="title4">
        per E-Mail
      </Text>{" "}
      informiert, dass die Akivierung des Steuerportal für das Unternehmen
      beantragt werden kann.
    </Text>
  </ContentContainer>
);
