import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  FieldSet,
  ICON_PROPS,
  ObjectItem,
  ObjectList,
  Panel,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PanelType,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { KantonContext } from "../../contexts";

export const ActivationCodePanel = (props) => {
  const { panelData, setData } = props;
  const kantonInfo = useContext(KantonContext);
  const [localSettings, setLocalSettings] = useState({});

  const getMandateList = async () => {
    const response = await HttpService.getAxiosClient().get("/api/mandants");
    if (response.status === 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    const getUserNotificationSettings = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/user/notification/settings"
      );
      if (response.status === 200) {
        setLocalSettings(response.data);
      }
    };
    getUserNotificationSettings();
  }, []);

  const [fields, setFields] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  const onRenderFooterContent = React.useCallback(
    () => (
      <DefaultButton
        onClick={() => {
          getMandateList();
          props.onDismiss();
          setSuccessMessage(false);
          setErrorMessage(false);
        }}
      >
        Schliessen
      </DefaultButton>
    ),
    [props.dismissPanel, navigate]
  );

  const handleClick = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/account/activate",
        {
          SubjektId: panelData.SubjektId,
          AktivierungsCode: fields.aktivierungscode,
        }
      );
      if (response.status === 200) {
        setErrorMessage(false);
        setSuccessMessage(true);
      }
    } catch (error) {
      setErrorMessage(
        "Der eingegebene Aktivierungscode ist ungültig - bitte validieren Sie Ihre Eingabe."
      );
      console.log(error, error.message);
    }
  };

  const activationSuccessMessage = () => (
    <ObjectList
      title="Meldungen"
      headingTag="h2"
      titleVisuallyHidden={true}
      className="messageList"
    >
      <ObjectItem>
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
            <Text as="h2" className="title2">
              Aktivierung erfolgt!
            </Text>
            <ContentContainer className="vertical l-flex-rowgap-4 l-1of1">
              <Text as="h3" className="t-listTextBig">
                Ihre Kommunikations-einstellungen:
              </Text>
              <ContentContainer className="vertical flexItem l-flex-rowgap-0">
                <Text as="span" className="flexItem title4">
                  Zustellung:
                </Text>
                <Text as="span" className="flexItem t-standard">
                  {kantonInfo.kanton === "nw"
                    ? "per Briefpost"
                    : localSettings?.deliveryWay === "electronic"
                    ? "digital ins Steuerportal (keine Briefpost)"
                    : "per Briefpost"}
                </Text>
              </ContentContainer>
              <ContentContainer className="vertical l-flex-rowgap-0">
                <Text as="span" className="flexItem t-standard title4">
                  Benachrichtigung:
                </Text>
                <Text as="span" className="flexItem t-standard">
                  per E-Mail: {panelData.notificationSettings.email}
                </Text>
              </ContentContainer>
            </ContentContainer>
            <DefaultButton
              text="Einstellungen anpassen"
              iconProps={ICON_PROPS.SettingsIcon}
              onClick={() => navigate(`/settings`)}
            />
          </ContentContainer>
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const activationSuccessPartnerWarningMessage = () => (
    <div>
      <MessageBar
        messageBarType={MessageBarType.severeWarning}
        isMultiline={true}
      >
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-4">
          <Text as="span" className="title3">
            Achtung: Ihre Dokumente werden digital ins Steuerportal zugestellt
          </Text>
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
            <Text as="p" className="t-standard">
              Ihr/Ihre Partner(in) hat die digitale Zustellung eingestellt.{" "}
            </Text>
            <Text as="p" className="t-standard">
              Für die Zustellung per Briefpost müssen beide Partner die Option
              "per Briefpost" wählen.
            </Text>
          </ContentContainer>
        </ContentContainer>
      </MessageBar>
    </div>
  );

  const activationErrorMessage = (msg) => (
    <ObjectList
      className="messageList"
      title="Meldungen"
      headingTag="h2"
      titleVisuallyHidden={true}
    >
      <ObjectItem>
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {msg}
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const handleKeyUp = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("activateBtn").click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  return (
    <Panel
      headerText={`${panelData.name} (PID: ${panelData.SubjektId})`}
      panelType={PanelType.small}
      isOpen={props.isOpen}
      className={"owTheme"}
      dismissPanel={() => {
        getMandateList();
        setSuccessMessage(false);
        setErrorMessage(false);
        props.onDismiss();
      }}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <ContentContainer className="vertical">
        <FieldSet
          title="Aktivierungscode eingeben"
          headingTag="h2"
          headingTagClass="title2 l-m-tb-16"
          description={
            <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
              <Text as="p" className="t-standard">
                Sie haben den Aktivierungscode für die PID {panelData.SubjektId}{" "}
                am {moment(panelData.registrationDate).format("DD.MM.YYYY")}{" "}
                angefordert. Er wird Ihnen per Post zugeschickt.
              </Text>
              <Text as="p" className="t-standard">
                Bitte geben Sie den Aktivierungscode hier ein, um die
                Aktivierung des Steuerportals abzuschliessen.
              </Text>
            </ContentContainer>
          }
        >
          {successMessage && activationSuccessMessage()}
          {panelData.owned === true &&
            panelData.notificationSettings.deliveryWayPartner &&
            panelData.notificationSettings.deliveryWayPartner ===
              "electronic" &&
            panelData.notificationSettings.deliveryWay === "paper" &&
            kantonInfo.kanton === "ow" &&
            activationSuccessPartnerWarningMessage()}
          {errorMessage && activationErrorMessage(errorMessage)}
          <TextField
            label="Aktivierungscode"
            onChange={(event) => {
              setFields({
                ...fields,
                aktivierungscode: event.target.value,
              });
            }}
          />
          <ContentContainer className="l-m-t-8">
            <PrimaryButton
              disabled={
                successMessage ||
                fields.aktivierungscode === "" ||
                !fields.aktivierungscode
              }
              text="Steuerportal aktivieren"
              id={"activateBtn"}
              iconProps={ICON_PROPS.CheckIcon}
              onClick={() => handleClick()}
            />
          </ContentContainer>
        </FieldSet>
      </ContentContainer>
    </Panel>
  );
};
