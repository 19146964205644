import React, { useContext } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Link, Text } from "@fluentui/react";
import { KantonContext } from "../../contexts";

const TaxDeclarationsHeader = (
  <ObjectListHeader>
    <ContentContainer className="flexItem l-fb-40">
      <Text as="span" className="t-listHeading l-m-l-32">
        Name
      </Text>
    </ContentContainer>
    <ContentContainer className="flextItem l-flex-align-end l-fb-60">
      <Text as="span" className="flexItem t-listHeading l-fb-40">
        PID
      </Text>
      <Text as="span" className="flexItem t-listHeading t-right l-fb-20">
        Anzahl geteilt
      </Text>
    </ContentContainer>
  </ObjectListHeader>
);

export const NothingSharedMessage = (props) => {
  const kantonInfo = useContext(KantonContext);
  const { type } = props;
  if (type === "vonmir") {
    return (
      <MainContentContainer>
        <ObjectList
          title={"Von mir geteilt mit"}
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxDeclarationsHeader}
          className="tableView bigTitle"
        >
          <ObjectItem
            className="infoWarning"
            ariaLabel="Information zur leeren Liste"
          >
            <ContentContainer className="horizontal">
              <FontIcon
                iconName={ICON_PROPS.InfoIcon.iconName}
                className="objectItemIcon"
              />
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Von Ihnen wurde noch nichts geteilt.
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Weitere Informationen und Anleitungen zum Teilen finden Sie in
                  der Hilfe im Bereich{" "}
                  <Link
                    href={
                      kantonInfo.kanton === "ow"
                        ? "https://ow-support.etax.ch/"
                        : "https://nw-support.etax.ch/"
                    }
                    target="_blank"
                    underline
                  >
                    "Bedienungsanleitungen eSteuerportal"
                  </Link>
                  .
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      </MainContentContainer>
    );
  }
  if (type === "mitmir") {
    return (
      <MainContentContainer>
        <ObjectList
          title={"Mit mir geteilte Inhalte von"}
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxDeclarationsHeader}
          className="tableView bigTitle"
        >
          <ObjectItem
            className="infoWarning"
            ariaLabel="Information zur leeren Liste"
          >
            <ContentContainer className="horizontal">
              <FontIcon
                iconName={ICON_PROPS.InfoIcon.iconName}
                className="objectItemIcon"
              />
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Mit Ihnen wurde noch nichts geteilt.
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Weitere Informationen und Anleitungen zum Teilen finden Sie in
                  der Hilfe im Bereich{" "}
                  <Link
                    href={
                      kantonInfo.kanton === "ow"
                        ? "https://ow-support.etax.ch/"
                        : "https://nw-support.etax.ch/"
                    }
                    target="_blank"
                    underline
                  >
                    "Bedienungsanleitungen eSteuerportal"
                  </Link>
                  .
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      </MainContentContainer>
    );
  }
  if (type === "einladungen") {
    return (
      <MainContentContainer>
        <ObjectList
          title={"Offene Einladungen für"}
          headingTag="h3"
          headingTagClass="title2"
          listHeader={TaxDeclarationsHeader}
          className="tableView bigTitle"
        >
          <ObjectItem
            className="infoWarning"
            ariaLabel="Information zur leeren Liste"
          >
            <ContentContainer className="horizontal">
              <FontIcon
                iconName={ICON_PROPS.InfoIcon.iconName}
                className="objectItemIcon"
              />
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Sie haben keine offenen Einladungen.
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Weitere Informationen und Anleitungen zum Teilen von Inhalten
                  finden Sie in den{" "}
                  <Link
                    href={
                      kantonInfo.kanton === "ow"
                        ? "https://ow-support.etax.ch/"
                        : "https://nw-support.etax.ch/"
                    }
                    target="_blank"
                    underline
                  >
                    Hilfeseiten im Bereiche 'Bedienungsanleitung eSteuerportal'
                  </Link>
                  .
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      </MainContentContainer>
    );
  }
};
