import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AuthService from "./services/AuthService";
import HttpService from "./services/HttpService";

const fetchData = async () => {
  const response = await HttpService.getAxiosClient().get("/api/configuration");
  if (response.status === 200) {
    const conf = {
      realm: response.data.auth.realm,
      url: response.data.auth["auth-server-url"],
      clientId: response.data.auth.resource,
    };

    const kantonInfo = {
      etaxBaseUri: response.data.etaxBaseUri,
      kanton: response.data.kanton,
      kantonTitleKey: response.data.kantonTitleKey,
      supportUrl: response.data.supportUrl,
      userAccountUrl: response.data.userAccountUrl,
      serverVersion: response.data.serverVersion,
      mode: response.data.mode,
      ...(response.data.jpOWUrl && {
        jpOWUrl: response.data.jpOWUrl,
      }),
      matrixBaseUrl: response.data.matrixBaseUrl,
    };

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (kantonInfo.kanton === "ow") {
      link.href = "faviconow.ico";
    }
    if (kantonInfo.kanton === "nw") {
      link.href = "faviconnw.ico";
    }

    const renderApp = () =>
      ReactDOM.render(
        <App kantonInfo={kantonInfo} />,
        document.getElementById("root")
      );

    AuthService.initKeycloak(renderApp, conf);
    HttpService.configure();
  }
};

fetchData();
