import React, { useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  Link,
  MessageBar,
  MessageBarType,
  Text,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import { Buffer } from "buffer";
import AuthService from "../../services/AuthService";
import { KantonContext } from "../../contexts";
import { useNavigate } from "react-router-dom";

export const SingleDocument = (props) => {
  const { docId, mandant } = props;
  const kantonInfo = useContext(KantonContext);
  const navigate = useNavigate();
  const [pdf, setPdf] = useState("");
  const [validUser, setValidUser] = useState(false);
  const [displayConfirmationMsg, setDisplayConfirmationMsg] = useState(false);

  useEffect(() => {
    if (mandant.SubjektId) {
      const getPdf = async () => {
        try {
          const response = await HttpService.getAxiosClient().get(
            `/api/dms/get/${mandant.SubjektId}/${docId}`,
            { responseType: "arraybuffer" }
          );
          if (response.status === 200) {
            setValidUser(true);
            let base64ImageString = Buffer.from(
              response.data,
              "binary"
            ).toString("base64");
            setPdf(base64ImageString);
          }
          if (response.status === 500) {
            setValidUser(false);
            let base64ImageString = Buffer.from(
              response.data,
              "binary"
            ).toString("base64");
            setPdf(base64ImageString);
          }
        } catch (error) {
          console.log(error, error.message);
        }
      };

      getPdf();
    }
  }, [mandant, docId]);

  const confirmNotificationSettings = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/user/notification/settings/mark/confirmed",
      {}
    );
    if (response.status === 200) {
      // setDisplayConfirmationMsg(false);
    }
  };

  useEffect(() => {
    const getUserNotificationSettings = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/user/notification/settings"
      );
      if (response.status === 200) {
        if (response.data.confirmed === false) {
          setDisplayConfirmationMsg(true);
          confirmNotificationSettings();
        }
      }
    };
    getUserNotificationSettings();
  }, []);

  const UpdateSuccessMessage = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <ObjectItem>
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
          className="title2"
          style={{ minWidth: "100%" }}
        >
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
            <Text as="h3" className="title2">
              Ihre Kommunikationseinstellungen wurden wie folgt gesetzt
            </Text>
            <Text as="p" className="t-standard">
              Neu werden Sie benachrichtigt, wenn neue Dokumente oder
              Mitteilungen im Steuerportal vorhanden sind.
            </Text>
            <ContentContainer className="vertical l-flex-rowgap-4 l-1of1">
              <ContentContainer className="flexItem l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem title4 l-fb-30">
                  Zustellung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  {kantonInfo.kanton === "nw"
                    ? "per Briefpost"
                    : "digital ins Steuerportal (keine Briefpost)"}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem t-standard title4 l-fb-30">
                  Benachrichtigung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  per E-Mail: {AuthService.getUsername()}
                </Text>
              </ContentContainer>
            </ContentContainer>
            <ContentContainer className="horizontal l-flex-justify-end l-1of1 l-m-t-8">
              <DefaultButton
                text="Einstellungen anpassen"
                iconProps={ICON_PROPS.SettingsIcon}
                onClick={() => navigate(`/settings`)}
              />
            </ContentContainer>
          </ContentContainer>
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const blob = base64ToBlob(pdf, "application/pdf");
  const url = URL.createObjectURL(blob);

  return (
    <MainContentContainer
      messages={displayConfirmationMsg && UpdateSuccessMessage}
    >
      <div>
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
          <Text as="h3" className="title3">
            Ihr neues Dokument
          </Text>
          {validUser ? (
            <Text as="p" className="t-standard">
              Sollte das Dokument nicht korrekt angezeigt werden, können Sie es
              über diesen{" "}
              <Link
                href={url}
                target="_blank"
                underline
                rel="noopener noreferrer"
              >
                Link herunterladen.
              </Link>{" "}
            </Text>
          ) : (
            <Text as="p" className="t-standard">
              Das Dokument kann nicht angezeigt werden, da Sie nicht berechtigt
              sind oder das Dokument nicht existiert.
            </Text>
          )}
        </ContentContainer>
        {pdf && validUser && (
          <div className="l-m-tb-16 pdfViewer">
            <iframe
              title={"dokument"}
              width="100%"
              height="100%"
              src={`${url}`}
            />
          </div>
        )}
      </div>
    </MainContentContainer>
  );
};
