import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { TaxDeclaration } from "./TaxDeclaration";
import { SteuerKonto } from "./SteuerKonto";
import { Documents } from "./Documents";

function MandantSelected(props) {
  const { mandant, setMandant } = props;
  const prevPage = sessionStorage.getItem("page");
  const PI1 = (
    <PivotItem headerText="Steuererklärungen" itemKey="Steuererklärungen">
      <TaxDeclaration mandant={mandant} setMandant={setMandant} />
    </PivotItem>
  );
  const PI2 = (
    <PivotItem headerText="Steuerkonto" itemKey="Steuerkonto">
      <SteuerKonto mandant={mandant} setMandant={setMandant} />
    </PivotItem>
  );
  const PI3 = (
    <PivotItem headerText="Dokumente" itemKey="Dokumente">
      <Documents mandant={mandant} setMandant={setMandant} />
    </PivotItem>
  );

  return (
    <Pivot
      ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
      className="pivotContainer"
      defaultSelectedKey={
        prevPage === "kontoDetails"
          ? "Steuerkonto"
          : prevPage === "documents"
          ? "Dokumente"
          : ""
      }
    >
      {PI1}
      {PI2}
      {PI3}
    </Pivot>
  );
}

export default MandantSelected;
