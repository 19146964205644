import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  Expander,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Text } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { MitMirGeteiltPanel } from "../Panel/MitMirGeteiltPanel";
import { NothingSharedMessage } from "./NothingSharedMessage";

function MitMirGeteilt() {
  const [acceptedInvitationsToMe, setAcceptedInvitationsToMe] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const navigate = useNavigate();
  const [
    isSharingOpen,
    { setTrue: openSharingPanel, setFalse: dismissSharingPanel },
  ] = useBoolean(false);

  const getAcceptedInvitationsToMeList = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/shares/given/all",
      {
        lang: "de",
      }
    );
    if (response.status === 200) {
      setAcceptedInvitationsToMe(response.data);
    }
  };

  useEffect(() => {
    getAcceptedInvitationsToMeList();
  }, []);

  const handleInvitationDelete = async (selectedItem) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/given/delete",
        {
          ownerId: selectedItem.owner.userId,
          subjectId: selectedItem.subjectId,
          subjectType: selectedItem.type,
        }
      );
      if (response.status === 200) {
        getAcceptedInvitationsToMeList();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const displayAcceptedInvitationsToMe = (invitations) => {
    return invitations.map((invitation) => (
      <Expander
        title={invitation.name}
        headerTitleTag="h3"
        headerTitleTagClass="title3"
        headerTitleSize="l-fb-40"
        className="formContent"
        headerContent={
          <>
            <ContentContainer
              size="l-fb-50 l-flex-nogrow"
              className="horizontal"
            >
              <Text as="span" className="flexItem t-listText hiddenLabel">
                PID:
              </Text>
              <Text as="span" className="flexItem t-listText t-right-s">
                {invitation.pid}
              </Text>
            </ContentContainer>
            <ContentContainer
              size="l-fb-50 l-flex-nogrow"
              className="horizontal"
            >
              <Text as="span" className="flexItem t-listText hiddenLabel">
                Anzahl geteilt:
              </Text>
              <Text as="span" className="flexItem t-listText t-right">
                1
              </Text>
            </ContentContainer>
          </>
        }
        headerContentSize="l-fb-60"
      >
        <ObjectList
          title="Geteilter Inhalt"
          headingTag="h4"
          headingTagClass="title4"
          listHeader={
            <ObjectListHeader hasActionButton={true}>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Mandant
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-10">
                PID
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Typ
              </Text>
              <Text as="span" className="flexItem t-listHeading l-fb-30">
                Benutzerrechte
              </Text>
            </ObjectListHeader>
          }
        >
          <ObjectItem
            onClick={() => navigate(`/mandanten/${invitation.pid}`)}
            ariaLabel={invitation.name}
            actionButton={
              <IconButton
                iconProps={null}
                ariaLabel={"Bearbeiten Menü"}
                menuProps={{
                  items: [
                    {
                      key: "info",
                      text: "Info",
                      iconProps: ICON_PROPS.InfoIcon,
                      onClick: () => {
                        openSharingPanel();
                        setSelectedItem(invitation);
                      },
                    },
                    {
                      key: "delete",
                      text: "Teilen beenden",
                      iconProps: ICON_PROPS.DeleteIcon,
                      onClick: () => handleInvitationDelete(invitation),
                    },
                  ],
                }}
                menuIconProps={ICON_PROPS.MenuDotsIcon}
                className={"moreIcon"}
              />
            }
          >
            <ContentContainer className="l-flex-rowgap-8">
              <ContentContainer size="l-fb-30" className="horizontal">
                <FontIcon
                  iconName={
                    invitation.subjectType === "JP"
                      ? ICON_PROPS.JPDelegatedIcon.iconName
                      : ICON_PROPS.NPDelegatedIcon.iconName
                  }
                  className="objectItemIcon-large"
                />
                <Text as="span" className="flexItem title3">
                  {invitation.name}
                </Text>
              </ContentContainer>
              <ContentContainer size="l-fb-10" className="horizontal">
                <Text as="span" className="flexItem t-listText hiddenLabel">
                  PID:
                </Text>
                <Text as="span" className="flexItem t-listText t-right-s">
                  {invitation.pid}
                </Text>
              </ContentContainer>
              <ContentContainer size="l-fb-30" className="horizontal">
                <Text
                  as="span"
                  className="flexItem t-listText hiddenLabel l-fb-100"
                >
                  Typ:
                </Text>
                <Text as="span" className="flexItem t-listText t-right-s">
                  {invitation.typeDescr} {invitation.year}
                </Text>
              </ContentContainer>
              <ContentContainer size="l-fb-30" className="horizontal">
                <Text
                  as="span"
                  className="flexItem t-listText hiddenLabel l-fb-100"
                >
                  Benutzerrechte:
                </Text>
                <Text as="span" className="flexItem t-listText t-right-s">
                  {invitation.permissions.accountsView === true &&
                    "Sieht Steuerkonto, Sieht Dokumente"}
                  {invitation.permissions.declRead === true &&
                    "Deklaration lesen, bearbeiten, einreichen"}
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      </Expander>
    ));
  };

  if (acceptedInvitationsToMe.length === 0) {
    return <NothingSharedMessage type={"mitmir"} />;
  }

  return (
    <MainContentContainer>
      <ObjectList
        title="Mit mir geteilte Inhalte von"
        headingTag="h2"
        headingTagClass="title2"
        listHeader={
          <ObjectListHeader>
            <ContentContainer className="flexItem l-fb-40">
              <Text as="span" className="t-listHeading l-m-l-32">
                Name
              </Text>
            </ContentContainer>
            <ContentContainer className="flextItem l-flex-align-end l-fb-60">
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                PID
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-20"
              >
                Anzahl geteilt
              </Text>
            </ContentContainer>
          </ObjectListHeader>
        }
      >
        {acceptedInvitationsToMe &&
          displayAcceptedInvitationsToMe(acceptedInvitationsToMe)}
      </ObjectList>
      <MitMirGeteiltPanel
        isOpen={isSharingOpen}
        onDismiss={dismissSharingPanel}
        selectedItem={selectedItem}
        setAcceptedInvitationsToMe={setAcceptedInvitationsToMe}
      />
    </MainContentContainer>
  );
}

export default MitMirGeteilt;
