import React from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Link, Text } from "@fluentui/react";
import { newETaxDeclaration } from "../../helpers/helpers";

const TaxDeclarationsHeader = (
  <ObjectListHeader hasActionButton={true}>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Typ
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Status
    </Text>
    {/*<Text as="span" className="flexItem t-listHeading t-right l-fb-20">*/}
    {/*  Einreichefrist*/}
    {/*</Text>*/}
    <Text as="span" className="flexItem t-listHeading t-right l-fb-20">
      Geteilt mit
    </Text>
  </ObjectListHeader>
);

export const NotDeclarationsMessage = (props) => {
  const { kantonInfo, mandant } = props;
  return (
    <MainContentContainer>
      <ObjectList
        title={"Steuererklärungen"}
        headingTag="h3"
        headingTagClass="title2"
        listHeader={TaxDeclarationsHeader}
        className="tableView bigTitle"
      >
        <ObjectItem
          className="infoWarning"
          ariaLabel="Information zur leeren Liste"
        >
          <ContentContainer className="horizontal">
            <FontIcon
              iconName={ICON_PROPS.InfoIcon.iconName}
              className="objectItemIcon"
            />
            {mandant.owned === false ? (
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Es sind keine Steuererklärungen vorhanden.
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Die Berechtigungen zum Anzeigen der Inhalte wurden nicht mit
                  Ihnen geteilt.
                </Text>
              </ContentContainer>
            ) : (
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Für {mandant.name} sind keine Steuererklärungen vorhanden:
                </Text>
                <Text as="span" className="flexItem t-standard">
                  Sie können eine Steuererklärung unter{" "}
                  <Link
                    href={newETaxDeclaration(
                      kantonInfo.etaxBaseUri,
                      kantonInfo.kanton
                    )}
                    underline
                  >
                    Neue Steuererklärung eröffnen
                  </Link>{" "}
                  hinzufügen.
                </Text>
              </ContentContainer>
            )}
          </ContentContainer>
        </ObjectItem>
      </ObjectList>
    </MainContentContainer>
  );
};
