import React, { useCallback, useContext, useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  ButtonList,
  ContentContainer,
  FieldSet,
  ICON_PROPS,
  MainContentContainer,
  RadioButton,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  Link,
  MaskedTextField,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  CompanyActivationDescription,
  CompanyActivationDescriptionOW,
  PrivateActivationDescription,
} from "./NewActivationDescriptions";
import { KantonContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { pickMessage } from "../../helpers/helpers";
import { Buffer } from "buffer";

function NewActivation() {
  const kantonInfo = useContext(KantonContext);
  const navigate = useNavigate();
  const [isPrivate, { toggle: changeType }] = useBoolean(true);
  const [NPfields, setNPFields] = useState({});
  const [JPfields, setJPFields] = useState({});
  const [displayAGB, setDisplayAGB] = useState(false);
  const [pdf, setPdf] = useState("");
  const [JpPdf, setJpPdf] = useState("");
  const [agbError, setAgbError] = useState(false);
  const [agbSuccess, setAgbSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const regexp = new RegExp("([0-9]{2}).([0-9]{2}).([0-9]{4})");
  const [shouldDisabled, setShouldDisabled] = useState(true);
  const [shouldDisabledJP, setShouldDisabledJP] = useState(true);

  useEffect(() => {
    if (
      NPfields.geburtsdatum &&
      NPfields.pid &&
      NPfields.name &&
      NPfields.vorname
    ) {
      if (isPrivate) {
        setShouldDisabled(false);
      } else {
        setShouldDisabled(true);
      }
    }
  }, [NPfields]);

  useEffect(() => {
    if (JPfields.pid && JPfields.name) {
      if (!isPrivate) {
        setShouldDisabledJP(false);
      } else {
        setShouldDisabledJP(true);
      }
    }
  }, [JPfields]);

  useEffect(() => {
    const getNPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdf",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };

    getNPPDF();
  }, []);

  useEffect(() => {
    const getJPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdfJP",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setJpPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };

    getJPPDF();
  }, []);

  const handleKeyUpPrivate = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("activatePrivate").click();
    }
  }, []);

  const handleKeyUpCompany = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("activateCompany").click();
    }
  }, []);

  useEffect(() => {
    if (isPrivate && !displayAGB) {
      window.addEventListener("keyup", handleKeyUpPrivate);
      return () => window.removeEventListener("keyup", handleKeyUpPrivate);
    }
  }, [handleKeyUpPrivate, isPrivate, displayAGB]);

  useEffect(() => {
    if (!isPrivate && !displayAGB) {
      window.addEventListener("keyup", handleKeyUpCompany);
      return () => window.removeEventListener("keyup", handleKeyUpCompany);
    }
  }, [handleKeyUpCompany, isPrivate, displayAGB]);

  const handleAcceptClick = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/agb/accept",
        {
          SubjektId: isPrivate
            ? parseInt(NPfields.pid)
            : parseInt(JPfields.pid),
        }
      );
      if (response.status === 200) {
        setAgbError(false);
        setDisplayAGB(false);
        setAgbSuccess(true);
      }
    } catch (error) {
      setAgbError(error.message);
      console.log(error, error.message);
    }
  };

  const handleDeclineClick = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/agb/reject",
        {
          SubjektId: isPrivate
            ? parseInt(NPfields.pid)
            : parseInt(JPfields.pid),
        }
      );
      if (response.status === 200) {
        setAgbError(false);
        setDisplayAGB(false);
        navigate(0);
      }
    } catch (error) {
      setAgbError(error.message);
      console.log(error, error.message);
    }
  };

  const handlePrivateClick = async () => {
    try {
      if (
        NPfields.geburtsdatum &&
        regexp.test(NPfields.geburtsdatum.slice(0, -1).trim()) &&
        NPfields.pid &&
        NPfields.name &&
        NPfields.vorname
      ) {
        const response = await HttpService.getAxiosClient().post(
          "/api/account/register/np",
          {
            GeburtsDatum: NPfields.geburtsdatum.slice(0, -1).trim(),
            SubjektId: parseInt(NPfields.pid),
            NachName: NPfields.name,
            VorName: NPfields.vorname,
          }
        );
        if (response.status === 200) {
          if (response.data.ErrorNumber) {
            setSubmitError(
              "Es ist ein technisches Problem aufgetreten - versuchen Sie es später nochmals."
            );
          }
          if (response.data.ErrorNumber === null) {
            setSubmitError(false);
            setDisplayAGB(true);
          }
        }
      } else {
        setSubmitError("Bitte überprüfen Sie Ihre Eingaben");
      }
    } catch (error) {
      if (
        error.response.status === 403 &&
        error.response.data.includes("client is registered")
      ) {
        setSubmitError(
          "Das Steuerportal wurde bereits aktiviert - Sie können dieses nur einmal aktivieren."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00026")
      ) {
        setSubmitError(
          `Das Anfordern des Aktivierungscodes ist nicht möglich. Es wurde noch keine Vollmacht beim Steueramt ${kantonInfo.kanton.toUpperCase()} abgegeben - bitte beachten Sie untenstehende Informationen.`
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00024")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00025")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00006")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00010")
      ) {
        setSubmitError(
          "Dieses Steuerkonto kann nicht aktiviert werden, wenden Sie sich an das Steueramt."
        );
      } else if (error.response.status === 429) {
        setSubmitError(
          "Es gab zu viele Versuche das Steuerkonto zu dieser PID zu aktivieren. Zur Sicherheit wurde die Aktivierung abgebrochen. \n" +
            "Kontrollieren Sie Ihre Angaben oder wenden Sie sich an die Steuerverwaltung und versuchen Sie es später."
        );
      } else {
        setSubmitError(
          "Es ist ein technisches Problem aufgetreten - versuchen Sie es später nochmals."
        );
        console.log(error, error.message);
      }
    }
  };

  const handleCompanyClick = async () => {
    try {
      if (JPfields.pid && JPfields.name) {
        const response = await HttpService.getAxiosClient().post(
          "/api/account/register/jp",
          {
            Name: JPfields.name,
            SubjektId: parseInt(JPfields.pid),
          }
        );
        if (response.status === 200) {
          if (response.data.ErrorNumber) {
            if (response.data.ErrorNumber === "PERS-00026") {
              setSubmitError(
                `Das Anfordern des Aktivierungscodes ist nicht möglich. Es wurde noch keine Vollmacht beim Steueramt ${kantonInfo.kanton.toUpperCase()} abgegeben - bitte beachten Sie untenstehende Informationen.`
              );
            } else {
              setSubmitError(
                "Es ist ein technisches Problem aufgetreten - versuchen Sie es später nochmals."
              );
            }
          }
          if (response.data.ErrorNumber === null) {
            setSubmitError(false);
            setDisplayAGB(true);
          }
        }
      } else {
        setSubmitError("Bitte überprüfen Sie Ihre Eingaben");
      }
    } catch (error) {
      if (
        error.response.status === 403 &&
        error.response.data.includes("client is registered")
      ) {
        setSubmitError(
          "Das Steuerportal wurde bereits aktiviert - Sie können dieses nur einmal aktivieren."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00026")
      ) {
        setSubmitError(
          `Das Anfordern des Aktivierungscodes ist nicht möglich. Es wurde noch keine Vollmacht beim Steueramt ${kantonInfo.kanton.toUpperCase()} abgegeben - bitte beachten Sie untenstehende Informationen.`
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00024")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00025")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00006")
      ) {
        setSubmitError(
          "Bitte überprüfen Sie Ihre Eingaben oder wenden Sie sich an das Steueramt."
        );
      } else if (
        error.response.status === 500 &&
        error.response.data.includes("PERS-00010")
      ) {
        setSubmitError(
          "Dieses Steuerkonto kann nicht aktiviert werden, wenden Sie sich an das Steueramt."
        );
      } else if (error.response.status === 429) {
        setSubmitError(
          "Es gab zu viele Versuche das Steuerkonto zu dieser PID zu aktivieren. Zur Sicherheit wurde die Aktivierung abgebrochen. \n" +
            "Kontrollieren Sie Ihre Angaben oder wenden Sie sich an die Steuerverwaltung und versuchen Sie es später."
        );
      } else {
        setSubmitError(
          "Es ist ein technisches Problem aufgetreten - versuchen Sie es später nochmals."
        );
        console.log(error, error.message);
      }
    }
  };

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const blob = base64ToBlob(isPrivate ? pdf : JpPdf, "application/pdf");
  const url = URL.createObjectURL(blob);

  const ActivationPrivat = () => (
    <FieldSet
      title="Hinweis"
      headingTag="h3"
      headingTagClass="title3"
      description={PrivateActivationDescription}
    >
      <TextField
        label="Vorname"
        className="l-w-330r"
        onChange={(event) => {
          setNPFields({
            ...NPfields,
            vorname: event.target.value,
          });
          setAgbSuccess(false);
        }}
      />
      <TextField
        label="Name"
        className="l-w-330r"
        onChange={(event) => {
          setNPFields({
            ...NPfields,
            name: event.target.value,
          });
          setAgbSuccess(false);
        }}
      />
      <MaskedTextField
        label="Geburtsdatum (tt.mm.jjjj)"
        mask="99.99.9999"
        className="l-w-330r"
        onChange={(event) => {
          setNPFields({
            ...NPfields,
            geburtsdatum: event.target.value,
          });
          setAgbSuccess(false);
        }}
      />
      <TextField
        label="PID"
        className="l-w-330r"
        onChange={(event) => {
          setNPFields({
            ...NPfields,
            pid: event.target.value,
          });
          setAgbSuccess(false);
        }}
      />
    </FieldSet>
  );

  const ActivationCompany = () => {
    return (
      <FieldSet
        title="Aktivierungcode für Unternehmung beantragen"
        headingTag="h3"
        headingTagClass="title3"
        description={
          kantonInfo.kanton === "ow"
            ? CompanyActivationDescriptionOW
            : CompanyActivationDescription
        }
      >
        <TextField
          label="Name"
          className="l-w-330r"
          defaultValue={""}
          onChange={(event) => {
            setJPFields({
              ...JPfields,
              name: event.target.value,
            });
            setAgbSuccess(false);
          }}
        />
        <TextField
          label="PID-Nr."
          className="l-w-330r"
          defaultValue={""}
          onChange={(event) => {
            setJPFields({
              ...JPfields,
              pid: event.target.value,
            });
            setAgbSuccess(false);
          }}
        />
      </FieldSet>
    );
  };

  return (
    <MainContentContainer
      messages={pickMessage(
        submitError,
        agbError,
        agbSuccess,
        isPrivate ? NPfields : JPfields,
        kantonInfo.kanton
      )}
    >
      {displayAGB ? (
        <div>
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0">
            <Text as="h3" className="title3">
              Allgemeine Geschäftsbedingungen akzeptieren
            </Text>
            <Text as="p" className="t-standard">
              Um das Steuerportal nutzen zu können müssen Sie die nachfolgenden
              Geschäftsbedingungen{" "}
              <Link
                href={url}
                target="_blank"
                underline
                rel="noopener noreferrer"
              >
                (AGB als PDF herunterladen)
              </Link>{" "}
              des Steuerportals annehmen.
            </Text>
          </ContentContainer>
          <div
            className="l-m-tb-16 pdfViewer"
            // style={{ height: "600px", border: "3px dotted grey" }}
          >
            {pdf && JpPdf && (
              <iframe width="100%" height="100%" src={`${url}`} />
            )}
          </div>
          <ButtonList>
            <PrimaryButton
              text="AGB annehmen"
              iconProps={ICON_PROPS.SuccessIcon}
              onClick={() => handleAcceptClick()}
            />
            <DefaultButton
              text="AGB ablehnen"
              iconProps={ICON_PROPS.ErrorIcon}
              onClick={() => handleDeclineClick()}
            />
          </ButtonList>
        </div>
      ) : (
        <div>
          <FieldSet title="Steuerportal aktivieren für:">
            <RadioButton
              active
              label=" "
              onChange={() => {
                changeType();
                setSubmitError(false);
                setAgbSuccess(false);
                setJPFields({});
                setNPFields({});
                setShouldDisabled(true);
                setShouldDisabledJP(true);
              }}
              options={[
                { key: 1, text: "Privatperson" },
                { key: 2, text: "Unternehmung" },
              ]}
              defaultSelectedKey={isPrivate ? 1 : 2}
            />
          </FieldSet>
          {isPrivate && ActivationPrivat()}
          {!isPrivate && ActivationCompany()}
          {isPrivate ? (
            <PrimaryButton
              text="Weiter"
              id={"activatePrivate"}
              disabled={shouldDisabled}
              iconProps={ICON_PROPS.LongArrowRightIcon}
              className="l-m-tb-16 l-w-330r"
              onClick={() => handlePrivateClick()}
            />
          ) : (
            <PrimaryButton
              text="Weiter"
              id={"activateCompany"}
              disabled={shouldDisabledJP}
              iconProps={ICON_PROPS.LongArrowRightIcon}
              className="l-m-tb-16 l-w-330r"
              onClick={() => handleCompanyClick()}
            />
          )}
        </div>
      )}
    </MainContentContainer>
  );
}

export default NewActivation;
