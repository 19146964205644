import React, { useContext } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import MainExtension from "../components/Extension/MainExtension";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { Footer } from "../components/Footer/Footer";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import { TAX_YEAR } from "../constants/constants";

function ExtensionPage() {
  const kantonInfo = useContext(KantonContext);
  sessionStorage.clear();
  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={`Frist Steuererklärung ${TAX_YEAR} verlängern`}
          displayButtons={false}
          breadcrumbs={[
            { text: "Fristerstreckung", key: "f1", isCurrentItem: true },
          ]}
          backButtonPath={-1}
          backButtonClear={true}
        />
      }
      main={<MainExtension />}
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default ExtensionPage;
