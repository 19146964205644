import React, { useContext } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { Footer } from "../components/Footer/Footer";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import { Pivot, PivotItem } from "@fluentui/react";
import { GlobalSettings } from "../components/Settings/GlobalSettings";
import { MandantSettings } from "../components/Settings/MandantSettings";

function SettingsPage() {
  sessionStorage.clear();
  const kantonInfo = useContext(KantonContext);

  const PI1 = (
    <PivotItem headerText="Globale Einstellungen" itemKey="Globale">
      <GlobalSettings />
    </PivotItem>
  );
  const PI2 = (
    <PivotItem headerText="Einstellung pro Mandant" itemKey="Mandant">
      <MandantSettings />
    </PivotItem>
  );

  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={`Kommunikationseinstellungen`}
          displayButtons={false}
          breadcrumbs={[
            {
              text: "Kommunikationseinstellungen",
              key: "f1",
              isCurrentItem: true,
            },
          ]}
          backButtonPath={`/`}
          backButtonClear={true}
        />
      }
      main={
        <Pivot
          ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
          className="pivotContainer"
        >
          {PI1}
          {PI2}
        </Pivot>
      }
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default SettingsPage;
