import React, { useContext, useEffect, useState } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { Footer } from "../components/Footer/Footer";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import { SingleDocument } from "../components/SingleDocument/SingleDocument";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import HttpService from "../services/HttpService";

function SingleDocumentPage() {
  sessionStorage.clear();
  sessionStorage.setItem("page", "documents");
  const kantonInfo = useContext(KantonContext);
  const [mandant, setMandant] = useState("");

  let pathParts = window.location.pathname.split("/");
  let subjectId = pathParts[2];
  let docId = pathParts[3];

  useEffect(() => {
    const getMandant = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          `/api/mandant/${subjectId}`
        );
        if (response.status === 200) {
          setMandant(response.data);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };
    getMandant();
  }, []);

  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={
            mandant && mandant.name && mandant.SubjektId
              ? `${mandant.name} (PID: ${mandant.SubjektId}) - Neues Dokument`
              : `Neues Dokument`
          }
          displayButtons={false}
          breadcrumbs={[{ text: "Dokument", key: "f1", isCurrentItem: true }]}
          backButtonPath={
            mandant && mandant.SubjektId
              ? `/mandanten/${mandant.SubjektId}`
              : `/`
          }
          backButtonClear={true}
        />
      }
      main={<SingleDocument mandant={mandant} docId={docId} />}
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default SingleDocumentPage;
