import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { PrivatePersonExtension } from "./PrivatepersonExtension";
import { CompanyExtension } from "./CompanyExtension";
import { useLocation } from "react-router-dom";

function MainExtension() {
  const location = useLocation();
  const DE1 = (
    <PivotItem headerText="für Privatpersonen" itemKey="Privatpersonen">
      <PrivatePersonExtension />
    </PivotItem>
  );
  const DE2 = (
    <PivotItem headerText="für Unternehmungen" itemKey="Unternehmungen">
      <CompanyExtension />
    </PivotItem>
  );

  return (
    <>
      {location?.state?.type ? (
        location?.state?.type === "JP" ? (
          <Pivot
            ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
            activeKey="Steuererklärungen"
            defaultSelectedKey={
              location?.state?.type === "JP"
                ? "Unternehmungen"
                : "Privatpersonen"
            }
            className="pivotContainer"
          >
            {DE2}
          </Pivot>
        ) : (
          <Pivot
            ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
            activeKey="Steuererklärungen"
            defaultSelectedKey={
              location?.state?.type === "JP"
                ? "Unternehmungen"
                : "Privatpersonen"
            }
            className="pivotContainer"
          >
            {DE1}
          </Pivot>
        )
      ) : (
        <Pivot
          ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
          activeKey="Steuererklärungen"
          defaultSelectedKey={
            location?.state?.type === "JP" ? "Unternehmungen" : "Privatpersonen"
          }
          className="pivotContainer"
        >
          {DE1}
          {DE2}
        </Pivot>
      )}
    </>
  );
}

export default MainExtension;
