import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import VonMirGeteilt from "./VonMirGeteilt";
import MitMirGeteilt from "./MitMirGeteilt";
import EinladungenGeteilt from "./EinladungenGeteilt";

function MainSharingManagement() {
  const DE1 = (
    <PivotItem headerText="Von mir geteilt" itemKey="Von mir geteilt">
      <VonMirGeteilt />
    </PivotItem>
  );
  const DE2 = (
    <PivotItem headerText="Mit mir geteilt" itemKey="Mit mir geteilt">
      <MitMirGeteilt />
    </PivotItem>
  );
  const DE3 = (
    <PivotItem headerText="Offene Einladungen" itemKey="Offene Einladungen">
      <EinladungenGeteilt />
    </PivotItem>
  );

  return (
    <Pivot
      ariaLabel="Pivotnavigation, benutzen Sie die links/rechts Pfeiltasten"
      activeKey="Von mir geteilt"
      className="pivotContainer"
    >
      {DE1}
      {DE2}
      {DE3}
    </Pivot>
  );
}

export default MainSharingManagement;
