import React, { useContext } from "react";
import {
  ContentContainer,
  FooterContainer,
} from "@riag-libs/etax-pattern-library";
import { Text } from "@fluentui/react";
import { KantonContext } from "../../contexts";
import { UI_VERSION } from "../../constants/constants";

export const Footer = () => {
  const kantonInfo = useContext(KantonContext);
  return (
    <FooterContainer className="footerCopy">
      <ContentContainer>
        <Text as="span" className="t-xsmall">
          Steuerportal Kanton{" "}
          {kantonInfo.kanton === "ow" ? "Obwalden" : "Nidwalden"} - UI Version{" "}
          {UI_VERSION} - Server Version {kantonInfo.serverVersion} © Ringler
          Informatik AG
        </Text>
      </ContentContainer>
    </FooterContainer>
  );
};
