import React from "react";
import {
  ContentContainer,
  HeaderTitle,
  ICON_PROPS,
  ObjectItem,
  ObjectList,
  Panel,
} from "@riag-libs/etax-pattern-library";
import { DefaultButton, PanelType, Text } from "@fluentui/react";
import HttpService from "../../services/HttpService";

export const MitMirGeteiltPanel = (props) => {
  const { selectedItem, isOpen, onDismiss, setAcceptedInvitationsToMe } = props;

  const getAcceptedInvitationsToMeList = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/shares/given/all",
      {
        lang: "de",
      }
    );
    if (response.status === 200) {
      setAcceptedInvitationsToMe(response.data);
    }
  };

  const handleInvitationDelete = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/given/delete",
        {
          ownerId: selectedItem.owner.userId,
          subjectId: selectedItem.subjectId,
          subjectType: selectedItem.type,
        }
      );
      if (response.status === 200) {
        getAcceptedInvitationsToMeList();
        onDismiss();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <DefaultButton
        onClick={() => {
          onDismiss();
        }}
      >
        Schliessen
      </DefaultButton>
    ),
    [onDismiss]
  );

  if (Object.keys(selectedItem).length === 0) {
    return <div></div>;
  }

  return (
    <Panel
      headerText={
        <HeaderTitle
          title={`${selectedItem.name} (PID: ${selectedItem.pid})`}
          subTitle="Mit mir geteilt"
          logo={ICON_PROPS.ShareIcon}
          className="l-m-b-16"
        />
      }
      className={"owTheme"}
      panelType={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      <ContentContainer className="vertical">
        <ObjectList
          title={`Geteilt mit ${selectedItem.trustee.fname} ${selectedItem.trustee.lname}`}
          headingTag="h2"
          headingTagClass="title2"
        >
          <ObjectItem className="whiteContent">
            <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
              <ContentContainer className="l-flex-rowgap-4">
                <Text
                  as="span"
                  className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                >
                  E-Mail:
                </Text>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  {selectedItem.trustee.email}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4">
                <Text
                  as="span"
                  className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                >
                  Mobile-Nummer:
                </Text>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50"
                >
                  {selectedItem.trustee.mobile}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4">
                <Text
                  as="span"
                  className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                >
                  Benutzerrechte:
                </Text>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                >
                  {selectedItem.permissions.accountsView === true &&
                    "Sieht Steuerkonto, Sieht Dokumente"}
                  {selectedItem.permissions.declRead === true &&
                    " Deklaration lesen, bearbeiten, übermitteln"}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4">
                <Text
                  as="span"
                  className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                >
                  Geteil von:
                </Text>
                <Text
                  as="span"
                  className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                >
                  {selectedItem.owner.email}
                </Text>
              </ContentContainer>
              <ContentContainer>
                <DefaultButton
                  text="Teilen beenden"
                  iconProps={ICON_PROPS.DeleteIcon}
                  onClick={() => handleInvitationDelete()}
                />
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      </ContentContainer>
    </Panel>
  );
};
