import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  Expander,
  FieldSet,
  HeaderTitle,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  Panel,
} from "@riag-libs/etax-pattern-library";
import {
  Checkbox,
  DefaultButton,
  Link,
  PanelType,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import { pickSharingMessage, validateEmail } from "../../helpers/helpers";
import { Buffer } from "buffer";
import AuthService from "../../services/AuthService";

export const SharingPanelDeclarationView = (props) => {
  const {
    mandant,
    isOpen,
    onDismiss,
    declaration,
    postAllDeclarations,
    getMandant,
  } = props;
  const [fields, setFields] = useState({ documents: false });
  const [invitations, setInvitations] = useState({});
  const [acceptedInvitations, setAcceptedInvitations] = useState({});
  const [selectedShare, setSelectedShare] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [shouldDisabled, setShouldDisabled] = useState(true);

  const [pdf, setPdf] = useState("");
  const [JpPdf, setJpPdf] = useState("");

  const userName = AuthService.getUsername();

  useEffect(() => {
    if (fields.vorname && fields.name && fields.email && fields.mobile) {
      if (fields.email === userName) {
        setSubmitError("username");
        setShouldDisabled(true);
      } else {
        if (
          acceptedInvitations.some(
            (e) => e.invitation.email === fields.email
          ) ||
          invitations.some((e) => e.email === fields.email)
        ) {
          setShouldDisabled(true);
          setSubmitError("shared");
        } else {
          setShouldDisabled(false);
          setSubmitError(null);
        }
      }
    } else {
      setShouldDisabled(true);
    }
  }, [fields, acceptedInvitations, invitations]);

  useEffect(() => {
    const getNPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdf",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };

    if (mandant.type === "NP") {
      getNPPDF();
    }
  }, []);

  useEffect(() => {
    const getJPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdfJP",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setJpPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };
    if (mandant.type === "JP") {
      getJPPDF();
    }
  }, []);

  const getPendingInvitationsList = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/delegation/invitation/search",
      {
        lang: "de_CH",
        subjectId: declaration.id,
        subjectType: "declaration",
      }
    );
    if (response.status === 200) {
      setInvitations(response.data);
    }
  };

  const getAcceptedInvitationsList = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/shares/my/search",
      {
        lang: "de_CH",
        subjectId: declaration.id,
      }
    );
    if (response.status === 200) {
      setAcceptedInvitations(response.data);
    }
  };

  const getSelectedShareDecl = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/given/search",
        {
          lang: "de_CH",
          subjectId: declaration.id,
        }
      );
      if (response.status === 200) {
        setSelectedShare(response.data);
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      getPendingInvitationsList();
      getSelectedShareDecl();
      getAcceptedInvitationsList();
    }
  }, [isOpen]);

  const handleAcceptedInvitationDelete = async (inv) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/my/delete",
        {
          subjectId: inv.subjectId,
          subjectType: inv.type,
          trusteeId: inv.trustee.userId,
        }
      );
      if (response.status === 200) {
        getAcceptedInvitationsList();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const handleAcceptedGivenInvitationDelete = async (share) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/shares/given/delete",
        {
          ownerId: share.owner.userId,
          subjectId: share.subjectId,
          subjectType: share.type,
        }
      );
      if (response.status === 200) {
        postAllDeclarations();
        getMandant();
        getSelectedShareDecl();
        onDismiss();
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const handleInvitationDelete = async (id) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/delegation/invitation/decline",
        {
          lang: "de",
          requestId: id,
        }
      );
      if (response.status === 200) {
        getPendingInvitationsList(declaration);
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const handleClick = async () => {
    setSubmitSuccess(false);
    setSubmitError(false);
    if (
      fields.vorname &&
      fields.name &&
      fields.email &&
      fields.mobile &&
      fields.email !== userName &&
      validateEmail(fields.email) &&
      fields.mobile.length > 9
    ) {
      try {
        const response = await HttpService.getAxiosClient().post(
          "/api/delegation/invitation/new",
          {
            email: fields.email,
            firstName: fields.vorname,
            lastName: fields.name,
            mobile: fields.mobile,
            subjects: [
              {
                permissions: {
                  accountsView: !!fields.steuerkonto,
                  declDelete: false,
                  declGrant: false,
                  declRead: true,
                  declSubmit: true,
                  declWrite: true,
                  documentsView: !!fields.documents,
                },
                subjectId: declaration.id,
                subjectType: "declaration",
              },
              {
                permissions: {
                  accountsView: !!fields.steuerkonto,
                  declDelete: false,
                  declGrant: false,
                  declRead: !!fields.decl,
                  declSubmit: !!fields.decl,
                  declWrite: !!fields.decl,
                  documentsView: !!fields.documents,
                },
                subjectId: mandant.SubjektId.toString(),
                subjectType: "client",
              },
            ],
          }
        );
        if (response.status === 200) {
          getPendingInvitationsList();
          setFields({
            vorname: "",
            name: "",
            email: "",
            mobile: "",
            documents: false,
            steuerkonto: false,
          });
          setSubmitSuccess(true);
        }
      } catch (error) {
        setSubmitError(true);
        console.log(error, error.message);
      }
    } else {
      if (!validateEmail(fields.email) && fields.mobile.length < 10) {
        setSubmitError("emailmobile");
      } else if (!validateEmail(fields.email)) {
        setSubmitError("email");
      } else if (fields.mobile.length < 10) {
        setSubmitError("mobile");
      } else {
        if (fields.email === userName) {
          setSubmitError("username");
        } else {
          setSubmitError("fields");
        }
      }
    }
  };

  const displayAcceptedInvitations = (invitations) => {
    if (Object.keys(invitations).length !== 0) {
      return invitations.map((invitation) => (
        <Expander
          title={`${invitation.invitation.fname} ${invitation.invitation.lname}`}
          headerTitleTag="h3"
          headerTitleTagClass="title3"
          className="formContent"
        >
          <ContentContainer className="vertical l-flex-rowgap-8">
            <ObjectItem className="whiteContent">
              <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    E-Mail:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {invitation.invitation.email}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Mobile-Nummer:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {invitation.invitation.mobile}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Benutzerrechte:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                  >
                    {invitation.permissions.accountsView === true &&
                      "Sieht Steuerkonto, Sieht Dokumente"}
                    {invitation.permissions.declRead === true &&
                      " Deklaration lesen, bearbeiten, übermitteln"}
                  </Text>
                </ContentContainer>
              </ContentContainer>
            </ObjectItem>
            <ContentContainer>
              <DefaultButton
                text="Teilen beenden"
                iconProps={ICON_PROPS.DeleteIcon}
                onClick={() => handleAcceptedInvitationDelete(invitation)}
              />
            </ContentContainer>
          </ContentContainer>
        </Expander>
      ));
    }
  };

  const displayInvitations = (invitations) => {
    if (Object.keys(invitations).length !== 0) {
      return invitations.map((invitation) => (
        <Expander
          title={`${invitation.firstName} ${invitation.lastName}`}
          headerTitleTag="h3"
          headerTitleTagClass="title3"
          className="formContent"
        >
          <ContentContainer className="vertical l-flex-rowgap-8">
            <ObjectItem className="whiteContent">
              <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    E-Mail:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {invitation.email}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Mobile-Nummer:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50"
                  >
                    {invitation.mobile}
                  </Text>
                </ContentContainer>
                <ContentContainer className="l-flex-rowgap-4">
                  <Text
                    as="span"
                    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                  >
                    Benutzerrechte:
                  </Text>
                  <Text
                    as="span"
                    className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                  >
                    {invitation.subjects.find(
                      (sub) => sub.permissions.accountsView === true
                    ) && "Sieht Steuerkonto "}
                    {invitation.subjects.find(
                      (sub) => sub.permissions.documentsView === true
                    ) && "Sieht Dokumente "}
                    {invitation.subjects.find(
                      (sub) => sub.permissions.declRead === true
                    ) && "Sieht Deklaration lesen, bearbeiten, übermitteln"}
                  </Text>
                </ContentContainer>
              </ContentContainer>
            </ObjectItem>
            <ContentContainer>
              <DefaultButton
                text="Einladung löschen"
                iconProps={ICON_PROPS.DeleteIcon}
                onClick={() => handleInvitationDelete(invitation.id)}
              />
            </ContentContainer>
          </ContentContainer>
        </Expander>
      ));
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <DefaultButton
        onClick={() => {
          setSubmitSuccess(false);
          if (declaration?.permissions?.owner === false) {
            postAllDeclarations();
            getMandant();
          }
          onDismiss();
        }}
      >
        Schliessen
      </DefaultButton>
    ),
    [props.dismissPanel]
  );

  const generateTitle = () => {
    if (declaration.fullNameP2) {
      return `${declaration.modelDescr} ${
        declaration.taxYear && declaration.taxYear.toString().substring(2)
      } von ${declaration.fullName} & ${declaration.fullNameP2} (PID: ${
        mandant.SubjektId
      })`;
    }
    return `${declaration.modelDescr} ${
      declaration.taxYear && declaration.taxYear.toString().substring(2)
    } von ${declaration.fullName} (PID: ${mandant.SubjektId})`;
  };

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const blob = base64ToBlob(
    mandant.type === "NP" ? pdf : JpPdf,
    "application/pdf"
  );
  const url = URL.createObjectURL(blob);

  return (
    <Panel
      headerText={
        <HeaderTitle
          title="Teilen"
          subTitle={mandant.owned ? generateTitle() : "Mit mir geteilt"}
          logo={ICON_PROPS.ShareIcon}
          className="l-m-b-16"
        />
      }
      isOpen={isOpen}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      dismissPanel={() => {
        setSubmitSuccess(false);
        if (declaration.permissions.owner === false) {
          postAllDeclarations();
          getMandant();
        }
        onDismiss();
      }}
      panelType={PanelType.medium}
      className="owTheme pivotPanel"
    >
      <MainContentContainer
        messages={pickSharingMessage(submitSuccess, submitError)}
      >
        {!mandant.owned || declaration?.permissions?.owner === false ? (
          selectedShare.length > 0 &&
          selectedShare.map((share) => (
            <ObjectList
              title={`Geteilt mit ${share.trustee.fname} ${share.trustee.lname}`}
              headingTag="h2"
              headingTagClass="title2"
              buttonList={
                <DefaultButton
                  text="Teilen beenden"
                  iconProps={ICON_PROPS.DeleteIcon}
                  onClick={() => handleAcceptedGivenInvitationDelete(share)}
                />
              }
            >
              <ObjectItem className="whiteContent">
                <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
                  <ContentContainer className="l-flex-rowgap-4">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      E-Mail:
                    </Text>
                    <Text
                      as="span"
                      className="flexItem t-standard t-overflow-wrap l-fb-50"
                    >
                      {share.trustee.email}
                    </Text>
                  </ContentContainer>
                  {/*<ContentContainer className="l-flex-rowgap-4">*/}
                  {/*  <Text*/}
                  {/*    as="span"*/}
                  {/*    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"*/}
                  {/*  >*/}
                  {/*    Mobile-Nummer:*/}
                  {/*  </Text>*/}
                  {/*  <Text*/}
                  {/*    as="span"*/}
                  {/*    className="flexItem t-standard t-overflow-wrap l-fb-50"*/}
                  {/*  >*/}
                  {/*    {share.trustee.mobile}*/}
                  {/*  </Text>*/}
                  {/*</ContentContainer>*/}
                  <ContentContainer className="l-flex-rowgap-4">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      Benutzerrechte:
                    </Text>
                    <Text
                      as="span"
                      className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                    >
                      {share.permissions.declRead &&
                        "Deklaration lesen, bearbeiten, übermitteln"}
                      {share.permissions.accountsView &&
                        "Sieht Steuerkonto und Dokumente"}
                    </Text>
                  </ContentContainer>
                  <ContentContainer className="l-flex-rowgap-4">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      Geteil von:
                    </Text>
                    <Text
                      as="span"
                      className="flexItem t-standard t-overflow-wrap l-fb-50 l-flex-nogrow"
                    >
                      {share.owner.email}
                    </Text>
                  </ContentContainer>
                </ContentContainer>
              </ObjectItem>
            </ObjectList>
          ))
        ) : (
          <ContentContainer className="vertical">
            <FieldSet
              title="Teilen mit:"
              headingTag="h2"
              headingTagClass="title2 l-m-tb-16"
            >
              <FieldSet headingTag="h3" headingTagClass="title3">
                <ContentContainer>
                  <TextField
                    value={fields.vorname}
                    label="Vorname"
                    className="l-fb-50"
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        vorname: event.target.value,
                      });
                    }}
                  />
                  <TextField
                    label="Name"
                    value={fields.name}
                    className="l-fb-50"
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        name: event.target.value,
                      });
                    }}
                  />
                </ContentContainer>
                <ContentContainer>
                  <TextField
                    label="E-Mail"
                    value={fields.email}
                    className="l-fb-50"
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        email: event.target.value,
                      });
                    }}
                  />
                  <TextField
                    label="Mobile-Nummer"
                    value={fields.mobile}
                    className="l-fb-50"
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        mobile: event.target.value,
                      });
                    }}
                  />
                </ContentContainer>
              </FieldSet>
              <FieldSet headingTag="h3" headingTagClass="title3 l-p-b-5">
                <Text style={{ marginTop: "16px" }}>
                  Der Empfänger dieser Einladung darf diese Steuererklärung
                  lesen, bearbeiten und übermitteln.
                </Text>
                {mandant.activated && (
                  <Checkbox
                    checked={fields.documents}
                    label="zusätzlich das Steuerkonto und die Dokumente teilen."
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        documents: event.target.checked,
                        steuerkonto: event.target.checked,
                      });
                    }}
                  />
                )}
              </FieldSet>
              <ContentContainer className="l-m-t-8">
                <Text as="p" className="t-standard">
                  Mit dem Senden der Einladung akzeptieren Sie die{" "}
                  <Link href={url} target={"_blank"} underline>
                    Allgemeinen Geschäftsbedingungen des Steuerportals
                  </Link>
                  .
                </Text>
              </ContentContainer>
              <ContentContainer className="l-m-t-8">
                <PrimaryButton
                  text="Einladung senden"
                  disabled={shouldDisabled}
                  iconProps={ICON_PROPS.SendIcon}
                  onClick={() => handleClick()}
                />
              </ContentContainer>
            </FieldSet>
            {invitations && invitations.length > 0 && (
              <ObjectList
                title="Offene Einladungen"
                headingTag="h2"
                headingTagClass="title2"
              >
                {displayInvitations(invitations)}
              </ObjectList>
            )}
            {acceptedInvitations && acceptedInvitations.length > 0 && (
              <ObjectList
                title="Diese Steuererklärung wird aktuell geteilt mit:"
                headingTag="h2"
                headingTagClass="title2"
              >
                {displayAcceptedInvitations(acceptedInvitations)}
              </ObjectList>
            )}
          </ContentContainer>
        )}
      </MainContentContainer>
    </Panel>
  );
};
