import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { Shimmer, ShimmerElementType, Text } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import HttpService from "../../services/HttpService";
import { Buffer } from "buffer";

const TaxAccountDetailHeader = (
  <ObjectListHeader>
    <Text as="span" className="flexItem t-listHeading l-fb-15">
      Datum
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-25">
      Beschreibung
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-15">
      Valuta/Verfall
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Belastung
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Gutschrift
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Saldo
    </Text>
  </ObjectListHeader>
);

export const SteuerKontoDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state.data.BackEndData;
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);
  const [picture, setPicture] = useState("");
  const [pictureLoading, setPictureLoading] = useState(false);

  useEffect(() => {
    const getPaymentCode = async () => {
      try {
        setPictureLoading(true);
        const response = await HttpService.getAxiosClient().post(
          "/api/account/paymentslipPng",
          {
            KontoNr: location.state.kontoItems.KontoNr,
            Sachgebiet: location.state.kontoItems.Sachgebiet,
            SubjektId: location.state.mandantInfo.SubjektId,
            SubjektIdKonto: location.state.kontoItems.SubjektIdKonto,
          },
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setPictureLoading(false);
          setPicture(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };
    getPaymentCode();
  }, []);

  return (
    <MainContentContainer>
      <ObjectList
        title={`${data.SachgebietBezeichnung}: ${data.SteuerartBezeichnung} ${data.Periode} (${data.VerfahrenBezeichnung}) ${data.GemeindeBezeichnung}`}
        headingTag="h3"
        headingTagClass="title2"
        listHeader={TaxAccountDetailHeader}
        className="tableView bigTitle"
      >
        {data.BuchungItems.map((acc) => (
          <ObjectItem>
            <ContentContainer className="l-flex-rowgap-8">
              <ContentContainer
                size="l-fb-15"
                className="horizontal t-ellipsis"
              >
                <Text as="span" className="flexItem t-listText hiddenLabel">
                  Datum:
                </Text>
                <Text as="span" className="flexItem t-listText t-right-s">
                  {acc.Buchdatum}
                </Text>
              </ContentContainer>
              <ContentContainer
                size="l-fb-25"
                className="horizontal t-ellipsis l-flex-orderSwitch-1-s"
              >
                <Text as="span" className="flexItem t-listText visuallyHidden">
                  Beschreibung:
                </Text>
                <Text as="span" className="flexItem t-listText title4-s">
                  {acc.Text}
                </Text>
              </ContentContainer>
              <ContentContainer
                size="l-fb-15"
                className="horizontal t-ellipsis"
              >
                <Text as="span" className="flexItem t-listText hiddenLabel">
                  Valuta/Verfall:
                </Text>
                <Text as="span" className="flexItem t-listText t-right-s">
                  {acc.Valutadatum}
                </Text>
              </ContentContainer>
              {acc.Soll ? (
                <ContentContainer
                  size="l-fb-15"
                  className="horizontal t-ellipsis"
                >
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Belastung:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right">
                    {acc.Soll}
                  </Text>
                </ContentContainer>
              ) : (
                <ContentContainer size="l-fb-15" className="horizontal" />
              )}
              {acc.Haben ? (
                <ContentContainer
                  size="l-fb-15"
                  className="horizontal t-ellipsis"
                >
                  <Text as="span" className="flexItem t-listText hiddenLabel">
                    Gutschrift:
                  </Text>
                  <Text as="span" className="flexItem t-listText t-right">
                    {acc.Haben}
                  </Text>
                </ContentContainer>
              ) : (
                <ContentContainer size="l-fb-15" className="horizontal" />
              )}
              <ContentContainer
                size="l-fb-15"
                className="horizontal t-ellipsis"
              >
                <Text as="span" className="flexItem t-listText hiddenLabel">
                  Saldo:
                </Text>
                <Text as="span" className="flexItem t-listText t-right">
                  {acc.TeilSaldo}
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        ))}
      </ObjectList>
      <ObjectItem className="totalRow" style={{ "margin-bottom": "32px" }}>
        <ContentContainer className="horizontal">
          <Text as="span" className="flexItem title4">
            {data.ZuGunstenText}
          </Text>
          <Text as="span" className="flexItem title4 t-right">
            {data.KontoSaldo}
          </Text>
        </ContentContainer>
      </ObjectItem>
      <ObjectList
        title="Zahlungsinformation"
        headingTag="h3"
        headingTagClass="title3"
        className="tableView"
      >
        <ObjectItem>
          <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
            {pictureLoading ? (
              <div>
                <Shimmer
                  shimmerElements={[
                    {
                      type: ShimmerElementType.line,
                      height: 120,
                      width: "100%",
                    },
                  ]}
                />
              </div>
            ) : (
              <img
                alt={"qr-code"}
                style={{ width: "100%" }}
                className="flexItem l-fb-50 paymentInfo"
                src={`data:image/png;base64,${picture}`}
              />
            )}
          </ContentContainer>
        </ObjectItem>
      </ObjectList>
    </MainContentContainer>
  );
};
