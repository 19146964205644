import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ContentContainer,
  FieldSet,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { DeliveryWarning } from "../../helpers/helpers";
import { KantonContext } from "../../contexts";

const PrivateActivationCodeDescription = (props) => {
  return (
    <ContentContainer
      style={{ marginTop: "8px", marginBottom: "8px" }}
      className="l-flex-column l-flex-align-start l-flex-rowgap-0"
    >
      <Text as="p" className="t-standard">
        Sie haben den Aktivierungscode für {props.name} die (PID-Nr. {props.pid}
        ) am {moment(props.date).format("DD.MM.YYYY")} angefordert. Er wird
        Ihnen per Post zugeschickt.
      </Text>
      <Text as="p" className="t-standard">
        Bitte geben Sie den Aktivierungscode hier ein, um die Aktivierung des
        Steuerportals abzuschliessen.
      </Text>
    </ContentContainer>
  );
};

export const NotActiveSteuerKonto = (props) => {
  const kantonInfo = useContext(KantonContext);
  const navigate = useNavigate();
  const { mandantInfo, setMandant, documentsView } = props;
  const [fields, setFields] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [localSettings, setLocalSettings] = useState({});

  useEffect(() => {
    const getUserNotificationSettings = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/user/notification/settings"
      );
      if (response.status === 200) {
        setLocalSettings(response.data);
      }
    };
    getUserNotificationSettings();
  }, []);

  const getMandant = async () => {
    const response = await HttpService.getAxiosClient().get(
      `/api/mandant/${mandantInfo.SubjektId}`
    );
    if (response.status === 200) {
      setMandant(response.data);
    }
  };

  const handleKeyUp = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("mandantPageActivateBtn").click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  const handleClick = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/account/activate",
        {
          SubjektId: mandantInfo.SubjektId,
          AktivierungsCode: fields.AktivierungsCode,
        }
      );
      if (response.status === 200) {
        setSuccessMessage(true);
      }
    } catch (error) {
      setErrorMessage(true);
      console.log(error, error.message);
    }
  };

  const ActivationErrorMessage = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <ObjectItem>
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          Der eingegebene Aktivierungscode ist ungültig - bitte validieren Sie
          Ihre Eingabe.
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const ActivationSuccessMessage = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <ObjectItem>
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={true}
          className="title2"
          style={{ minWidth: "100%" }}
        >
          <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
            <Text as="h3" className="title2">
              Ihre Kommunikationseinstellungen wurden gesetzt
            </Text>
            <Text as="p" className="t-standard">
              Neu werden Sie benachrichtigt, wenn neue Dokumente oder
              Mitteilungen im Steuerportal vorhanden sind.
            </Text>
            <ContentContainer className="vertical l-flex-rowgap-4 l-1of1">
              <ContentContainer className="flexItem l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem title4 l-fb-30">
                  Zustellung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  {kantonInfo.kanton === "nw"
                    ? "per Briefpost"
                    : localSettings?.deliveryWay === "electronic"
                    ? "digital ins Steuerportal (keine Briefpost)"
                    : "per Briefpost"}
                </Text>
              </ContentContainer>
              <ContentContainer className="l-flex-rowgap-4 l-flex-align-start l-fb-100">
                <Text as="span" className="flexItem t-standard title4 l-fb-30">
                  Benachrichtigung:
                </Text>
                <Text as="span" className="flexItem t-standard l-fb-70">
                  per E-Mail: {mandantInfo.notificationSettings.email}
                </Text>
              </ContentContainer>
            </ContentContainer>
            <ContentContainer className="horizontal l-flex-justify-end l-1of1 l-m-t-8">
              <DefaultButton
                text="Einstellungen anpassen"
                iconProps={ICON_PROPS.SettingsIcon}
                onClick={() => navigate(`/settings`)}
              />
              <DefaultButton
                text={
                  documentsView ? "Dokumente anzeigen" : "Steuerkonto anzeigen"
                }
                iconProps={ICON_PROPS.ReloadIcon}
                onClick={() => getMandant()}
              />
            </ContentContainer>
          </ContentContainer>
        </MessageBar>
      </ObjectItem>
    </ObjectList>
  );

  const ActivationSuccessMessageWithWarning = (
    <ObjectList title="Meldungen" headingTag="h2" titleVisuallyHidden={true}>
      <MessageBar
        messageBarType={MessageBarType.success}
        isMultiline={true}
        className="title2"
      >
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
          <Text as="h3" className="title2">
            Ihre Kommunikationseinstellungen wurden gesetzt
          </Text>
          <Text as="p" className="t-standard">
            Neu werden Sie benachrichtigt, wenn neue Dokumente oder Mitteilungen
            im Steuerportal vorhanden sind.
          </Text>
          <ContentContainer className="vertical l-flex-rowgap-4 l-1of1">
            <ContentContainer className="flexItem l-flex-rowgap-4 l-flex-align-start l-fb-100">
              <Text as="span" className="flexItem title4 l-fb-30">
                Zustellung:
              </Text>
              <Text as="span" className="flexItem t-standard l-fb-70">
                {kantonInfo.kanton === "nw"
                  ? "per Briefpost"
                  : localSettings?.deliveryWay === "electronic"
                  ? "digital ins Steuerportal (keine Briefpost)"
                  : "per Briefpost"}
              </Text>
            </ContentContainer>
            <ContentContainer className="l-flex-rowgap-4 l-flex-align-start l-fb-100">
              <Text as="span" className="flexItem t-standard title4 l-fb-30">
                Benachrichtigung:
              </Text>
              <Text as="span" className="flexItem t-standard l-fb-70">
                per E-Mail: {mandantInfo.notificationSettings.email}
              </Text>
            </ContentContainer>
          </ContentContainer>
          <ContentContainer className="horizontal l-flex-justify-end l-1of1 l-m-t-8">
            <DefaultButton
              text="Einstellungen anpassen"
              iconProps={ICON_PROPS.SettingsIcon}
              onClick={() => navigate(`/settings`)}
            />
            <DefaultButton
              text={
                documentsView ? "Dokumente anzeigen" : "Steuerkonto anzeigen"
              }
              iconProps={ICON_PROPS.ReloadIcon}
              onClick={() => getMandant()}
            />
          </ContentContainer>
        </ContentContainer>
      </MessageBar>
      {kantonInfo.kanton === "ow" && DeliveryWarning}
    </ObjectList>
  );

  const displayMessage = () => {
    if (
      mandantInfo.owned === true &&
      mandantInfo.notificationSettings.deliveryWayPartner &&
      mandantInfo.notificationSettings.deliveryWayPartner === "electronic" &&
      mandantInfo.notificationSettings.deliveryWay === "paper"
    ) {
      return ActivationSuccessMessageWithWarning;
    } else if (successMessage) {
      return ActivationSuccessMessage;
    } else if (errorMessage) {
      return ActivationErrorMessage;
    }
  };

  return (
    <MainContentContainer messages={displayMessage()}>
      <FieldSet
        title="Aktivierungscode eingeben"
        headingTag="h3"
        headingTagClass="title3"
        description={
          <PrivateActivationCodeDescription
            date={mandantInfo.registrationDate}
            pid={mandantInfo.SubjektId}
            name={mandantInfo.name}
          />
        }
      >
        <TextField
          label="Aktivierungscode"
          className="l-w-330r"
          onChange={(event) => {
            setFields({
              ...fields,
              AktivierungsCode: event.target.value,
            });
          }}
        />
      </FieldSet>
      <PrimaryButton
        text="Steuerportal aktivieren"
        iconProps={ICON_PROPS.CheckIcon}
        disabled={
          fields.AktivierungsCode === "" ||
          !fields.AktivierungsCode ||
          successMessage
        }
        id={"mandantPageActivateBtn"}
        className="l-m-tb-16 l-w-330r"
        onClick={() => handleClick()}
      />
    </MainContentContainer>
  );
};
