// see https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
// see https://github.com/dasniko/keycloak-reactjs-demo

import Keycloak from "keycloak-js";

let _kc = {};

const initKeycloak = (onAuthenticatedCallback, conf) => {
  _kc = new Keycloak({
    realm: conf.realm,
    url: conf.url,
    clientId: conf.clientId,
  });

  _kc.onTokenExpired = () => {
    console.info("Keycloak token is about to expire");
    _kc.updateToken(30).then(
      () => {
        console.info("Keycloak token updated");
      },
      (err) => {
        console.warn("Failed to update keycloak token", err);
      }
    );
  };

  _kc
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
      redirectUri: window.location.href,
    })
    .then((authenticated) => {
      startKeepaliveScheduler();
      onAuthenticatedCallback();
    });
};

const doLogin = () => _kc.login();

const doLogout = (options) => _kc.logout(options);

const getToken = () => _kc.token;

const getIdToken = () => _kc.idToken;

const isLoggedIn = () => !!_kc.token;
const updateToken = (successCallback) =>
  _kc.updateToken(60).then(successCallback).catch(doLogin);
const getGivenName = () =>
  _kc.tokenParsed ? _kc.tokenParsed.given_name : undefined;
const getFamilyName = () =>
  _kc.tokenParsed ? _kc.tokenParsed.family_name : undefined;
const getUsername = () =>
  _kc.tokenParsed ? _kc.tokenParsed.preferred_username : undefined;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

// eslint-disable-next-line
let keepaliveIntervalId;
const keepaliveInterval = 30000;
const maxUserInactivitySeconds = 60 * 30;
let lastUserActivity;
const detectUserInactivity = false;
const keepalive = function () {
  // console.log('keepalive expired='+_kc.isTokenExpired(5))
  if (detectUserInactivity) {
    //console.log('Current user inactivity: ' + (Date.now() - self.lastUserActivity) / 1000 + ' sec')
    if (Date.now() - lastUserActivity > maxUserInactivitySeconds * 1000) {
      console.log("Max user inactivity reached. Skipping refresh token");
      return;
    }
  }
  updateToken(function () {
    //console.log('keepalive updateToken refresh='+refresh)
    keepaliveIntervalId = setTimeout(keepalive, keepaliveInterval);
  });
};

const startKeepaliveScheduler = function () {
  keepaliveIntervalId = setTimeout(keepalive, keepaliveInterval);
};

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getIdToken,
  updateToken,
  getUsername,
  hasRole,
  getGivenName,
  getFamilyName,
};

export default AuthService;
