import React, { useEffect } from "react";
import { ICON_SUBSET } from "@riag-libs/etax-pattern-library";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { ThemeProvider, registerIcons } from "@fluentui/react";
import { THEMES } from "@riag-libs/etax-pattern-library";
import { KantonContext } from "./contexts";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/root";
import HttpService from "./services/HttpService";

initializeIcons();
registerIcons(ICON_SUBSET);

function App(props) {
  const { kantonInfo } = props;

  useEffect(() => {
    const getUserAccount = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/account/user"
      );
      if (response.status === 200) {
        // console.log("getUserAccount success", response);
      }
    };
    getUserAccount();
  }, []);

  return (
    <ThemeProvider applyTo="body" theme={THEMES.owTheme}>
      <KantonContext.Provider value={kantonInfo}>
        <RouterProvider router={router}> </RouterProvider>
      </KantonContext.Provider>
    </ThemeProvider>
  );
}

export default App;
