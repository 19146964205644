import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Shimmer, ShimmerElementType, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ActivationCodePanel } from "../Panel/ActivationCodePanel";
import HttpService from "../../services/HttpService";
import moment from "moment";

function ToBeActivatedList() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const getMandateList = async () => {
      const response = await HttpService.getAxiosClient().get("/api/mandants");
      if (response.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    };
    getMandateList();
  }, []);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [panelData, setPanelData] = useState({});

  const handleClick = (proc) => {
    setPanelData(proc);
    openPanel();
  };

  function caseExists(c) {
    return c.agbAccepted === true && c.activated === false;
  }

  const exists = !_.isEmpty(data) && data.find(caseExists);
  const filteredList = !_.isEmpty(data) && data.filter(caseExists);

  if (loading) {
    return (
      <MainContentContainer>
        <ObjectList
          title="Beantragte Aktivierungen"
          headingTag="h2"
          headingTagClass="title3"
          listHeader={
            <ObjectListHeader size="l-fb-100" hasActionButton={true}>
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                Name
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                PID
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                Code angefordert am
              </Text>
            </ObjectListHeader>
          }
        >
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        </ObjectList>
      </MainContentContainer>
    );
  }

  return (
    <MainContentContainer>
      {exists ? (
        <ObjectList
          title="Beantragte Aktivierungen"
          headingTag="h2"
          headingTagClass="title3"
          listHeader={
            <ObjectListHeader size="l-fb-100" hasActionButton={true}>
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                Name
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                PID
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                Code angefordert am
              </Text>
            </ObjectListHeader>
          }
        >
          {data &&
            Object.keys(filteredList).length !== 0 &&
            filteredList.map((process) => {
              return (
                <ObjectItem
                  onClick={() => handleClick(process)}
                  ariaLabel={process.name}
                  actionButton={
                    <IconButton
                      ariaLabel="IconButton with Menu"
                      className="moreIcon"
                      iconProps={null}
                      menuIconProps={{ iconName: "menuDotsIcon" }}
                      menuProps={{
                        items: [
                          {
                            iconProps: { iconName: "checkIcon" },
                            key: "edit",
                            text: "Aktivierungscode eingeben",
                            onClick: () => handleClick(process),
                          },
                        ],
                      }}
                      title="IconButton with Menu"
                    />
                  }
                >
                  <ContentContainer className="l-flex-rowgap-8">
                    <ContentContainer className="horizontal l-fb-40">
                      <FontIcon
                        iconName={
                          process.type === "NP"
                            ? ICON_PROPS.NPActivateIcon.iconName
                            : ICON_PROPS.JPActivateIcon.iconName
                        }
                        className="objectItemIcon-large"
                      />
                      <Text as="span" className="flexItem title3">
                        {process.name}
                      </Text>
                    </ContentContainer>
                    <ContentContainer size="l-fb-30" className="horizontal">
                      <Text
                        as="span"
                        className="flexItem t-listText hiddenLabel"
                      >
                        PID:
                      </Text>
                      <Text as="span" className="flexItem t-listText t-right">
                        {process.SubjektId}
                      </Text>
                    </ContentContainer>
                    <ContentContainer size="l-fb-30" className="horizontal">
                      <Text
                        as="span"
                        className="flexItem t-listText hiddenLabel"
                      >
                        Code angefordert am:
                      </Text>
                      <Text as="span" className="flexItem t-listText t-right">
                        {moment(process.registrationDate).format("DD.MM.YYYY")}
                      </Text>
                    </ContentContainer>
                  </ContentContainer>
                </ObjectItem>
              );
            })}
        </ObjectList>
      ) : (
        <ObjectList
          title="Beantragte Aktivierungen"
          headingTag="h2"
          headingTagClass="title3"
          listHeader={
            <ObjectListHeader size="l-fb-100" hasActionButton={true}>
              <Text as="span" className="flexItem t-listHeading l-fb-40">
                Name
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                PID
              </Text>
              <Text
                as="span"
                className="flexItem t-listHeading t-right l-fb-30"
              >
                Code angefordert am
              </Text>
            </ObjectListHeader>
          }
        >
          <ObjectItem
            className="infoWarning"
            ariaLabel="Information zur leeren Liste"
          >
            <ContentContainer className="horizontal">
              <FontIcon
                iconName={ICON_PROPS.InfoIcon.iconName}
                className="objectItemIcon"
              />
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                <Text as="span" className="flexItem title3">
                  Keine ausstehenden Aktivierungen vorhanden
                </Text>
              </ContentContainer>
            </ContentContainer>
          </ObjectItem>
        </ObjectList>
      )}
      <ActivationCodePanel
        panelData={panelData}
        isOpen={isOpen}
        onDismiss={dismissPanel}
        setData={setData}
      />
    </MainContentContainer>
  );
}

export default ToBeActivatedList;
