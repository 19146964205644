import React, { useCallback, useEffect, useState } from "react";
import {
  DefaultButton,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import {
  ButtonList,
  ContentContainer,
  FieldSet,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
} from "@riag-libs/etax-pattern-library";
import HttpService from "../../services/HttpService";
import { useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";

function MainAcceptShare() {
  const params = useParams();
  const navigate = useNavigate();
  const [invitationinfo, setInvitationinfo] = useState({});
  const [smsError, setSmsError] = useState("");
  const [requestSmsError, setRequestSmsError] = useState("");
  const [smsSuccess, setSmsSuccess] = useState(false);
  const [refuseError, setRefuseError] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [pdf, setPdf] = useState("");
  const [JpPdf, setJpPdf] = useState("");

  const handleKeyUp = useCallback((event) => {
    if (event.key === "Enter") {
      document.getElementById("enterSms").click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  useEffect(() => {
    const getNPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdf",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };

    getNPPDF();
  }, []);

  useEffect(() => {
    const getJPPDF = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          "/api/agb/pdfJP",
          { responseType: "arraybuffer" }
        );
        if (response.status === 200) {
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64"
          );
          setJpPdf(base64ImageString);
        }
      } catch (error) {
        console.log(error, error.message);
      }
    };

    getJPPDF();
  }, []);

  const getInvitationinfo = async () => {
    const response = await HttpService.getAxiosClient().post(
      "/api/delegation/invitation/info",
      {
        lang: "de",
        requestId: params.code,
      }
    );
    if (response.status === 200) {
      setInvitationinfo(response.data);
    }
    if (response.status !== 200) {
      setInvitationinfo({});
    }
  };

  useEffect(() => {
    getInvitationinfo();
  }, []);

  const declineInvitation = async () => {
    setSmsError("");
    setRequestSmsError("");
    setSmsSuccess(false);
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/delegation/invitation/decline",
        {
          lang: "de",
          requestId: params.code,
        }
      );
      if (response.status === 200) {
        setRefuseError(false);
        navigate("/");
      }
    } catch (error) {
      if (error.response.status === 410 || error.response.status === 500) {
        setRefuseError(true);
      }
    }
  };

  const getSmsCode = async (isAutomaticRequest) => {
    setRefuseError(false);
    setSmsCode("");
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/delegation/invitation/send-sms",
        {
          lang: "de",
          requestId: params.code,
        }
      );
      if (response.status === 200) {
        if (isAutomaticRequest !== true) {
          setSmsError("");
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        setRequestSmsError("403");
        navigate("/");
      }
      if (error.response.status === 410) {
        setRequestSmsError("410");
      }
      if (error.response.status === 429) {
        setSmsError("");
        setRequestSmsError("429");
        setInvitationinfo({});
      }
      if (error.response.status === 500) {
        setRequestSmsError("500");
      }
      console.log(error, error.message);
    }
  };

  useEffect(() => {
    getSmsCode();
  }, []);

  const handleInvitationAccept = async () => {
    setRefuseError(false);
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/delegation/invitation/accept",
        {
          lang: "de",
          requestId: params.code,
          "sms-code": smsCode,
        }
      );
      if (response.status === 200) {
        setSmsError("");
        setSmsSuccess(true);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setSmsError("401");
      }
      if (error.response.status === 403) {
        setSmsError("403");
      }
      if (error.response.status === 406) {
        setSmsError("406");
      }
      if (error.response.status === 410) {
        setSmsError("410");
      }
      if (error.response.status === 429) {
        setSmsError("429");
        // this is for automatically sendign a code
        // getSmsCode(true);
      }
      if (error.response.status === 500) {
        setSmsError("500");
      }
      console.log(error, error.message);
    }
  };

  const pickSharingMessage = (
    smsError,
    smsSuccess,
    requestSmsError,
    refuseError
  ) => {
    if (smsSuccess) {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              className="title2"
            >
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8 l-m-b-16">
                <Text as="p" className="title2">
                  Teilen erfolgreich bestätigt
                </Text>
                <Text as="p" className="t-standard">
                  Der geteilte Inhalt wird nun auf Ihrer{" "}
                  <Link
                    onClick={() => {
                      navigate("/");
                      navigate(0);
                    }}
                    underline
                  >
                    Startseite angezeigt
                  </Link>
                  .
                </Text>
              </ContentContainer>
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (refuseError) {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Die Einladung konnte nicht abgelehnt werden. Bitte versuchen Sie
              es erneut.
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (smsError) {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              {(() => {
                if (smsError === "401") {
                  return (
                    <div>
                      SMS-Code ist nicht mehr gültig - Sie können sich einen
                      neuen{" "}
                      <Link onClick={() => getSmsCode()} underline>
                        SMS-Code zusenden
                      </Link>{" "}
                      lassen.
                    </div>
                  );
                } else if (smsError === "403") {
                  return "Dieser Inhalt wurde von Ihrem aktuellen Benutzerkonto geteilt. Bitte melden Sie sich ab und bestätigen das Teilen dieses Inhalts mit einem anderen Benutzerkonto.";
                } else if (smsError === "406") {
                  return "Überprüfen Sie die Eingabe des SMS Codes.";
                } else if (smsError === "410") {
                  return "Diese Teilen-Einladung ist nicht gültig. Möglicherweise wurde das Teilen dieses Inhalts schon mit einem anderen Benutzeraccount bestätigt.";
                } else if (smsError === "429") {
                  return (
                    <div>
                      Der SMS-Code wurde zu viel falsch eingegeben - Sie können
                      sich einen neuen{" "}
                      <Link onClick={() => getSmsCode()} underline>
                        SMS-Code zusenden
                      </Link>{" "}
                      lassen.
                    </div>
                  );
                } else if (smsError === "500") {
                  return "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.";
                }
              })()}
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
    if (requestSmsError) {
      return (
        <ObjectList
          title="Meldungen"
          headingTag="h2"
          titleVisuallyHidden={true}
        >
          <ObjectItem>
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              {(() => {
                if (requestSmsError === "403") {
                  return "Dieser Inhalt wurde von Ihrem aktuellen Benutzerkonto geteilt. Bitte melden Sie sich ab und bestätigen das Teilen dieses Inhalts mit einem anderen Benutzerkonto.";
                } else if (requestSmsError === "410") {
                  return "Diese Teilen-Einladung ist nicht gültig. Möglicherweise wurde das Teilen dieses Inhalts schon mit einem anderen Benutzeraccount bestätigt.";
                } else if (requestSmsError === "429") {
                  return (
                    <div>
                      Um die Einladung zu akzeptieren, gab es zu viele falsche
                      Eingaben. Zur Sicherheit wurde die Einladung gelöscht. Für
                      eine neue Einladung kontaktieren Sie bitte die Person,
                      welche Sie eingeladen hat.{" "}
                      <Link onClick={() => navigate("/")} underline>
                        Zurück zur Startseite
                      </Link>{" "}
                    </div>
                  );
                } else if (requestSmsError === "500") {
                  return "Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.";
                }
              })()}
            </MessageBar>
          </ObjectItem>
        </ObjectList>
      );
    }
  };

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const blob = base64ToBlob(
    invitationinfo.subjects && invitationinfo.subjects[0].subjectType === "NP"
      ? pdf
      : JpPdf,
    "application/pdf"
  );
  const url = URL.createObjectURL(blob);

  return (
    <MainContentContainer
      messages={
        (smsError || smsSuccess || requestSmsError || refuseError) &&
        pickSharingMessage(smsError, smsSuccess, requestSmsError, refuseError)
      }
    >
      {requestSmsError !== "429" &&
        requestSmsError !== "410" &&
        smsError !== "410" && (
          <>
            <ObjectList
              title="Ein Steuerportal-Inhalt wurde mit Ihnen geteilt"
              headingTag="h3"
              headingTagClass="title3"
            >
              <ObjectItem className="formData">
                <ContentContainer className="l-flex-column l-flex-align-stretch lineSeparator">
                  <ContentContainer className="l-flex-rowgap-4 l-flex-align-start">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      Inhalt:
                    </Text>
                    <ContentContainer className="l-flex-rowgap-0 l-fb-50 vertical">
                      <Text
                        as="span"
                        className="flexItem t-standard t-overflow-wrap"
                      >
                        {invitationinfo.subjects &&
                          invitationinfo.subjects[0].name}{" "}
                        (PID:{" "}
                        {invitationinfo.subjects &&
                          invitationinfo.subjects[0].pid}
                        ){" "}
                        {invitationinfo.subjects &&
                          invitationinfo.subjects[0].typeDescr}{" "}
                        {invitationinfo.subjects &&
                          invitationinfo.subjects[0].year}
                      </Text>
                      {/*<Text as="span" className="flexItem t-standard t-overflow-wrap">*/}
                      {/*  Steuererklärung 2022*/}
                      {/*</Text>*/}
                    </ContentContainer>
                  </ContentContainer>
                  {/*<ContentContainer className="l-flex-rowgap-4 l-flex-align-start">*/}
                  {/*  <Text*/}
                  {/*    as="span"*/}
                  {/*    className="flexItem t-standard title4 t-overflow-wrap l-fb-50"*/}
                  {/*  >*/}
                  {/*    Geteilt von:*/}
                  {/*  </Text>*/}
                  {/*  <Text*/}
                  {/*    as="span"*/}
                  {/*    className="flexItem t-standard t-overflow-wrap l-fb-50"*/}
                  {/*  >*/}
                  {/*    p.mustervorde@example.com*/}
                  {/*  </Text>*/}
                  {/*</ContentContainer>*/}
                  <ContentContainer className="l-flex-rowgap-4 l-flex-align-start">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      Geteilt mit:
                    </Text>
                    <ContentContainer className="l-flex-rowgap-0 l-fb-50 vertical">
                      <Text
                        as="span"
                        className="flexItem t-standard t-overflow-wrap"
                      >
                        {invitationinfo.firstName} {invitationinfo.lastName}
                      </Text>
                      <Text
                        as="span"
                        className="flexItem t-standard t-overflow-wrap"
                      >
                        {invitationinfo.email}
                      </Text>
                      <Text
                        as="span"
                        className="flexItem t-standard t-overflow-wrap"
                      >
                        {invitationinfo.mobile}
                      </Text>
                    </ContentContainer>
                  </ContentContainer>
                  <ContentContainer className="l-flex-rowgap-4 l-flex-align-start">
                    <Text
                      as="span"
                      className="flexItem t-standard title4 t-overflow-wrap l-fb-50"
                    >
                      Benutzerrechte:
                    </Text>
                    <ContentContainer className="l-flex-rowgap-0 l-fb-50 vertical">
                      {invitationinfo.subjects &&
                        invitationinfo.subjects[0].permissions.accountsView ===
                          true && (
                          <Text
                            as="span"
                            className="flexItem t-standard t-overflow-wrap"
                          >
                            Steuerkonto lesen
                          </Text>
                        )}
                      {invitationinfo.subjects &&
                        invitationinfo.subjects[0].permissions.documentsView ===
                          true && (
                          <Text
                            as="span"
                            className="flexItem t-standard t-overflow-wrap"
                          >
                            Dokumente lesen
                          </Text>
                        )}
                      {invitationinfo.subjects &&
                        invitationinfo.subjects.find(
                          (el) => el.permissions.declRead === true
                        ) && (
                          <Text
                            as="span"
                            className="flexItem t-standard t-overflow-wrap"
                          >
                            Deklaration bearbeiten
                          </Text>
                        )}
                    </ContentContainer>
                  </ContentContainer>
                </ContentContainer>
              </ObjectItem>
            </ObjectList>
            {smsSuccess === false && (
              <ContentContainer className="vertical l-m-t-32">
                <FieldSet
                  title="Teilen bestätigen"
                  headingTag="h3"
                  headingTagClass="title3"
                  description={
                    <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-0 l-m-b-8">
                      <Text as="p" className="t-standard">
                        Es wurde ein SMS-Code an die Mobile-Nr.{" "}
                        {invitationinfo.mobile} geschickt.
                      </Text>
                      <Text as="p" className="t-standard">
                        Bitte geben Sie diesen Code ein, um das Teilen zu
                        bestätigen.
                      </Text>
                      <Text as="p" className="t-standard">
                        Mit der Eingabe des SMS-Codes akzeptieren Sie die{" "}
                        <Link href={url} target={"_blank"} underline>
                          Allgemeinen Geschäftsbedingungen des Steuerportals
                        </Link>
                        .
                      </Text>
                    </ContentContainer>
                  }
                >
                  <TextField
                    label="SMS-Code eingeben"
                    className="l-w-330r"
                    value={smsCode}
                    disabled={
                      Object.keys(invitationinfo).length === 0 ||
                      smsError === "401"
                    }
                    onChange={(event) => {
                      setSmsError("");
                      setRequestSmsError("");
                      setSmsCode(event.target.value);
                    }}
                  />
                </FieldSet>
                <ButtonList className="vertical l-flex-align-start">
                  <PrimaryButton
                    text="Teilen bestätigen"
                    id={"enterSms"}
                    iconProps={ICON_PROPS.CheckIcon}
                    disabled={
                      !smsCode ||
                      smsCode.sms === "" ||
                      requestSmsError === "429" ||
                      requestSmsError === "410" ||
                      smsError === "401"
                    }
                    className="l-w-330r"
                    onClick={() => handleInvitationAccept()}
                  />
                  <DefaultButton
                    text="SMS-Code erneut versenden"
                    iconProps={ICON_PROPS.ReloadIcon}
                    disabled={Object.keys(invitationinfo).length === 0}
                    className="l-w-330r"
                    onClick={() => getSmsCode()}
                  />
                </ButtonList>
              </ContentContainer>
            )}
            {!smsSuccess && (
              <ObjectList
                title="Teilen ablehnen"
                headingTag="h3"
                headingTagClass="title3"
                description="Falls Sie die Teilen-Einladung nicht annehmen wollen, können Sie diese mit Klick auf den nachfolgenden Button ablehnen."
                className="l-mt-32"
                buttonList={
                  <ButtonList className="vertical l-flex-align-start">
                    <DefaultButton
                      text="Teilen ablehnen"
                      iconProps={ICON_PROPS.DeleteIcon}
                      disabled={Object.keys(invitationinfo).length === 0}
                      className="l-w-330r"
                      onClick={() => declineInvitation()}
                    />
                  </ButtonList>
                }
              />
            )}
          </>
        )}
    </MainContentContainer>
  );
}

export default MainAcceptShare;
