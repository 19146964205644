import React from "react";
import {
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import { FontIcon, Link, Text } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

const TaxAccountHeader = (
  <ObjectListHeader>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Gemeinde
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Beschreibung
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-15">
      Status
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-15">
      Saldo
    </Text>
  </ObjectListHeader>
);

const DocumentsHeader = (
  <ObjectListHeader hasActionButton={true}>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerart
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Dokumenttyp
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-40">
      Dokumentname
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-20">
      Erstellt am
    </Text>
  </ObjectListHeader>
);

export const NotActiveMessage = (props) => {
  const navigate = useNavigate();
  const { steuer, nestError, mandant } = props;
  return (
    <MainContentContainer>
      <ObjectList
        title={steuer ? "Steuerkonto" : "Dokumente"}
        headingTag="h3"
        headingTagClass="title2"
        listHeader={steuer ? TaxAccountHeader : DocumentsHeader}
        className="tableView bigTitle"
      >
        <ObjectItem
          className="infoWarning"
          ariaLabel="Information zur leeren Liste"
        >
          <ContentContainer className="horizontal">
            <FontIcon
              iconName={ICON_PROPS.InfoIcon.iconName}
              className="objectItemIcon"
            />
            {steuer ? (
              nestError ? (
                nestError === "PERS-00013" ? (
                  <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                    <Text as="span" className="flexItem title3">
                      Steuerkonto wurde für diese PID deaktiviert.{" "}
                    </Text>
                    <Text as="span" className="flexItem t-standard">
                      Aktivieren Sie das Steuerportal für diese PID erneut, um
                      Zugang zum Steuerkonto und den Dokumenten zu erhalten. Den
                      Aktivierungsprozess starten Sie oben mit dem Button
                      'Weitere Aktionen' und mit der Option{" "}
                      <Link onClick={() => navigate(`/aktivieren/`)} underline>
                        'Steuerportal aktivieren'
                      </Link>
                      .
                    </Text>
                  </ContentContainer>
                ) : (
                  <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                    <Text as="span" className="flexItem title3">
                      Es ist ein technisches Problem aufgetreten - versuchen Sie
                      es später nochmals.
                    </Text>
                  </ContentContainer>
                )
              ) : (
                <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                  <div>
                    <Text as="span" className="flexItem title3">
                      Steuerkonto für {mandant.name} ist nicht aktiviert.{" "}
                    </Text>
                    <Text as="span" className="flexItem t-standard">
                      Sie können das Steuerportal über{" "}
                      <Link onClick={() => navigate(`/aktivieren/`)} underline>
                        Steuerportal aktivieren
                      </Link>
                      .
                    </Text>
                  </div>
                </ContentContainer>
              )
            ) : (
              <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
                {nestError ? (
                  <Text as="span" className="flexItem title3">
                    Es ist ein technisches Problem aufgetreten - versuchen Sie
                    es später nochmals.
                  </Text>
                ) : (
                  <div>
                    <Text as="span" className="flexItem title3">
                      Dokumente für {mandant.name} sind nicht aktiviert.{" "}
                    </Text>
                    <Text as="span" className="flexItem t-standard">
                      Sie können das Steuerportal über{" "}
                      <Link onClick={() => navigate(`/aktivieren/`)} underline>
                        Steuerportal aktivieren
                      </Link>
                      .
                    </Text>
                  </div>
                )}
              </ContentContainer>
            )}
          </ContentContainer>
        </ObjectItem>
      </ObjectList>
    </MainContentContainer>
  );
};
