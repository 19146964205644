import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  FieldSet,
  ICON_PROPS,
  IconButton,
  MainContentContainer,
  ObjectItem,
  ObjectList,
  ObjectListHeader,
} from "@riag-libs/etax-pattern-library";
import {
  DefaultButton,
  Dropdown,
  FontIcon,
  Shimmer,
  ShimmerElementType,
  Text,
  TextField,
} from "@fluentui/react";
import HttpService from "../../services/HttpService";
import moment from "moment/moment";
import { NotActiveMessage } from "./NotActiveMessage";
import { useBoolean } from "@fluentui/react-hooks";
import { SharingPanelMandantView } from "../Panel/SharingPanelMandantView";
import { NotActiveSteuerKonto } from "./NotActiveSteuerKonto";
import { NotSharedWithYou } from "./NotSharedWithYou";
import { useNavigate } from "react-router-dom";
var FileSaver = require("file-saver");

const DocumentsHeader = (
  <ObjectListHeader hasActionButton={true}>
    <Text as="span" className="flexItem t-listHeading l-fb-10">
      Steuerjahr
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Steuerart
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-20">
      Dokumenttyp
    </Text>
    <Text as="span" className="flexItem t-listHeading l-fb-30">
      Dokumentname
    </Text>
    <Text as="span" className="flexItem t-listHeading t-right l-fb-20">
      Erstellt am
    </Text>
  </ObjectListHeader>
);

export const Documents = (props) => {
  sessionStorage.clear();
  const [filters, setFilters] = useState("");
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputFilter, setInputFilter] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const navigate = useNavigate();
  const [
    isDocumentSharingOpen,
    { setTrue: openSharingPanel, setFalse: dismissSharingPanel },
  ] = useBoolean(false);
  const { mandant, setMandant } = props;
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await HttpService.getAxiosClient().post(
        `/api/dms/${mandant.SubjektId}/search`,
        {
          ...(fields.doktypId && { doktypId: fields.doktypId.toString() }),
          ...(fields.steuerart && { steuerart: fields.steuerart.toString() }),
          ...(fields.steuerjahr && {
            steuerjahr: fields.steuerjahr.toString(),
          }),
        },
        { params: { subjectId: mandant.SubjektId } }
      );
      if (response.status === 200) {
        setFilteredDocuments(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, error.message);
    }
  };

  const getMandant = async () => {
    try {
      const response = await HttpService.getAxiosClient().get(
        `/api/mandant/${mandant.SubjektId}`
      );
      if (response.status === 200) {
        setMandant(response.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await HttpService.getAxiosClient().get(
          `/api/dms/${mandant.SubjektId}/filters`
        );
        if (response.status === 200) {
          setFilters(response.data);
        }
      } catch (error) {
        console.log(error, error.message);
        return <NotActiveMessage nestError />;
      }
    };
    if (mandant.activated === true) {
      getFilters();
      handleSearch();
    }
  }, [mandant]);

  useEffect(() => {
    if (mandant.activated === false) {
      getMandant();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (mandant.activated === true) {
      handleSearch();
    }
  }, [fields.doktypId, fields.steuerart, fields.steuerjahr]);

  const downloadFile = async (doc, download) => {
    try {
      const response = await HttpService.getAxiosClient().get(
        `/api/dms/get/${mandant.SubjektId}/${doc.docNumber}`,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        if (download) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(blob, doc.documentName + ".pdf");
        } else {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        }
      }
    } catch (error) {
      console.log(error, error.message);
    }
  };

  const newDocTypes =
    filters &&
    filters.docTypes.map((rec) => {
      return {
        key: rec.id,
        text: rec.name,
      };
    });

  const finalDocTypes = newDocTypes && [
    {
      key: 0,
      text: "Alle",
    },
    ...newDocTypes,
  ];

  const newTaxYears =
    filters &&
    filters.years.map((rec) => {
      return {
        key: rec,
        text: rec,
      };
    });

  const finalTaxYears = newTaxYears && [
    {
      key: 0,
      text: "Alle",
    },
    ...newTaxYears,
  ];

  const newTaxTypes =
    filters &&
    filters.steuerarts.map((rec) => {
      return {
        key: rec.id,
        text: rec.name,
      };
    });

  const finalTaxTypes = newTaxTypes && [
    {
      key: 0,
      text: "Alle",
    },
    ...newTaxTypes,
  ];

  const onlyDisplayedFieldsDocuments =
    filteredDocuments &&
    filteredDocuments.map((d) => {
      return {
        creationDate: moment(d.creationDate).format("DD.MM.YYYY"),
        steuerjahr: d.steuerjahr,
        steuerartName: d.steuerartName,
        doktypName: d.doktypName,
        documentName: d.documentName,
        docNumber: d.docNumber,
      };
    });

  const searched =
    filteredDocuments &&
    onlyDisplayedFieldsDocuments &&
    onlyDisplayedFieldsDocuments.filter(
      (f) =>
        Object.values(f).some((el) =>
          el.toLowerCase().includes(inputFilter.toLowerCase())
        ) || inputFilter === ""
    );

  if (mandant.owned === false && mandant.permissions.documentsView === false) {
    return <NotSharedWithYou />;
  }

  if (mandant.agbAccepted === true && mandant.activated === false) {
    return (
      <NotActiveSteuerKonto
        setMandant={setMandant}
        mandantInfo={mandant}
        documentsView={true}
      />
    );
  }

  if (mandant.agbAccepted === false && mandant.activated === false) {
    return <NotActiveMessage mandant={mandant} />;
  }

  const DocumentsEmptyListMessage = (
    <ObjectItem
      className="infoWarning"
      ariaLabel="Information zur leeren Liste"
    >
      <ContentContainer className="horizontal">
        <FontIcon
          iconName={ICON_PROPS.InfoIcon.iconName}
          className="objectItemIcon"
        />
        <ContentContainer className="l-flex-column l-flex-align-start l-flex-rowgap-8">
          <Text as="span" className="flexItem title3">
            Für {mandant.name} sind keine Dokumente vorhanden.
          </Text>
        </ContentContainer>
      </ContentContainer>
    </ObjectItem>
  );

  return (
    <MainContentContainer>
      <ObjectList
        title="Dokumente"
        headingTag="h3"
        headingTagClass="title2"
        className="bigTitle"
        listHeader={DocumentsHeader}
        emptyListMessage={
          filteredDocuments.length === 0 && DocumentsEmptyListMessage
        }
        titleAction={
          <DefaultButton
            onClick={() => openSharingPanel()}
            text={"Teilen"}
            disabled={!filters}
            iconProps={ICON_PROPS.ShareIcon}
          ></DefaultButton>
        }
        listAction={
          <FieldSet
            title="Dokumentfilter"
            headingTag="label"
            titleVisuallyHidden="true"
            horizontal="true"
            size="l-w-100p"
          >
            <TextField
              label="Suche"
              className="flexItem l-fb-40"
              disabled={!filters}
              onChange={(event) => {
                setInputFilter(event.target.value);
              }}
            />
            <Dropdown
              label="Steuerjahr"
              className="flexItem l-fb-20"
              disabled={!filters}
              options={finalTaxYears}
              onChange={(event, selection) => {
                setFields({
                  ...fields,
                  steuerjahr: selection.key,
                });
              }}
            />
            <Dropdown
              label="Steuerart"
              className="flexItem l-fb-20"
              options={finalTaxTypes}
              disabled={!filters}
              onChange={(event, selection) => {
                setFields({
                  ...fields,
                  steuerart: selection.key,
                });
              }}
            />
            <Dropdown
              label="Dokumenttyp"
              className="flexItem l-fb-20"
              options={finalDocTypes}
              disabled={!filters}
              onChange={(event, selection) => {
                setFields({
                  ...fields,
                  doktypId: selection.key,
                });
              }}
            />
          </FieldSet>
        }
      >
        {loading ? (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, height: 69, width: "100%" },
            ]}
          />
        ) : (
          filteredDocuments.length !== 0 &&
          !loading &&
          searched.map((doc) => {
            return (
              <ObjectItem
                onClick={() => downloadFile(doc, false)}
                actionButton={
                  <IconButton
                    iconProps={null}
                    ariaLabel={"Bearbeiten Menü"}
                    menuProps={{
                      items: [
                        {
                          key: "view",
                          text: "Dokument anzeigen",
                          iconProps: ICON_PROPS.ViewIcon,
                          onClick: () => downloadFile(doc, false),
                        },
                        {
                          key: "download",
                          text: "Dokument herunterladen",
                          onClick: () => downloadFile(doc, true),
                          iconProps: ICON_PROPS.DownloadIcon,
                        },
                      ],
                    }}
                    menuIconProps={ICON_PROPS.MenuDotsIcon}
                    className={"moreIcon"}
                  />
                }
              >
                <ContentContainer className="l-flex-rowgap-8">
                  <ContentContainer size="l-fb-10" className="horizontal">
                    <Text as="span" className="flexItem t-listText hiddenLabel">
                      Steuerjahr:
                    </Text>
                    <Text as="span" className="flexItem t-listText t-right-s">
                      {doc.steuerjahr}
                    </Text>
                  </ContentContainer>
                  <ContentContainer size="l-fb-20" className="horizontal">
                    <Text as="span" className="flexItem t-listText hiddenLabel">
                      Steuerart:
                    </Text>
                    <Text as="span" className="flexItem t-listText t-right-s">
                      {doc.steuerartName}
                    </Text>
                  </ContentContainer>
                  <ContentContainer
                    size="l-fb-20"
                    className="horizontal t-ellipsis"
                  >
                    <Text as="span" className="flexItem t-listText hiddenLabel">
                      Dokumenttyp:
                    </Text>
                    <Text as="span" className="flexItem t-listText t-right-s">
                      {doc.doktypName}
                    </Text>
                  </ContentContainer>
                  <ContentContainer
                    size="l-fb-30"
                    className="horizontal t-ellipsis"
                  >
                    <Text as="span" className="flexItem t-listText hiddenLabel">
                      Dokumentname:
                    </Text>
                    <Text as="span" className="flexItem t-listText t-right-s">
                      {doc.documentName}
                    </Text>
                  </ContentContainer>
                  <ContentContainer
                    size="l-fb-20"
                    className="horizontal t-ellipsis"
                  >
                    <Text as="span" className="flexItem t-listText hiddenLabel">
                      Erstellt am:
                    </Text>
                    <Text as="span" className="flexItem t-listText t-right">
                      {doc.creationDate}
                    </Text>
                  </ContentContainer>
                </ContentContainer>
              </ObjectItem>
            );
          })
        )}
      </ObjectList>
      <SharingPanelMandantView
        isOpen={isDocumentSharingOpen}
        onDismiss={dismissSharingPanel}
        mandant={mandant}
        refreshOnClose={getMandant}
      />
    </MainContentContainer>
  );
};
