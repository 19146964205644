import axios from "axios";
import AuthService from "./AuthService";

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (AuthService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
        config.headers["X-Keycloak-Id-Token"] = AuthService.getIdToken();
        return Promise.resolve(config);
      };
      return AuthService.updateToken(cb);
    }
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  configure,
  getAxiosClient,
};

export default HttpService;
