import React, { useEffect, useState } from "react";
import {
  ButtonList,
  ContentContainer,
  DefaultButton,
  ICON_PROPS,
  Panel,
  PrimaryButton,
  TextButton,
  TextField,
} from "@riag-libs/etax-pattern-library";
import { PanelType } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  EmailFormatError,
  EmailSent,
  EmailVerified,
  GlobalSettingsError,
  VerifyEmailCodeError,
} from "../../helpers/helpers";

export const EmailPanel = (props) => {
  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [error, setError] = useState(null);
  const [newEmailRequested, setNewEmailRequested] = useState(false);
  const [emailCodeVerificationSuccess, setEmailCodeVerificationSuccess] =
    useState(false);
  const { setLocalSettings } = props;

  const getUserNotificationSettings = async () => {
    const response = await HttpService.getAxiosClient().get(
      "/api/user/notification/settings"
    );
    if (response.status === 200) {
      setLocalSettings(response.data);
    }
  };

  const postUserNotificationSettings = async (updatedSettings) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/settings",
        {
          ...updatedSettings,
        }
      );

      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating settings");
    }
  };

  const postUserEmailCode = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/email/validation/complete",
        {
          code: emailCode,
        }
      );
      if (response.status === 200) {
        setError(null);
        setNewEmailRequested(false);
        setEmailCodeVerificationSuccess(true);
      }
      if (response.status !== 200) {
        setError("E-Mail-Code konnte nicht verifiziert werden");
      }
    } catch (err) {
      setError("errorVerifyEmailCode");
    }
  };

  useEffect(() => {
    postUserNotificationSettings({
      ...props.localSettings,
      email: "",
      emailValidated: false,
    });
  }, []);

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleEmailChange = (event, newValue) => {
    setEmail(newValue);
  };

  const handleEmailCodeChange = (event, newValue) => {
    setEmailCode(newValue);
  };

  const handleKeyPressVerifyEmail = (event) => {
    if (event.key === "Enter") {
      handleVerifyEmail();
    }
  };

  const handleKeyPressEmailCodeChange = (event) => {
    if (event.key === "Enter") {
      handleVerifyEmailCode();
    }
  };

  const postUserNewEmail = async () => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/email/validation/start",
        {
          lang: "de",
        }
      );
      if (response.status === 200) {
        setError(null);
      }
      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating email");
    }
  };

  const handleVerifyEmail = async () => {
    if (isValidEmail(email)) {
      setError(null);
      const updatedSettings = {
        ...props.localSettings,
        emailValidated: false,
        email: email,
      };
      await postUserNotificationSettings(updatedSettings);
      postUserNewEmail();
      setNewEmailRequested(true);
    } else {
      setError("errorEmailFormat");
    }
  };

  const displayMessage = () => {
    if (error) {
      if (error === "errorVerifyEmailCode") {
        return VerifyEmailCodeError;
      } else if (error === "errorEmailFormat") {
        return EmailFormatError;
      } else {
        return GlobalSettingsError;
      }
    } else if (newEmailRequested === true) {
      return EmailSent;
    } else if (emailCodeVerificationSuccess) {
      return EmailVerified;
    }
  };

  const handleVerifyEmailCode = async () => {
    await postUserNotificationSettings({
      ...props.localSettings,
      email: email,
      emailValidated: true,
    });
    await postUserEmailCode();
  };
  const renderContent = () => {
    if (newEmailRequested === true) {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <ContentContainer className="vertical l-w-330">
            <ContentContainer className="vertical l-flex-rowgap-8 l-w-330">
              <TextField
                label={`Verifikations-Code für ${email}`}
                size="l-w-330"
                onKeyPress={(e) => handleKeyPressEmailCodeChange(e)}
                onChange={handleEmailCodeChange}
                autoFocus
              />
              <ButtonList className="l-w-330 vertical narrow">
                <PrimaryButton
                  text="Verifikations-Code bestätigen"
                  iconProps={ICON_PROPS.CheckIcon}
                  className="l-w-330"
                  onClick={() => handleVerifyEmailCode()}
                />
                <TextButton
                  text="Verifikations-E-Mail erneut verschicken"
                  iconProps={ICON_PROPS.ReloadIcon}
                  className="l-w-330"
                  onClick={() => handleVerifyEmail()}
                />
              </ButtonList>
            </ContentContainer>
          </ContentContainer>
        </ContentContainer>
      );
    } else if (emailCodeVerificationSuccess) {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <DefaultButton
            onClick={() => {
              getUserNotificationSettings();
              props.onDismiss();
            }}
          >
            Schliessen
          </DefaultButton>
        </ContentContainer>
      );
    } else {
      return (
        <ContentContainer className="vertical">
          {displayMessage()}
          <TextField
            label="E-Mail Adresse (bitte verifizieren)"
            size="l-w-330"
            value={email}
            onChange={handleEmailChange}
            onKeyPress={(e) => handleKeyPressVerifyEmail(e)}
          />
          <PrimaryButton
            text="E-Mail verifizieren"
            iconProps={ICON_PROPS.CheckIcon}
            className="l-w-330"
            onClick={() => handleVerifyEmail()}
          />
        </ContentContainer>
      );
    }
  };

  return (
    <Panel
      headerText="E-Mail Adresse ändern"
      panelType={PanelType.smallFixedFar}
      isOpen={props.isOpen}
      dismissPanel={() => {
        getUserNotificationSettings();
        props.onDismiss();
      }}
    >
      {renderContent()}
    </Panel>
  );
};
