import { createBrowserRouter, Navigate } from "react-router-dom";
import MainPage from "../pages/main-page";
import ErrorPage from "../pages/error-page";
import SelectedPage from "../pages/selected-page";
import Extension from "../pages/extension-page";
import React from "react";
import KontoDetailPage from "../pages/kontodetail-page";
import ActivatePage from "../pages/activate-page";
import AcceptSharePage from "../pages/accept-share-page";
import ManagementPage from "../pages/management-page";
import SettingsPage from "../pages/settings-page";
import SingleDocumentPage from "../pages/singledocument-page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "verify/:code",
    element: <AcceptSharePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "mandanten/:mandatId",
    element: <SelectedPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "mandanten/:mandatId/:subjektIdKonto/:kontoNr/kontodetails",
    element: <KontoDetailPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "extension/",
    element: <Extension />,
    errorElement: <ErrorPage />,
  },
  {
    path: "aktivieren/",
    element: <ActivatePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "teilen/",
    element: <ManagementPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "settings/",
    element: <SettingsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "dashboard/",
    element: <Navigate to="/" replace />,
  },
  {
    path: "dokument/:pid/:docId",
    element: <SingleDocumentPage />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
