import React, { useContext, useEffect, useState } from "react";
import {
  ButtonList,
  ContentContainer,
  ICON_PROPS,
  MainContentContainer,
  RadioButton,
  TextButton,
} from "@riag-libs/etax-pattern-library";
import { Shimmer, ShimmerElementType, Text } from "@fluentui/react";
import HttpService from "../../services/HttpService";
import {
  EmailFormatError,
  GlobalSettingsError,
  MobileFormatError,
  SettingHelp,
  VerifyEmailCodeError,
  VerifyMobileCodeError,
} from "../../helpers/helpers";
import { useBoolean } from "@fluentui/react-hooks";
import { EmailPanel } from "../Panel/EmailPanel";
import { MobilePanel } from "../Panel/MobilePanel";
import { KantonContext } from "../../contexts";

export const GlobalSettings = () => {
  const kantonInfo = useContext(KantonContext);
  const [error, setError] = useState(null);
  const [localSettings, setLocalSettings] = useState({});
  const [newDocumentOptions, setNewDocumentOptions] = useState([]);
  const [
    isEmailOpen,
    { setTrue: openEmailPanel, setFalse: dismissEmailPanel },
  ] = useBoolean(false);
  const [
    isMobileOpen,
    { setTrue: openMobilePanel, setFalse: dismissMobilePanel },
  ] = useBoolean(false);

  useEffect(() => {
    const getUserNotificationSettings = async () => {
      const response = await HttpService.getAxiosClient().get(
        "/api/user/notification/settings"
      );
      if (response.status === 200) {
        setLocalSettings(response.data);
      }
    };
    getUserNotificationSettings();
  }, []);

  useEffect(() => {
    const smsDisabled = !localSettings.mobile;
    let smsOption = {};
    if (kantonInfo.kanton === "ow" && localSettings.deliveryWay === "paper") {
      smsOption = {
        key: "sms",
        text: `per SMS auf ${
          localSettings.mobileValidated
            ? localSettings.mobile
            : localSettings.defaultMobile
        }`,
        disabled: true,
      };
    } else {
      smsOption = {
        key: "sms",
        text: `per SMS auf ${
          localSettings.mobileValidated
            ? localSettings.mobile
            : localSettings.defaultMobile
        }`,
        ...(smsDisabled && { disabled: true }),
      };
    }

    if (localSettings.deliveryWay === "paper") {
      setNewDocumentOptions([
        {
          key: "email",
          text: `per E-Mail auf ${
            localSettings.emailValidated
              ? localSettings.email
              : localSettings.defaultEmail
          }`,
        },
        smsOption,
        {
          key: "none",
          text: "keine Notifikation",
        },
      ]);
    } else {
      setNewDocumentOptions([
        {
          key: "email",
          text: `per E-Mail auf ${
            localSettings.emailValidated
              ? localSettings.email
              : localSettings.defaultEmail
          }`,
        },
        smsOption,
      ]);

      if (localSettings.newDocNotificationType === "none") {
        handleRadioChange("newDocNotificationType", "email");
      }
    }
  }, [localSettings.deliveryWay, localSettings.mobile, localSettings]);

  const postUserNotificationSettings = async (updatedSettings) => {
    try {
      const response = await HttpService.getAxiosClient().post(
        "/api/user/notification/settings",
        {
          ...updatedSettings,
        }
      );
      if (response.status !== 200) {
        setError("Failed to update settings");
      }
    } catch (err) {
      setError("Unexpected error while updating settings");
    }
  };

  const handleRadioChange = (key, value) => {
    let updatedSettings = {};
    if (
      kantonInfo.kanton === "ow" &&
      key === "deliveryWay" &&
      value === "paper" &&
      localSettings.newDocNotificationType === "sms"
    ) {
      updatedSettings = {
        ...localSettings,
        [key]: value,
        newDocNotificationType: "none",
        dueDateNotificationType: "none",
        newMessageNotificationType: "none",
      };
    } else {
      updatedSettings = {
        ...localSettings,
        [key]: value,
      };
      if (key === "newDocNotificationType") {
        updatedSettings = {
          ...updatedSettings,
          dueDateNotificationType: value,
          newMessageNotificationType: value,
        };
      }
    }
    setLocalSettings(updatedSettings);
    postUserNotificationSettings(updatedSettings);
  };

  const displayMessage = () => {
    if (error) {
      if (error === "errorVerifyEmailCode") {
        return VerifyEmailCodeError;
      } else if (error === "errorVerifyMobileCode") {
        return VerifyMobileCodeError;
      } else if (error === "errorMobileFormat") {
        return MobileFormatError;
      } else if (error === "errorEmailFormat") {
        return EmailFormatError;
      } else {
        return GlobalSettingsError;
      }
    } else {
      return null;
    }
  };

  if (
    !localSettings.deliveryWay &&
    !localSettings.newDocNotificationType &&
    !localSettings.dueDateNotificationType &&
    !localSettings.newMessageNotificationType &&
    !localSettings.mobile &&
    !localSettings.email
  ) {
    return (
      <MainContentContainer>
        <Shimmer
          shimmerElements={[
            { type: ShimmerElementType.line, height: 69, width: "100%" },
          ]}
        />
      </MainContentContainer>
    );
  }

  const optionActions = (
    <ButtonList className="l-m-l-m8 vertical narrow">
      <TextButton
        text="E-Mail Adresse ändern"
        iconProps={ICON_PROPS.EmailIcon}
        style={{ height: 26 }}
        onClick={() => openEmailPanel()}
      />
      <TextButton
        text="Mobile-Nummer ändern"
        iconProps={ICON_PROPS.PhoneIcon}
        style={{ height: 26 }}
        onClick={() => openMobilePanel()}
      />
    </ButtonList>
  );

  return (
    <MainContentContainer messages={displayMessage()}>
      <ContentContainer className="vertical l-m-b-32">
        <Text as="p" className="t-standard">
          Die globalen Einstellungen werden für alle aktivierten Steuerkonti
          verwendet. Ausnahmen können Sie im Register "Einstellungen pro
          Mandant" definieren.
        </Text>
        <RadioButton
          active
          defaultSelectedKey={localSettings.deliveryWay}
          label="Wie sollen amtliche Dokumente zugestellt werden?"
          onChange={(e, option) => handleRadioChange("deliveryWay", option.key)}
          options={[
            {
              key: "electronic",
              text: "digital ins Steuerportal (keine Briefpost)",
            },
            { key: "paper", text: "per Briefpost" },
          ].filter(
            (option) =>
              !(option.key === "electronic" && kantonInfo.kanton === "nw")
          )}
          outSideChild={kantonInfo.kanton === "ow" && SettingHelp}
          className={"l-w-330"}
        />

        <RadioButton
          active
          selectedKey={localSettings.newDocNotificationType}
          label={
            kantonInfo.kanton === "ow"
              ? "Wie möchten Sie bei Verfügbarkeit neuer Dokumente und Mitteilungen im Steuerportal benachrichtigt werden?"
              : "Wie möchten Sie, nebst dem standardmässigen Postversand, bei Verfügbarkeiten neuer Dokumente und Mitteilungen im Steuerportal benachrichtigt werden?"
          }
          onChange={(e, option) =>
            handleRadioChange("newDocNotificationType", option.key)
          }
          style={{ marginRight: "64px" }}
          options={newDocumentOptions}
          optionActions={optionActions}
        />
        {isEmailOpen && (
          <EmailPanel
            localSettings={localSettings}
            setLocalSettings={setLocalSettings}
            isOpen={isEmailOpen}
            onDismiss={dismissEmailPanel}
          />
        )}
        {isMobileOpen && (
          <MobilePanel
            localSettings={localSettings}
            setLocalSettings={setLocalSettings}
            isOpen={isMobileOpen}
            onDismiss={dismissMobilePanel}
          />
        )}
      </ContentContainer>
    </MainContentContainer>
  );
};
