import React, { useContext } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { Footer } from "../components/Footer/Footer";
import { SteuerKontoDetails } from "../components/Mandant/SteuerKontoDetails";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import { useLocation } from "react-router-dom";

function KontoDetailPage() {
  const kantonInfo = useContext(KantonContext);
  const location = useLocation();
  sessionStorage.setItem("page", "kontoDetails");
  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={`${
            location?.state?.mandantInfo?.name
              ? location?.state?.mandantInfo?.name
              : ""
          } (PID:
                ${
                  location?.state?.mandantInfo?.SubjektId
                    ? location?.state?.mandantInfo?.SubjektId
                    : ""
                })`}
          displayButtons={false}
          breadcrumbs={[
            {
              text: location?.state?.mandantInfo?.name
                ? location.state.mandantInfo.name
                : "",
              key: "f1",
              isCurrentItem: true,
            },
          ]}
          backButtonPath={`/mandanten/${location.state.mandantInfo.SubjektId}`}
          backButtonState={location}
          backButtonClear={true}
        />
      }
      main={<SteuerKontoDetails />}
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default KontoDetailPage;
