import { useRouteError } from "react-router-dom";
import React, { useContext } from "react";
import {
  AppContainer,
  MainContentContainer,
  ObjectItem,
  ObjectList,
} from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import { Footer } from "../components/Footer/Footer";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";

export default function ErrorPage() {
  sessionStorage.clear();
  const error = useRouteError();
  const kantonInfo = useContext(KantonContext);
  console.error(error);

  return (
    <AppContainer
      header={<HeaderDefault userName={AuthService.getUsername()} />}
      main={
        <MainContentContainer
          messages={
            <ObjectList
              title="Meldungen"
              headingTag="h2"
              titleVisuallyHidden={true}
            >
              <ObjectItem href="#">
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                >
                  Oops! Page not found! Error: 404
                </MessageBar>
              </ObjectItem>
            </ObjectList>
          }
        ></MainContentContainer>
      }
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}
