import React, { useContext } from "react";
import { ContentContainer, Panel } from "@riag-libs/etax-pattern-library";
import { PanelType, Text } from "@fluentui/react";
import { KantonContext } from "../../contexts";
import { UI_VERSION } from "../../constants/constants";

export const ImpressumPanel = (props) => {
  const kantonInfo = useContext(KantonContext);
  return (
    <Panel
      headerText="Impressum"
      panelType={PanelType.medium}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <ContentContainer className="vertical l-flex-rowgap-32 l-m-t-32">
        <ContentContainer className="vertical l-flex-rowgap-8">
          <Text as="h2" className="title2">
            Steueramt des Kantons{" "}
            {kantonInfo.kanton === "ow" ? "Obwalden" : "Nidwalden"}
          </Text>
          <ContentContainer className="vertical l-flex-rowgap-4">
            <Text as="p" className="t-standard">
              {kantonInfo.kanton === "ow" ? "eTax@ow.ch" : "steueramt@nw.ch"}
            </Text>
            <Text as="p" className="t-standard">
              {kantonInfo.kanton === "ow"
                ? "St. Antoninstrasse 4"
                : "Bahnhofpl. 3"}
            </Text>
            <Text as="p" className="t-standard">
              {kantonInfo.kanton === "ow" ? "6060 Sarnen" : "6371 Stans"}
            </Text>
            <Text as="p" className="t-standard">
              {kantonInfo.kanton === "ow" ? "041 666 62 94" : "041 618 71 27"}
            </Text>
          </ContentContainer>
        </ContentContainer>
        <ContentContainer className="vertical l-flex-rowgap-8">
          <Text as="h2" className="title2">
            Version
          </Text>
          <ContentContainer className="vertical l-flex-rowgap-4">
            <Text as="p" className="t-standard">
              UI Version {UI_VERSION} - Server Version{" "}
              {kantonInfo.serverVersion}
            </Text>
          </ContentContainer>
        </ContentContainer>
        <ContentContainer className="vertical l-flex-rowgap-8">
          <Text as="h2" className="title2">
            Konzept & Entwicklung
          </Text>
          <ContentContainer className="vertical l-flex-rowgap-4">
            <Text as="p" className="t-standard">
              Ringler Informatik AG
            </Text>
            <Text as="p" className="t-standard">
              Baarermattstrasse 10
            </Text>
            <Text as="p" className="t-standard">
              6340 Baar
            </Text>
            <Text as="p" className="t-standard">
              www.ringler.ch
            </Text>
          </ContentContainer>
        </ContentContainer>
        <ContentContainer className="vertical l-flex-rowgap-8">
          <Text as="p" className="t-standard">
            © Ringler Informatik AG
          </Text>
        </ContentContainer>
      </ContentContainer>
    </Panel>
  );
};
