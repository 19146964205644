import React, { useContext } from "react";
import { AppContainer } from "@riag-libs/etax-pattern-library";
import AuthService from "../services/AuthService";
import { owTheme } from "@riag-libs/etax-pattern-library/lib/css/owTheme/owTheme";
import { Footer } from "../components/Footer/Footer";
import { nwTheme } from "@riag-libs/etax-pattern-library/lib/css/nwTheme/nwTheme";
import { KantonContext } from "../contexts";
import MainSharingManagement from "../components/Sharing/MainSharingManagement";
import HeaderDefault from "../components/HeaderDefault/HeaderDefault";

function ManagementPage() {
  const kantonInfo = useContext(KantonContext);
  sessionStorage.clear();
  return (
    <AppContainer
      header={
        <HeaderDefault
          userName={AuthService.getUsername()}
          mainText={`Teilen Übersicht`}
          displayButtons={false}
          breadcrumbs={[
            { text: "Teilen Übersicht", key: "f1", isCurrentItem: true },
          ]}
          backButtonPath={-1}
        />
      }
      main={<MainSharingManagement />}
      footer={<Footer />}
      appTheme={kantonInfo.kanton === "ow" ? owTheme : nwTheme}
    ></AppContainer>
  );
}

export default ManagementPage;
